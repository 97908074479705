<template>
  <div class="login-body">
    <div class="login-view dowebok">

      <div class="form-container sign-in-container">
        <div class="form">
          <h1>登录</h1>
          <br>
          <input v-model="phoneNum" type="text" placeholder="手机号">
          <div v-if="loginType==0" style="width: 100%;display: flex;align-items: center;">
            <input v-model="code" type="text" placeholder="验证码" style="width: 60%;">
            <button @click="getCode" class="input-button">发送验证码</button>
          </div>
          <div v-if="loginType==1 && !passwordSee" style="width: 100%;display: flex;align-items: center;">
            <input v-model="password" type="password" placeholder="密码" style="width: 80%;">
            <button @click="passwordSee=true" class="input-button" style="width: 20%;">
              <BrowseOffIcon size="20px"/>
            </button>
          </div>
          <div v-if="loginType==1 && passwordSee" style="width: 100%;display: flex;align-items: center;">
            <input v-model="password" type="text" placeholder="密码" style="width: 80%;">
            <button @click="passwordSee=false" class="input-button" style="width: 20%;">
              <BrowseIcon size="20px"/>
            </button>
          </div>
          <br>
          <span style="color: grey;">
            未注册手机验证后自动登录，注册即代表同意
            <span @click="showBox1" style="cursor: pointer;">《用户协议》</span>
            <span @click="showBox2" style="cursor: pointer;">《隐私政策》</span>
          </span>
          <div style="display: flex;align-items: center;height: 20px;margin: 10px 0;">
            <div style="height: 1px;width: 30px;;background-color: #666;"></div>
            <span style="color: #666;">其它登录方式</span>
            <div style="height: 1px;width: 30px;;background-color: #666;"></div>
          </div>
          <div v-if="loginType==0" @click="loginType=1" style="width: 40px;height: 40px;background-color: #D84A36;border-radius: 20px;display: flex;align-items: center;justify-content: center;">
            <LockOnIcon size="24px" style="color: #fff;"/>
          </div>
          <div v-if="loginType==1" @click="loginType=0" style="width: 40px;height: 40px;background-color: #23b6ff;border-radius: 20px;display: flex;align-items: center;justify-content: center;">
            <MobileIcon size="24px" style="color: #fff;"/>
          </div>
          <br>
          <button v-if="loginType==0" @click="login1">登录</button>
          <button v-if="loginType==1" @click="login2">登录</button>
        </div>
      </div>

      <div id="user-agreement" @click="hiddenBox" style="display: none;">
        <div @click="(event) => { event.stopPropagation() }" class="text-box">
          <h2>碧湾用户服务协议</h2>

          <p>感谢选择碧湾为您提供服务！</p>

          <p>《碧湾用户服务协议》（以下简称为“本协议”）由杭州碧湾信息科技有限公司（以下简称为“碧湾”）和您签订。</p>

          <p>请悉知：碧湾App自2023年9月27号上线，至2023年10月31号为试运营阶段，供用户免费试用，不进行任何收费。后续正式运营期间开始收费，用户可自行决定购买与否，其他付费详情可详见《碧湾会员服务协议》。</p>

          <p>1、碧湾服务所涉及的相关权利归碧湾所有，包括但不限于行情系统、数据分析系统、网站资讯、手机短信、APP等。用户完成注册程序，即表示用户对本协议全部条款的确认和接纳，愿意按照服务条款及相关操作规则执行。</p>

          <p>2、在接受碧湾服务前，用户首先得确认碧湾服务系通过移动互联网进行，用户得自行承担上网和服务相关的费用。</p>

          <p>
            3、为了维护用户的合法权益，用户需要提供真实合法、详尽准确的个人身份信息，并及时根据实际状况予以更新。如国家调整相关用户注册信息法律法规的，用户应当根据新要求更新注册信息或按照新要求办理。否则，碧湾有可能解除本协议终止服务。
          </p>

          <p>4、用户注册成功后，将得到一个密码和账号，由用户自行更新、保管并承担全部责任。碧湾提醒用户对自身的相关信息（含注册信息、密码和账号）及时存储、备份，妥善保管，防止被盗、防止泄露，以免给自身造成损失。</p>

          <p>
            5、碧湾根据用户的注册信息对用户进行身份识别，以帮助用户处理诸如密码丢失、服务投诉等事项。若用户密码、账号被盗的，用户应当在第一时间告知碧湾，并提供合法有效的身份证件及碧湾注册信息，以便碧湾及时给用户提供必要的合理帮助。同时，用户应当向有权管辖机关报案或申诉，以维护自身的合法权益。
          </p>

          <p>6、碧湾提醒用户，凡是密码、账号正确或通过已授权登录方式（如手机、微信、苹果账号等）而登录成功的，均被视为用户本人登录，由此造成的后果均由用户本人承担，碧湾不承担任何后果。</p>

          <p>7、碧湾依照国家法律法规的规定，合理审慎地保护用户的注册信息，不予公开、不向第三方提供，除非：用户主动要求；法律、法规要求；司法部门要求。</p>

          <p>8、尊重用户个人隐私是碧湾的一项基本政策,详见《碧湾隐私政策》。</p>

          <p>9、根据法律法规、政府主管部门、行业监管协会及其他需求，碧湾服务条款或许有变更。在此情形下，碧湾将会在相关页面中予以提示。若用户接受变更，则点击激活“同意”按钮；若用户不点击的，则视同终止服务条款。</p>

          <p>
            10、追求产品的卓越性能，为用户提供更好的服务体验，是碧湾的一贯宗旨，碧湾将根据实际情况，不断更新产品和/或服务。同时，为了系统的稳定运行，碧湾将在合适的时机，予以系统维护。对此，用户确认碧湾有权事先不予通知而予以更新和维护。
          </p>

          <p>
            11、碧湾不向用户提供任何承诺和担保。若用户发现碧湾下属部门或员工向用户作出任何形式的承诺或担保，则应当向碧湾官方网站（beawan.com）披露的投诉电话和电子信箱予以投诉。碧湾提醒用户，碧湾的产品和服务，均以本协议约定的条款、产品说明书以及官网披露的公开信息为准，任何部门或员工超出此范围所作出的承诺和担保均为无效；碧湾禁止员工与用户的收益有任何关联。
          </p>

          <p>12、碧湾提供的产品和服务，实质上为一种“工具”，用户采用这种“工具”，并不意味着碧湾保证用户有所收益或不亏损。“市场有风险，投资需谨慎”，碧湾敬请用户理性投资。</p>

          <p>
            13、由于移动互联网本身的特性（包括但不限于网络拥堵、计算机病毒感染、黑客攻击、系统停机、设备设施故障等），以及不可抗力、意外事件的客观存在，碧湾并不保证服务能够时刻畅通、不中断。碧湾确保尽力维护用户的利益，确保自身的产品服务形象，并尽可能作出最大的救济。
          </p>

          <p>14、碧湾提醒用户，移动互联网不是净土，相关犯罪及损害用户利益的现象客观存在，敬请用户谨慎选择相关应用，保护用户个人隐私，以免波及碧湾的产品和服务。</p>

          <p>15、用户不得利用碧湾产品和服务，干扰碧湾正常运营，侵犯其他用户或第三方合法权益，以及其他触犯国家法律法规的情形，包括但不限于：
            违反宪法确定的基本原则的；危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；损害国家荣誉和利益的；煽动民族仇恨、民族歧视，破坏民族团结的；破坏国家宗教政策，宣扬邪教和封建迷信的；散布谣言，扰乱社会秩序，破坏社会稳定的；散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；侮辱或者诽谤他人，侵害他人合法权益的；煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；以非法民间组织名义活动的；发布、传送、传播、储存违反国家法律法规禁止的内容；发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利内容的；涉及他人隐私、个人信息或资料的；发表、传送、传播骚扰、广告信息、过度营销信息及垃圾信息或含有任何性或性暗示的；不符合《即时通信工具公众信息服务发展管理暂行规定》及遵守法律法规、社会主义制度、国家利益、公民合法利益、公共秩序、社会道德风尚和信息真实性等“七条底线”要求的；含有法律、行政法规禁止的其他内容的。
          </p>

          <p>16、用户应当合理使用碧湾产品和服务，尊重碧湾及其他相关著作权人的权利，不得从事下列行为：
            删除著作权的信息；进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；授权他人使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非碧湾经授权的第三方工具/服务接入本软件和相关系统；通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；通过非碧湾开发、授权的第三方软件、插件、外挂、系统，登录或使用碧湾软件及服务，或制作、发布、传播上述工具；自行或者授权他人、第三方软件对本软件及其组件、模块、数据进行干扰；其他未经碧湾明示授权的行为。
          </p>

          <p>17、碧湾提醒用户，用户应当对平台中第三方发表的言论、提供的信息或链接以及其他内容，保持独立、清醒、客观的认识，不要上当受骗。如有必要，用户可以向专业机构和人士咨询，向碧湾投诉，向监管机关举报。</p>

          <p>18、用户应当对自己的言论、行为负责，并承担由此造成的法律后果。用户应当保管好账号、密码，防止他人恶意登录，从事非法行为。</p>

          <p>19、如碧湾相关平台允许用户发表相关信息的，提请用户遵守相关法律规定，并且不得夹杂广告、促销、宣传、策划、炒作之类的信息，否则碧湾有权直接予以删除，并对违规用户限制使用。</p>

          <p>
            20、如用户违反法律法规规定和本协议规定的，碧湾有权视情节轻重，采取警告、删除、责令整改、限制使用、终止协议停止服务等措施。如给碧湾造成损失的，碧湾有权向用户追偿，并要求用户承担由此产生的诉讼费、公证费、文印费、公告费、鉴定费、拍卖费、执行费、律师费等一切合理费用。
          </p>

          <p>
            21、鉴于移动互联网的特性，用户同意碧湾以网站（https://beawan.com/）公告、手机短信、电子邮件、信息推送等符合移动互联网发展趋势的方式，送达相关通知和商业信息。用户在此授权碧湾向其预留的电话号码拨打回访电话。
          </p>

          <p>22、用户需要联络碧湾的，可以拨打电话+0571 8668 5367，可来函至杭州市滨江区江陵南路312号B幢杭州碧湾信息技术有限公司。</p>

          <p>23、因履行本协议发生争议的，双方应友好协商，协商不成的，提交碧湾住所地（杭州市滨江区）有管辖权的法院裁决。</p>

          <p>24、为更好地保护用户的利益，碧湾建议用户仔细阅读以下法律法规：</p>

          <p>《中华人民共和国网络安全法》</p>

          <p>《中华人民共和国电信条例》</p>

          <p>《互联网信息服务管理办法》</p>

          <p>《互联网电子公告服务管理规定》</p>

          <p>《中华人民共和国计算机信息网络国际联网管理暂行规定》</p>

          <p>《中华人民共和国计算机信息网络国际联网管理暂行规定实施办法》</p>

          <p>《中华人民共和国计算机信息系统安全保护条例》</p>

          <p>《计算机信息网络国际联网安全保护管理办法》</p>

          <p>25、本协议自用户点击“同意”按钮后生效。</p>
        </div>
      </div>
      <div id="privacy-policy" @click="hiddenBox" style="display: none;">
        <div @click="(event) => { event.stopPropagation() }" class="text-box">

          <p style="width: 94%; margin-top: 10px; font-weight: 600; font-size: 20px; text-align: center;">
                碧湾隐私政策
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 14px; text-align: right;">
                本版发布日期：2023年09月27日
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 14px; text-align: right;">
                生效日期：2023年09月27日
            </p>
            <p style="width: 94%; margin-top: 10px; font-weight: 600; font-size: 18px; text-align: justify;">
                一、声明
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                杭州碧湾信息技术有限公司(注册地址：浙江省杭州市滨江区江陵南路312号B幢，联系方式：+0571 8668 5367，以下简称“我们”)尊重并保护您的隐私，您通过计算机设备、移动终端或其他设备使用我们的服务时，我们会收集和使用您的相关信息。我们希望通过本隐私政策（以下简称“本政策”）向您说明，如您页面浏览/注册并登陆我们的官方网站（网址：www.beawan.com）及APP“碧湾”，并使用我们在上述平台的全部服务时，我们会收集您的哪些信息，并如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、控制和保护这些信息的方式。
                本政策与您使用我们的服务关系紧密，我们建议您仔细阅读并理解本政策全部内容，做出您认为适当的选择。请注意我们不时地会检查我们的政策，因此有关的措施会随之变化。我们恳请您定期光顾本页面，以确保对我们《隐私政策》最新版本始终保持了解。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                您使用或继续使用我们的服务，即意味着同意我们按照本政策收集、使用、储存和分享您的相关信息。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (a) 本应用尊重并保护所有使用服务用户的个人隐私权。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (b) 为了给您提供更准确、更有个性化的服务，本应用会按照本隐私政策的规定使用和披露您的个人信息。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (c) 但本应用将以高度的勤勉、审慎义务对待这些信息。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (d) 除本隐私政策另有规定外，在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (e) 本应用会不时更新本隐私政策。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (f) 您在同意本应用服务使用协议之时，即视为您已经同意本隐私政策全部内容。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (g) 本隐私政策属于本应用服务使用协议不可分割的一部分。
            </p>
            <p style="width: 94%; margin-top: 10px; font-weight: 600; font-size: 18px; text-align: justify;">
                二、收集和使用个人信息的适用范围
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                我们会按照如下方式收集您在使用服务时主动提供的，以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息：
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                2.1 注册、登录
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (a) 在您注册本应用帐号时，您根据本应用要求提供的个人注册信息；
            </p>
            <p style="width: 94%; font-weight: 600; margin-top: 10px; font-size: 16px; text-align: justify;">
                (b) 使用第三方帐号登录并使用碧湾，您将授权我们获取您在第三方平台注册的公开信息（头像、昵称以及您授权的其他信息），用于与市值风云帐号绑定，使您可以直接登录并使用本产品和相关服务。在使用第三方账号进行登录时，需要将实现登录所必需的信息在剪切板中写入与读取。这些信息仅供实现登录相关的目的所使用，不会收集您的隐私信息。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                2.2 搜索
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                <span style="font-weight: 600;">
                    您使用碧湾的搜索服务时，我们会收集您的搜索关键字信息、日志记录。
                </span>
                为了提供高效的搜索服务，部分前述信息会暂时存储在您的本地存储设备之中，并可向您展示搜索结果内容。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                2.3 运营与安全运行
            </p>
            <p style="font-weight: 600; width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (a) 为了保障软件与服务的安全运行、运营的质量及效率，我们会收集您的硬件型号、操作系统版本号、国际移动设备识别码、唯一设备标识符、网络设备硬件地址、IP 地址、WLAN接入点、蓝牙、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志。
            </p>
            <p style="font-weight: 600; width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (b) 为了预防恶意程序、保障运营质量及效率，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                2.4 统计分析
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                为了分析应用新增、激活、留存、性能等统计性指标，
                <span style="font-weight: 600;">
                    我们可能会调用剪切板对相关统计信息进行归因分析，
                </span>
                请您放心，在统计过程中，我们不会将您写入剪切板的其他信息回传，我们不会收集您的隐私信息。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                2.5 依法豁免征得同意收集和使用的个人信息
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                请您理解，在下列情形中，根据法律法规及相关国家标准，我们收集和使用您的个人信息无需征得您的授权同意：
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (a) 与国家安全、国防安全直接相关的
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (b) 与公共安全、公共卫生、重大公共利益直接相关的；
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (c) 与犯罪侦查、起诉、审判和判决执行等直接相关的；
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (d) 出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (e) 所收集的您的个人信息是您自行向社会公众公开的；
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (f) 从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (g) 根据您的要求签订或履行合同所必需的；
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (h) 用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障；
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (i) 为合法的新闻报道所必需的；
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (j) 学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (k) 法律法规规定的其他情形。
                特别提示：您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。
            </p>
            <p style="width: 94%; margin-top: 10px; font-weight: 600; font-size: 18px; text-align: justify;">
                三、信息使用
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (a) 本应用不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息。除非事先得到您的许可，或该第三方和本应用（含本应用关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (b) 本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。
                任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。
            </p>
            <p style="width: 94%; font-weight: 600; margin-top: 10px; font-size: 16px; text-align: justify;">
                (c) 本应用会在您在上传头像选择图片时，经过您的授权后有可能访问您的相册及相机，您可以在手机的系统设置中随时关闭此权限。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (d) 本应用会在您注册账户及修改密码时获取您的手机号码。
            </p>
            <p style="width: 94%; margin-top: 10px; font-weight: 600; font-size: 18px; text-align: justify;">
                四、信息披露
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                在如下情况下，本应用将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (a) 经您事先同意，向第三方披露；
            </p>
            <p style="font-weight: 600; width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (b) 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；
            </p>
            <p style="font-weight: 600; width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (c) 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
            </p>
            <p style="font-weight: 600; width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (d) 如您出现违反中国有关法律、法规或者本应用服务协议或相关规则的情况，需要向第三方披露；
            </p>
            <p style="font-weight: 600; width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (e) 如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；
            </p>
            <p style="font-weight: 600; width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (f) 在本应用平台上创建的某一交易中，如交易任何一方履行或部分履行了交易义务并提出信息披露请求的，本应用有权决定向该用户提供其交易对方的联络方式等必要信息，以促成交易的完成或纠纷的解决。
            </p>
            <p style="font-weight: 600; width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (g) 其它本应用根据法律、法规或者网站政策认为合适的披露。
            </p>
            <p style="width: 94%; margin-top: 10px; font-weight: 600; font-size: 18px; text-align: justify;">
                五、信息存储和交换
            </p>
            <p style="font-weight: 600; width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                本应用收集的有关您的信息和资料将保存在本应用及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或本应用收集信息和资料所在地的境外并在境外被访问、存储和展示。
            </p>
            <p style="width: 94%; margin-top: 10px; font-weight: 600; font-size: 18px; text-align: justify;">
                六、Cookie的使用
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (a) 在您未拒绝接受cookies的情况下，本应用会在您的应用上设定或取用cookies，以便您能登录或使用依赖于cookies的本应用平台服务或功能。本应用使用cookies可为您提供更加周到的个性化服务，包括推广服务。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (b) 您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。
                但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的本应用网络服务或功能。
                * 通过本应用所设cookies所取得的有关信息，将适用本政策。
            </p>
            <p style="width: 94%; margin-top: 10px; font-weight: 600; font-size: 18px; text-align: justify;">
                七、信息安全
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                本应用账号均有安全保护功能，请妥善保管您的用户名及密码信息。本应用将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。
            </p>
            <p style="width: 94%; margin-top: 10px; font-weight: 600; font-size: 18px; text-align: justify;">
                八、授权条款
            
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                我们服务中某些功能的技术模块由第三方提供，该等技术模块以软件开发工具包（SDK）或应用程序接口（API）形式接入到我们的APP中。我们会与该等第三方签署严格的保密协定，要求他们按照我们的要求、本隐私政策以及相关的保密和安全措施妥善保护和处理您的个人信息。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                我们对接入的相关第三方SDK如下所示：
            </p>
            <p style="font-weight: 600; width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                Android 操作系统第三方SDK列表
            </p>
            
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                （1）微信开放平台SDK
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: justify;">
                使用目的：实现微信分享、登录、支付等功能
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: justify;">
                使用场景：提供微信分享、登录、支付等功能
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: justify;">
                数据类型：联网信息、设备信息（OAID、Android ID、IDFA等）、本机号码、外部存储信息
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: justify;">
                官网链接：<a style="color: blue;" href="https://open.weixin.qq.com/">https://open.weixin.qq.com/</a>
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: left;">
                隐私政策：<a style="color: blue;" href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy">https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy</a>
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                （2）支付宝SDK
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: justify;">
                使用目的：分享、支付
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: justify;">
                使用场景：实现支付宝分享、支付
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: justify;">
                数据类型：联网信息、设备信息（IMEI/IMSI、SIM卡序列号/MAC等）、本机号码
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: justify;">
                官网链接：<a style="color: blue;" href="https://open.alipay.com/">https://open.alipay.com/</a>
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: left;">
                隐私政策：<a style="color: blue;" href="https://opendocs.alipay.com/open/54/01g6qm">https://opendocs.alipay.com/open/54/01g6qm</a>
            </p>
            <p style="font-weight: 600; width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                iOS操作系统第三方SDK列表
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                （1）微信开放平台SDK
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: justify;">
                使用目的：实现微信分享、登录、支付等功能
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: justify;">
                使用场景：提供微信分享、登录、支付等功能
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: justify;">
                数据类型：联网信息、设备信息（OAID、Android ID、IDFA等）、本机号码、外部存储信息
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: justify;">
                官网链接：<a style="color: blue;" href="https://open.weixin.qq.com/">https://open.weixin.qq.com/</a>
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: left;">
                隐私政策：<a style="color: blue;" href="https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy">https://weixin.qq.com/cgi-bin/readtemplate?lang=zh_CN&t=weixin_agreement&s=privacy</a>
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                （2）支付宝SDK
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: justify;">
                使用目的：分享、支付
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: justify;">
                使用场景：实现支付宝分享、支付
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: justify;">
                数据类型：联网信息、设备信息（IMEI/IMSI、SIM卡序列号/MAC等）、本机号码
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: justify;">
                官网链接：<a style="color: blue;" href="https://open.alipay.com/">https://open.alipay.com/</a>
            </p>
            <p style="width: 94%; margin-top: 8px; font-size: 16px; text-align: left;">
                隐私政策：<a style="color: blue;" href="https://opendocs.alipay.com/open/54/01g6qm">https://opendocs.alipay.com/open/54/01g6qm</a>
            </p>
            <p style="width: 94%; margin-top: 10px; font-weight: 600; font-size: 18px; text-align: justify;">
                九、本隐私政策的更改
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (a) 如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (b) 本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。方披露自己的个人信息，如联络方式或者邮政地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息泄密，尤其是本应用用户名及密码发生泄露，请您立即联络本应用客服，以便本应用采取相应措施。
            </p>
            <p style="width: 94%; margin-top: 10px; font-weight: 600; font-size: 18px; text-align: justify;">
                十、我们如何保护未成年人的信息
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                1. 本指引所指未成年人是根据《中华人民共和国未成年人保护法》规定的未满十八周岁的公民。
            </p>
            
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                2. 我们的业务主要面向十八周岁以上公民使用，如果是未满十八周岁的未成年人，建议请您的监护人阅读本指引或在监护人的指导下阅读本指引，并在取得您的监护人同意的前提下向我们提供您的相关信息以便使用我们的服务。对于经监护人同意而收集您的信息的情况，我们只会在受到法律允许、监护人明确同意或者保护您的权益所必要的情况下使用或公开披露此信息。
                3. 如您的监护人不同意您按照本指引使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。
                4. 如您为未成年人的监护人，当您对该未成年人的信息处理存在疑问时，请通过上文中的联系方式及时联系我们。
            </p>
            <p style="width: 94%; margin-top: 10px; font-weight: 600; font-size: 18px; text-align: justify;">
                十一、注销账号
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                若您需要注销账号，请在App内点击【我的】—【个人信息】—【注销账户】按钮，提交注销申请并完成注销。
            </p>
            <p style="width: 94%; margin-top: 10px; font-weight: 600; font-size: 18px; text-align: justify;">
                十二、联系我们
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                如果您有包含但不限于以下的情形：
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (a) 对市值风云的隐私政策、数据处理有任何问题或疑问
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (b) 希望删除个人信息
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (c) 想就可能违反隐私权法律的情况进行投诉
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                (d) 其它情况
            </p>
            <p style="width: 94%; margin-top: 10px; font-size: 16px; text-align: justify;">
                请联系我们，您可以随时发送邮件到info@beawan.com，与我们联系。
            </p>

        </div>
      </div>

    </div>
  </div>
</template>
    
<script>
import $ from 'jquery';
import { LockOnIcon, MobileIcon, BrowseIcon, BrowseOffIcon } from 'tdesign-icons-vue';
import { sendRequestCompany } from '../../static/js/util';

export default {
  name: 'App',
  components: { LockOnIcon, MobileIcon, BrowseIcon, BrowseOffIcon },
  data() {
    return {
      loginType: 0,
      phoneNum: '',
      code: '',
      password: '',
      passwordSee: false,
      codeBtnState: true,
    }
  },
  mounted() {

  },
  methods: {
    showBox1() {
      $("#user-agreement").css('display', 'block');
    },
    showBox2() {
      $("#privacy-policy").css('display', 'block');
    },
    hiddenBox() {
      $("#user-agreement").css('display', 'none');
      $("#privacy-policy").css('display', 'none');
    },
    getCode() {
      if (this.phoneNum.length == 0) {
        this.$message.warning('手机号不能为空');
        return;
      }
      var pattern = /^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/g
      if (!pattern.test(this.phoneNum)) {
        this.$message.error("号码错误");
        return;
      }
      if (this.codeBtnState) {
        this.codeBtnState = false;
        $('.input-button').css('cursor', 'no-drop');

        let url = '/user/sendCode?phoneNumber=' + this.phoneNum;

        sendRequestCompany(url, 'get', null, null, (res) => {
          // console.log(res)
          if (res.code == 1) {
            this.$message.error(res.msg);
          }else{
            this.$message.success('发送成功，请注意接收短信验证码');

            var second = 60;
            var time = setInterval(() => {
              $('.input-button').html((second--) + ' s');
              if (second < 0) {
                clearInterval(time);
                $('.input-button').html('发送验证码');
                this.codeBtnState = true;
                $('.input-button').css('cursor', 'pointer');
              }
            }, 1000);
          }
        }, (err) => {
          console.log(err)
          this.codeBtnState = true;
          $('.input-button').css('cursor', 'pointer');
          this.$message.error("服务器异常！")
        });
      }
    },
    login1() {
      if (this.phoneNum.length == 0) {
        this.$message.warning('手机号不能为空');
        return;
      }
      if (this.code.length == 0) {
        this.$message.warning('验证码不能为空');
        return;
      }

      let url = '/user/loginByCode?phoneNumber=' + this.phoneNum + '&' + 'code=' + this.code;

      sendRequestCompany(url, 'get', null, null, (res) => {
        // console.log(res);
        if (res.code == 1) {
          this.$message.error(res.msg);
          return;
        } else if (res.code == 0) {
          // sessionStorage.setItem('user_info', JSON.stringify(res.data));
          localStorage.setItem('satoken', res.data.tokenValue);
          // document.cookie = 'satoken='+res.data.tokenValue;
          this.$message.success('登录成功');
          setTimeout(() => {
            // window.location.href = '/beawanHome';
            this.$router.push({
              name: 'reportHome'
            });
          }, 1000);
        } else {
          this.$message.error('登录异常')
        }
      }, (err) => {
        console.log(err)
      });

    },
    login2() {
      if (this.phoneNum.length == 0) {
        this.$message.warning('手机号不能为空');
        return;
      }
      if (this.password.length == 0) {
        this.$message.warning('密码不能为空');
        return;
      }

      let url = '/user/loginByPassword?phoneNumber=' + this.phoneNum + '&' + 'password=' + this.password;

      sendRequestCompany(url, 'get', null, null, (res) => {
        // console.log(res);
        if (res.code == 1) {
          this.$message.error(res.msg);
          return;
        } else if (res.code == 0) {
          // sessionStorage.setItem('user_info', JSON.stringify(res.data));
          localStorage.setItem('satoken', res.data.tokenValue);
          localStorage.setItem('satokenDate', new Date().toLocaleDateString());
          // document.cookie = 'satoken='+res.data.tokenValue;
          this.$message.success('登录成功');
          setTimeout(() => {
            // window.location.href = '/beawanHome';
            this.$router.push({
              name: 'reportHome'
            });
          }, 1000);
        } else {
          this.$message.error('登录异常')
        }
      }, (err) => {
        console.log(err)
      });
    }

  }
}
</script>
    
<style scoped>
* {
  box-sizing: border-box;
}

.login-body {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f5f7;
}

.login-view {
  font-family: 'Montserrat', sans-serif;
  background: #f6f5f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
  width: 500px;
  margin: 0;
}

h1 {
  font-weight: bold;
  margin: 0;
}

h2 {
  text-align: center;
}

/* span {
  font-size: 16px;
  color: grey;
} */

.form-container .form {
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.form-container input {
  background: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  outline: none;
}

.form>button {
  border-radius: 20px;
  border: 1px solid lightseagreen;
  background: lightseagreen;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  padding: 10px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}

button:active {
  transform: scale(.95);
}

.form>button:focus {
  outline: none;
}

.form span {
  font-size: 14px;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all .6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 100%;
  z-index: 2;
}

.dowebok {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22);
  position: relative;
  overflow: hidden;
  width: 384px;
  max-width: 100%;
  min-height: 430px;
}

.input-button {
  background: #e5e5e5;
  height: 39px;
  width: 40%;
  line-height: 39px;
  color: grey;
  border: 0;
  border-left: 1px solid #fff;
  cursor: pointer;
}

#user-agreement,
#privacy-policy {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  overflow: auto;
  padding-bottom: 100px;
}

.text-box {
  width: 100%;
  margin: 100px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 7px;
}
</style>