const data = [
  {
    "id": 1,
    "type": "1",
    "name": "银行",
    "level": "1",
    "code": "480000"
  },
  {
    "id": 2,
    "type": "1",
    "name": "纺织服饰",
    "level": "1",
    "code": "350000"
  },
  {
    "id": 3,
    "type": "1",
    "name": "房地产",
    "level": "1",
    "code": "430000"
  },
  {
    "id": 4,
    "type": "1",
    "name": "煤炭",
    "level": "1",
    "code": "740000"
  },
  {
    "id": 5,
    "type": "1",
    "name": "公用事业",
    "level": "1",
    "code": "410000"
  },
  {
    "id": 6,
    "type": "1",
    "name": "传媒",
    "level": "1",
    "code": "720000"
  },
  {
    "id": 7,
    "type": "1",
    "name": "美容护理",
    "level": "1",
    "code": "770000"
  },
  {
    "id": 8,
    "type": "1",
    "name": "建筑装饰",
    "level": "1",
    "code": "620000"
  },
  {
    "id": 9,
    "type": "1",
    "name": "农林牧渔",
    "level": "1",
    "code": "110000"
  },
  {
    "id": 10,
    "type": "1",
    "name": "轻工制造",
    "level": "1",
    "code": "360000"
  },
  {
    "id": 11,
    "type": "1",
    "name": "钢铁",
    "level": "1",
    "code": "230000"
  },
  {
    "id": 12,
    "type": "1",
    "name": "通信",
    "level": "1",
    "code": "730000"
  },
  {
    "id": 13,
    "type": "1",
    "name": "电力设备",
    "level": "1",
    "code": "630000"
  },
  {
    "id": 14,
    "type": "1",
    "name": "石油石化",
    "level": "1",
    "code": "750000"
  },
  {
    "id": 15,
    "type": "1",
    "name": "汽车",
    "level": "1",
    "code": "280000"
  },
  {
    "id": 16,
    "type": "1",
    "name": "机械设备",
    "level": "1",
    "code": "640000"
  },
  {
    "id": 17,
    "type": "1",
    "name": "建筑材料",
    "level": "1",
    "code": "610000"
  },
  {
    "id": 18,
    "type": "1",
    "name": "环保",
    "level": "1",
    "code": "760000"
  },
  {
    "id": 19,
    "type": "1",
    "name": "医药生物",
    "level": "1",
    "code": "370000"
  },
  {
    "id": 20,
    "type": "1",
    "name": "社会服务",
    "level": "1",
    "code": "460000"
  },
  {
    "id": 21,
    "type": "1",
    "name": "家用电器",
    "level": "1",
    "code": "330000"
  },
  {
    "id": 22,
    "type": "1",
    "name": "食品饮料",
    "level": "1",
    "code": "340000"
  },
  {
    "id": 23,
    "type": "1",
    "name": "非银金融",
    "level": "1",
    "code": "490000"
  },
  {
    "id": 24,
    "type": "1",
    "name": "电子",
    "level": "1",
    "code": "270000"
  },
  {
    "id": 25,
    "type": "1",
    "name": "综合",
    "level": "1",
    "code": "510000"
  },
  {
    "id": 26,
    "type": "1",
    "name": "计算机",
    "level": "1",
    "code": "710000"
  },
  {
    "id": 27,
    "type": "1",
    "name": "有色金属",
    "level": "1",
    "code": "240000"
  },
  {
    "id": 28,
    "type": "1",
    "name": "商贸零售",
    "level": "1",
    "code": "450000"
  },
  {
    "id": 29,
    "type": "1",
    "name": "基础化工",
    "level": "1",
    "code": "220000"
  },
  {
    "id": 30,
    "type": "1",
    "name": "交通运输",
    "level": "1",
    "code": "420000"
  },
  {
    "id": 31,
    "type": "1",
    "name": "国防军工",
    "level": "1",
    "code": "650000"
  },
  {
    "id": 32,
    "type": "1",
    "name": "其他家电",
    "level": "2",
    "code": "330700"
  },
  {
    "id": 33,
    "type": "1",
    "name": "渔业",
    "level": "2",
    "code": "110200"
  },
  {
    "id": 34,
    "type": "1",
    "name": "轨交设备",
    "level": "2",
    "code": "640500"
  },
  {
    "id": 35,
    "type": "1",
    "name": "电视广播",
    "level": "2",
    "code": "721000"
  },
  {
    "id": 36,
    "type": "1",
    "name": "环境治理",
    "level": "2",
    "code": "760100"
  },
  {
    "id": 37,
    "type": "1",
    "name": "房地产开发",
    "level": "2",
    "code": "430100"
  },
  {
    "id": 38,
    "type": "1",
    "name": "家居用品",
    "level": "2",
    "code": "360300"
  },
  {
    "id": 39,
    "type": "1",
    "name": "装修建材",
    "level": "2",
    "code": "610300"
  },
  {
    "id": 40,
    "type": "1",
    "name": "农化制品",
    "level": "2",
    "code": "220800"
  },
  {
    "id": 41,
    "type": "1",
    "name": "白酒",
    "level": "2",
    "code": "340500"
  },
  {
    "id": 42,
    "type": "1",
    "name": "元件",
    "level": "2",
    "code": "270200"
  },
  {
    "id": 43,
    "type": "1",
    "name": "饲料",
    "level": "2",
    "code": "110400"
  },
  {
    "id": 44,
    "type": "1",
    "name": "休闲食品",
    "level": "2",
    "code": "340800"
  },
  {
    "id": 45,
    "type": "1",
    "name": "乘用车",
    "level": "2",
    "code": "280500"
  },
  {
    "id": 46,
    "type": "1",
    "name": "塑料",
    "level": "2",
    "code": "220500"
  },
  {
    "id": 47,
    "type": "1",
    "name": "互联网电商",
    "level": "2",
    "code": "450600"
  },
  {
    "id": 48,
    "type": "1",
    "name": "汽车服务",
    "level": "2",
    "code": "280300"
  },
  {
    "id": 49,
    "type": "1",
    "name": "医疗美容",
    "level": "2",
    "code": "770300"
  },
  {
    "id": 50,
    "type": "1",
    "name": "燃气",
    "level": "2",
    "code": "410300"
  },
  {
    "id": 51,
    "type": "1",
    "name": "生物制品",
    "level": "2",
    "code": "370300"
  },
  {
    "id": 52,
    "type": "1",
    "name": "小家电",
    "level": "2",
    "code": "330300"
  },
  {
    "id": 53,
    "type": "1",
    "name": "油气开采",
    "level": "2",
    "code": "750100"
  },
  {
    "id": 54,
    "type": "1",
    "name": "电子化学品",
    "level": "2",
    "code": "270600"
  },
  {
    "id": 55,
    "type": "1",
    "name": "调味发酵品",
    "level": "2",
    "code": "340900"
  },
  {
    "id": 56,
    "type": "1",
    "name": "铁路公路",
    "level": "2",
    "code": "420900"
  },
  {
    "id": 57,
    "type": "1",
    "name": "房屋建设",
    "level": "2",
    "code": "620100"
  },
  {
    "id": 58,
    "type": "1",
    "name": "饰品",
    "level": "2",
    "code": "350300"
  },
  {
    "id": 59,
    "type": "1",
    "name": "水泥",
    "level": "2",
    "code": "610100"
  },
  {
    "id": 60,
    "type": "1",
    "name": "计算机设备",
    "level": "2",
    "code": "710100"
  },
  {
    "id": 61,
    "type": "1",
    "name": "通信服务",
    "level": "2",
    "code": "730100"
  },
  {
    "id": 62,
    "type": "1",
    "name": "软件开发",
    "level": "2",
    "code": "710400"
  },
  {
    "id": 63,
    "type": "1",
    "name": "林业",
    "level": "2",
    "code": "110300"
  },
  {
    "id": 64,
    "type": "1",
    "name": "特钢",
    "level": "2",
    "code": "230500"
  },
  {
    "id": 65,
    "type": "1",
    "name": "化学制药",
    "level": "2",
    "code": "370100"
  },
  {
    "id": 66,
    "type": "1",
    "name": "数字媒体",
    "level": "2",
    "code": "720700"
  },
  {
    "id": 67,
    "type": "1",
    "name": "化学原料",
    "level": "2",
    "code": "220200"
  },
  {
    "id": 68,
    "type": "1",
    "name": "个护用品",
    "level": "2",
    "code": "770100"
  },
  {
    "id": 69,
    "type": "1",
    "name": "文娱用品",
    "level": "2",
    "code": "360500"
  },
  {
    "id": 70,
    "type": "1",
    "name": "物流",
    "level": "2",
    "code": "420800"
  },
  {
    "id": 71,
    "type": "1",
    "name": "医疗器械",
    "level": "2",
    "code": "370500"
  },
  {
    "id": 72,
    "type": "1",
    "name": "航运港口",
    "level": "2",
    "code": "421100"
  },
  {
    "id": 73,
    "type": "1",
    "name": "种植业",
    "level": "2",
    "code": "110100"
  },
  {
    "id": 74,
    "type": "1",
    "name": "体育",
    "level": "2",
    "code": "460600"
  },
  {
    "id": 75,
    "type": "1",
    "name": "专业工程",
    "level": "2",
    "code": "620400"
  },
  {
    "id": 76,
    "type": "1",
    "name": "医药商业",
    "level": "2",
    "code": "370400"
  },
  {
    "id": 77,
    "type": "1",
    "name": "纺织制造",
    "level": "2",
    "code": "350100"
  },
  {
    "id": 78,
    "type": "1",
    "name": "农业综合",
    "level": "2",
    "code": "110900"
  },
  {
    "id": 79,
    "type": "1",
    "name": "能源金属",
    "level": "2",
    "code": "240600"
  },
  {
    "id": 80,
    "type": "1",
    "name": "旅游零售",
    "level": "2",
    "code": "450700"
  },
  {
    "id": 81,
    "type": "1",
    "name": "专业连锁",
    "level": "2",
    "code": "450400"
  },
  {
    "id": 82,
    "type": "1",
    "name": "饮料乳品",
    "level": "2",
    "code": "340700"
  },
  {
    "id": 83,
    "type": "1",
    "name": "城商行",
    "level": "2",
    "code": "480400"
  },
  {
    "id": 84,
    "type": "1",
    "name": "通信设备",
    "level": "2",
    "code": "730200"
  },
  {
    "id": 85,
    "type": "1",
    "name": "半导体",
    "level": "2",
    "code": "270100"
  },
  {
    "id": 86,
    "type": "1",
    "name": "电池",
    "level": "2",
    "code": "630700"
  },
  {
    "id": 87,
    "type": "1",
    "name": "军工电子",
    "level": "2",
    "code": "650500"
  },
  {
    "id": 88,
    "type": "1",
    "name": "航空装备",
    "level": "2",
    "code": "650200"
  },
  {
    "id": 89,
    "type": "1",
    "name": "化学纤维",
    "level": "2",
    "code": "220400"
  },
  {
    "id": 90,
    "type": "1",
    "name": "IT服务",
    "level": "2",
    "code": "710300"
  },
  {
    "id": 91,
    "type": "1",
    "name": "金属新材料",
    "level": "2",
    "code": "240200"
  },
  {
    "id": 92,
    "type": "1",
    "name": "电机",
    "level": "2",
    "code": "630100"
  },
  {
    "id": 93,
    "type": "1",
    "name": "其他电源设备",
    "level": "2",
    "code": "630300"
  },
  {
    "id": 94,
    "type": "1",
    "name": "家电零部件",
    "level": "2",
    "code": "330600"
  },
  {
    "id": 95,
    "type": "1",
    "name": "贸易",
    "level": "2",
    "code": "450200"
  },
  {
    "id": 96,
    "type": "1",
    "name": "贵金属",
    "level": "2",
    "code": "240400"
  },
  {
    "id": 97,
    "type": "1",
    "name": "光学光电子",
    "level": "2",
    "code": "270300"
  },
  {
    "id": 98,
    "type": "1",
    "name": "消费电子",
    "level": "2",
    "code": "270500"
  },
  {
    "id": 99,
    "type": "1",
    "name": "专用设备",
    "level": "2",
    "code": "640200"
  },
  {
    "id": 100,
    "type": "1",
    "name": "地面兵装",
    "level": "2",
    "code": "650300"
  },
  {
    "id": 101,
    "type": "1",
    "name": "证券",
    "level": "2",
    "code": "490100"
  },
  {
    "id": 102,
    "type": "1",
    "name": "冶钢原料",
    "level": "2",
    "code": "230300"
  },
  {
    "id": 103,
    "type": "1",
    "name": "保险",
    "level": "2",
    "code": "490200"
  },
  {
    "id": 104,
    "type": "1",
    "name": "通用设备",
    "level": "2",
    "code": "640100"
  },
  {
    "id": 105,
    "type": "1",
    "name": "出版",
    "level": "2",
    "code": "720900"
  },
  {
    "id": 106,
    "type": "1",
    "name": "工程机械",
    "level": "2",
    "code": "640600"
  },
  {
    "id": 107,
    "type": "1",
    "name": "工程咨询服务",
    "level": "2",
    "code": "620600"
  },
  {
    "id": 108,
    "type": "1",
    "name": "动物保健",
    "level": "2",
    "code": "110800"
  },
  {
    "id": 109,
    "type": "1",
    "name": "农商行",
    "level": "2",
    "code": "480500"
  },
  {
    "id": 110,
    "type": "1",
    "name": "股份制银行",
    "level": "2",
    "code": "480300"
  },
  {
    "id": 111,
    "type": "1",
    "name": "电力",
    "level": "2",
    "code": "410100"
  },
  {
    "id": 112,
    "type": "1",
    "name": "多元金融",
    "level": "2",
    "code": "490300"
  },
  {
    "id": 113,
    "type": "1",
    "name": "游戏",
    "level": "2",
    "code": "720400"
  },
  {
    "id": 114,
    "type": "1",
    "name": "煤炭开采",
    "level": "2",
    "code": "740100"
  },
  {
    "id": 115,
    "type": "1",
    "name": "商用车",
    "level": "2",
    "code": "280600"
  },
  {
    "id": 116,
    "type": "1",
    "name": "酒店餐饮",
    "level": "2",
    "code": "460900"
  },
  {
    "id": 117,
    "type": "1",
    "name": "养殖业",
    "level": "2",
    "code": "110700"
  },
  {
    "id": 118,
    "type": "1",
    "name": "摩托车及其他",
    "level": "2",
    "code": "280400"
  },
  {
    "id": 119,
    "type": "1",
    "name": "厨卫电器",
    "level": "2",
    "code": "330400"
  },
  {
    "id": 120,
    "type": "1",
    "name": "专业服务",
    "level": "2",
    "code": "460800"
  },
  {
    "id": 121,
    "type": "1",
    "name": "医疗服务",
    "level": "2",
    "code": "370600"
  },
  {
    "id": 122,
    "type": "1",
    "name": "黑色家电",
    "level": "2",
    "code": "330200"
  },
  {
    "id": 123,
    "type": "1",
    "name": "普钢",
    "level": "2",
    "code": "230400"
  },
  {
    "id": 124,
    "type": "1",
    "name": "造纸",
    "level": "2",
    "code": "360100"
  },
  {
    "id": 125,
    "type": "1",
    "name": "国有大型银行",
    "level": "2",
    "code": "480200"
  },
  {
    "id": 126,
    "type": "1",
    "name": "焦炭",
    "level": "2",
    "code": "740200"
  },
  {
    "id": 127,
    "type": "1",
    "name": "化妆品",
    "level": "2",
    "code": "770200"
  },
  {
    "id": 128,
    "type": "1",
    "name": "光伏设备",
    "level": "2",
    "code": "630500"
  },
  {
    "id": 129,
    "type": "1",
    "name": "其他电子",
    "level": "2",
    "code": "270400"
  },
  {
    "id": 130,
    "type": "1",
    "name": "自动化设备",
    "level": "2",
    "code": "640700"
  },
  {
    "id": 131,
    "type": "1",
    "name": "非白酒",
    "level": "2",
    "code": "340600"
  },
  {
    "id": 132,
    "type": "1",
    "name": "玻璃玻纤",
    "level": "2",
    "code": "610200"
  },
  {
    "id": 133,
    "type": "1",
    "name": "化学制品",
    "level": "2",
    "code": "220300"
  },
  {
    "id": 134,
    "type": "1",
    "name": "装修装饰",
    "level": "2",
    "code": "620200"
  },
  {
    "id": 135,
    "type": "1",
    "name": "教育",
    "level": "2",
    "code": "461100"
  },
  {
    "id": 136,
    "type": "1",
    "name": "非金属材料",
    "level": "2",
    "code": "220900"
  },
  {
    "id": 137,
    "type": "1",
    "name": "白色家电",
    "level": "2",
    "code": "330100"
  },
  {
    "id": 138,
    "type": "1",
    "name": "一般零售",
    "level": "2",
    "code": "450300"
  },
  {
    "id": 139,
    "type": "1",
    "name": "影视院线",
    "level": "2",
    "code": "720600"
  },
  {
    "id": 140,
    "type": "1",
    "name": "炼化及贸易",
    "level": "2",
    "code": "750300"
  },
  {
    "id": 141,
    "type": "1",
    "name": "照明设备",
    "level": "2",
    "code": "330500"
  },
  {
    "id": 142,
    "type": "1",
    "name": "汽车零部件",
    "level": "2",
    "code": "280200"
  },
  {
    "id": 143,
    "type": "1",
    "name": "航天装备",
    "level": "2",
    "code": "650100"
  },
  {
    "id": 144,
    "type": "1",
    "name": "广告营销",
    "level": "2",
    "code": "720500"
  },
  {
    "id": 145,
    "type": "1",
    "name": "包装印刷",
    "level": "2",
    "code": "360200"
  },
  {
    "id": 146,
    "type": "1",
    "name": "航海装备",
    "level": "2",
    "code": "650400"
  },
  {
    "id": 147,
    "type": "1",
    "name": "环保设备",
    "level": "2",
    "code": "760200"
  },
  {
    "id": 148,
    "type": "1",
    "name": "中药",
    "level": "2",
    "code": "370200"
  },
  {
    "id": 149,
    "type": "1",
    "name": "电网设备",
    "level": "2",
    "code": "630800"
  },
  {
    "id": 150,
    "type": "1",
    "name": "食品加工",
    "level": "2",
    "code": "340400"
  },
  {
    "id": 151,
    "type": "1",
    "name": "房地产服务",
    "level": "2",
    "code": "430300"
  },
  {
    "id": 152,
    "type": "1",
    "name": "油服工程",
    "level": "2",
    "code": "750200"
  },
  {
    "id": 153,
    "type": "1",
    "name": "农产品加工",
    "level": "2",
    "code": "110500"
  },
  {
    "id": 154,
    "type": "1",
    "name": "橡胶",
    "level": "2",
    "code": "220600"
  },
  {
    "id": 155,
    "type": "1",
    "name": "小金属",
    "level": "2",
    "code": "240500"
  },
  {
    "id": 156,
    "type": "1",
    "name": "航空机场",
    "level": "2",
    "code": "421000"
  },
  {
    "id": 157,
    "type": "1",
    "name": "综合",
    "level": "2",
    "code": "510100"
  },
  {
    "id": 158,
    "type": "1",
    "name": "旅游及景区",
    "level": "2",
    "code": "461000"
  },
  {
    "id": 159,
    "type": "1",
    "name": "服装家纺",
    "level": "2",
    "code": "350200"
  },
  {
    "id": 160,
    "type": "1",
    "name": "工业金属",
    "level": "2",
    "code": "240300"
  },
  {
    "id": 161,
    "type": "1",
    "name": "基础建设",
    "level": "2",
    "code": "620300"
  },
  {
    "id": 162,
    "type": "1",
    "name": "风电设备",
    "level": "2",
    "code": "630600"
  },
  {
    "id": 163,
    "type": "1",
    "name": "原材料供应链服务",
    "level": "3",
    "code": "420802"
  },
  {
    "id": 164,
    "type": "1",
    "name": "钨",
    "level": "3",
    "code": "240502"
  },
  {
    "id": 165,
    "type": "1",
    "name": "种子",
    "level": "3",
    "code": "110101"
  },
  {
    "id": 166,
    "type": "1",
    "name": "其他家电",
    "level": "3",
    "code": "330701"
  },
  {
    "id": 167,
    "type": "1",
    "name": "航空运输",
    "level": "3",
    "code": "421001"
  },
  {
    "id": 168,
    "type": "1",
    "name": "数字芯片设计",
    "level": "3",
    "code": "270104"
  },
  {
    "id": 169,
    "type": "1",
    "name": "电视广播",
    "level": "3",
    "code": "721001"
  },
  {
    "id": 170,
    "type": "1",
    "name": "轨交设备",
    "level": "3",
    "code": "640501"
  },
  {
    "id": 171,
    "type": "1",
    "name": "商业物业经营",
    "level": "3",
    "code": "450304"
  },
  {
    "id": 172,
    "type": "1",
    "name": "线缆部件及其他",
    "level": "3",
    "code": "630805"
  },
  {
    "id": 173,
    "type": "1",
    "name": "印刷包装机械",
    "level": "3",
    "code": "640208"
  },
  {
    "id": 174,
    "type": "1",
    "name": "通信网络设备及器件",
    "level": "3",
    "code": "730204"
  },
  {
    "id": 175,
    "type": "1",
    "name": "纯碱",
    "level": "3",
    "code": "220201"
  },
  {
    "id": 176,
    "type": "1",
    "name": "无机盐",
    "level": "3",
    "code": "220203"
  },
  {
    "id": 177,
    "type": "1",
    "name": "模拟芯片设计",
    "level": "3",
    "code": "270105"
  },
  {
    "id": 178,
    "type": "1",
    "name": "汽车电子电气系统",
    "level": "3",
    "code": "280206"
  },
  {
    "id": 179,
    "type": "1",
    "name": "电网自动化设备",
    "level": "3",
    "code": "630803"
  },
  {
    "id": 180,
    "type": "1",
    "name": "其他多元金融",
    "level": "3",
    "code": "490308"
  },
  {
    "id": 181,
    "type": "1",
    "name": "油气及炼化工程",
    "level": "3",
    "code": "750202"
  },
  {
    "id": 182,
    "type": "1",
    "name": "其他养殖",
    "level": "3",
    "code": "110704"
  },
  {
    "id": 183,
    "type": "1",
    "name": "铜",
    "level": "3",
    "code": "240302"
  },
  {
    "id": 184,
    "type": "1",
    "name": "集成电路封测",
    "level": "3",
    "code": "270107"
  },
  {
    "id": 185,
    "type": "1",
    "name": "运动服装",
    "level": "3",
    "code": "350208"
  },
  {
    "id": 186,
    "type": "1",
    "name": "燃气",
    "level": "3",
    "code": "410301"
  },
  {
    "id": 187,
    "type": "1",
    "name": "复合肥",
    "level": "3",
    "code": "220805"
  },
  {
    "id": 188,
    "type": "1",
    "name": "娱乐用品",
    "level": "3",
    "code": "360502"
  },
  {
    "id": 189,
    "type": "1",
    "name": "其他专用设备",
    "level": "3",
    "code": "640209"
  },
  {
    "id": 190,
    "type": "1",
    "name": "基建市政工程",
    "level": "3",
    "code": "620306"
  },
  {
    "id": 191,
    "type": "1",
    "name": "电信运营商",
    "level": "3",
    "code": "730102"
  },
  {
    "id": 192,
    "type": "1",
    "name": "玻纤制造",
    "level": "3",
    "code": "610202"
  },
  {
    "id": 193,
    "type": "1",
    "name": "其他汽车零部件",
    "level": "3",
    "code": "280205"
  },
  {
    "id": 194,
    "type": "1",
    "name": "电子化学品",
    "level": "3",
    "code": "270601"
  },
  {
    "id": 195,
    "type": "1",
    "name": "调味发酵品",
    "level": "3",
    "code": "340901"
  },
  {
    "id": 196,
    "type": "1",
    "name": "油气开采",
    "level": "3",
    "code": "750101"
  },
  {
    "id": 197,
    "type": "1",
    "name": "氮肥",
    "level": "3",
    "code": "220801"
  },
  {
    "id": 198,
    "type": "1",
    "name": "金融控股",
    "level": "3",
    "code": "490302"
  },
  {
    "id": 199,
    "type": "1",
    "name": "其他农产品加工",
    "level": "3",
    "code": "110504"
  },
  {
    "id": 200,
    "type": "1",
    "name": "半导体设备",
    "level": "3",
    "code": "270108"
  },
  {
    "id": 201,
    "type": "1",
    "name": "棉纺",
    "level": "3",
    "code": "350102"
  },
  {
    "id": 202,
    "type": "1",
    "name": "印制电路板",
    "level": "3",
    "code": "270202"
  },
  {
    "id": 203,
    "type": "1",
    "name": "消费电子零部件及组装",
    "level": "3",
    "code": "270504"
  },
  {
    "id": 204,
    "type": "1",
    "name": "工控设备",
    "level": "3",
    "code": "640702"
  },
  {
    "id": 205,
    "type": "1",
    "name": "医院",
    "level": "3",
    "code": "370604"
  },
  {
    "id": 206,
    "type": "1",
    "name": "其他化学原料",
    "level": "3",
    "code": "220204"
  },
  {
    "id": 207,
    "type": "1",
    "name": "仓储物流",
    "level": "3",
    "code": "420806"
  },
  {
    "id": 208,
    "type": "1",
    "name": "林业",
    "level": "3",
    "code": "110301"
  },
  {
    "id": 209,
    "type": "1",
    "name": "其他生物制品",
    "level": "3",
    "code": "370304"
  },
  {
    "id": 210,
    "type": "1",
    "name": "面板",
    "level": "3",
    "code": "270301"
  },
  {
    "id": 211,
    "type": "1",
    "name": "氨纶",
    "level": "3",
    "code": "220405"
  },
  {
    "id": 212,
    "type": "1",
    "name": "特钢",
    "level": "3",
    "code": "230501"
  },
  {
    "id": 213,
    "type": "1",
    "name": "品牌消费电子",
    "level": "3",
    "code": "270503"
  },
  {
    "id": 214,
    "type": "1",
    "name": "其他橡胶制品",
    "level": "3",
    "code": "220602"
  },
  {
    "id": 215,
    "type": "1",
    "name": "辅料",
    "level": "3",
    "code": "350105"
  },
  {
    "id": 216,
    "type": "1",
    "name": "房地产综合服务",
    "level": "3",
    "code": "430303"
  },
  {
    "id": 217,
    "type": "1",
    "name": "工程机械整机",
    "level": "3",
    "code": "640601"
  },
  {
    "id": 218,
    "type": "1",
    "name": "线下药店",
    "level": "3",
    "code": "370403"
  },
  {
    "id": 219,
    "type": "1",
    "name": "蓄电池及其他电池",
    "level": "3",
    "code": "630705"
  },
  {
    "id": 220,
    "type": "1",
    "name": "保健品",
    "level": "3",
    "code": "340407"
  },
  {
    "id": 221,
    "type": "1",
    "name": "体育",
    "level": "3",
    "code": "460601"
  },
  {
    "id": 222,
    "type": "1",
    "name": "宠物食品",
    "level": "3",
    "code": "110404"
  },
  {
    "id": 223,
    "type": "1",
    "name": "汽车综合服务",
    "level": "3",
    "code": "280303"
  },
  {
    "id": 224,
    "type": "1",
    "name": "机床工具",
    "level": "3",
    "code": "640101"
  },
  {
    "id": 225,
    "type": "1",
    "name": "其他自动化设备",
    "level": "3",
    "code": "640704"
  },
  {
    "id": 226,
    "type": "1",
    "name": "旅游零售",
    "level": "3",
    "code": "450701"
  },
  {
    "id": 227,
    "type": "1",
    "name": "安防设备",
    "level": "3",
    "code": "710102"
  },
  {
    "id": 228,
    "type": "1",
    "name": "城商行",
    "level": "3",
    "code": "480401"
  },
  {
    "id": 229,
    "type": "1",
    "name": "化学工程",
    "level": "3",
    "code": "620402"
  },
  {
    "id": 230,
    "type": "1",
    "name": "零食",
    "level": "3",
    "code": "340801"
  },
  {
    "id": 231,
    "type": "1",
    "name": "分立器件",
    "level": "3",
    "code": "270102"
  },
  {
    "id": 232,
    "type": "1",
    "name": "专业连锁",
    "level": "3",
    "code": "450401"
  },
  {
    "id": 233,
    "type": "1",
    "name": "航空装备",
    "level": "3",
    "code": "650201"
  },
  {
    "id": 234,
    "type": "1",
    "name": "其他专业服务",
    "level": "3",
    "code": "460804"
  },
  {
    "id": 235,
    "type": "1",
    "name": "军工电子",
    "level": "3",
    "code": "650501"
  },
  {
    "id": 236,
    "type": "1",
    "name": "电商服务",
    "level": "3",
    "code": "450603"
  },
  {
    "id": 237,
    "type": "1",
    "name": "其他建材",
    "level": "3",
    "code": "610303"
  },
  {
    "id": 238,
    "type": "1",
    "name": "非运动服装",
    "level": "3",
    "code": "350209"
  },
  {
    "id": 239,
    "type": "1",
    "name": "冶钢辅料",
    "level": "3",
    "code": "230302"
  },
  {
    "id": 240,
    "type": "1",
    "name": "文字媒体",
    "level": "3",
    "code": "720705"
  },
  {
    "id": 241,
    "type": "1",
    "name": "血液制品",
    "level": "3",
    "code": "370302"
  },
  {
    "id": 242,
    "type": "1",
    "name": "稀土",
    "level": "3",
    "code": "240501"
  },
  {
    "id": 243,
    "type": "1",
    "name": "其他饰品",
    "level": "3",
    "code": "350303"
  },
  {
    "id": 244,
    "type": "1",
    "name": "通信工程及服务",
    "level": "3",
    "code": "730103"
  },
  {
    "id": 245,
    "type": "1",
    "name": "其他化学制品",
    "level": "3",
    "code": "220309"
  },
  {
    "id": 246,
    "type": "1",
    "name": "风电零部件",
    "level": "3",
    "code": "630602"
  },
  {
    "id": 247,
    "type": "1",
    "name": "垂直应用软件",
    "level": "3",
    "code": "710401"
  },
  {
    "id": 248,
    "type": "1",
    "name": "信托",
    "level": "3",
    "code": "490304"
  },
  {
    "id": 249,
    "type": "1",
    "name": "仪器仪表",
    "level": "3",
    "code": "640107"
  },
  {
    "id": 250,
    "type": "1",
    "name": "超市",
    "level": "3",
    "code": "450302"
  },
  {
    "id": 251,
    "type": "1",
    "name": "锂电池",
    "level": "3",
    "code": "630701"
  },
  {
    "id": 252,
    "type": "1",
    "name": "农用机械",
    "level": "3",
    "code": "640207"
  },
  {
    "id": 253,
    "type": "1",
    "name": "水产养殖",
    "level": "3",
    "code": "110202"
  },
  {
    "id": 254,
    "type": "1",
    "name": "其他运输设备",
    "level": "3",
    "code": "280401"
  },
  {
    "id": 255,
    "type": "1",
    "name": "工程咨询服务",
    "level": "3",
    "code": "620601"
  },
  {
    "id": 256,
    "type": "1",
    "name": "固废治理",
    "level": "3",
    "code": "760103"
  },
  {
    "id": 257,
    "type": "1",
    "name": "锦纶",
    "level": "3",
    "code": "220406"
  },
  {
    "id": 258,
    "type": "1",
    "name": "制冷空调设备",
    "level": "3",
    "code": "640105"
  },
  {
    "id": 259,
    "type": "1",
    "name": "中间产品及消费品供应链服务",
    "level": "3",
    "code": "420803"
  },
  {
    "id": 260,
    "type": "1",
    "name": "农商行",
    "level": "3",
    "code": "480501"
  },
  {
    "id": 261,
    "type": "1",
    "name": "通信线缆及配套",
    "level": "3",
    "code": "730205"
  },
  {
    "id": 262,
    "type": "1",
    "name": "医疗耗材",
    "level": "3",
    "code": "370503"
  },
  {
    "id": 263,
    "type": "1",
    "name": "原料药",
    "level": "3",
    "code": "370101"
  },
  {
    "id": 264,
    "type": "1",
    "name": "氯碱",
    "level": "3",
    "code": "220202"
  },
  {
    "id": 265,
    "type": "1",
    "name": "其他通信设备",
    "level": "3",
    "code": "730207"
  },
  {
    "id": 266,
    "type": "1",
    "name": "游戏",
    "level": "3",
    "code": "720401"
  },
  {
    "id": 267,
    "type": "1",
    "name": "综合电力设备商",
    "level": "3",
    "code": "630301"
  },
  {
    "id": 268,
    "type": "1",
    "name": "配电设备",
    "level": "3",
    "code": "630802"
  },
  {
    "id": 269,
    "type": "1",
    "name": "定制家居",
    "level": "3",
    "code": "360308"
  },
  {
    "id": 270,
    "type": "1",
    "name": "塑料包装",
    "level": "3",
    "code": "360204"
  },
  {
    "id": 271,
    "type": "1",
    "name": "商业地产",
    "level": "3",
    "code": "430102"
  },
  {
    "id": 272,
    "type": "1",
    "name": "纺织化学制品",
    "level": "3",
    "code": "220308"
  },
  {
    "id": 273,
    "type": "1",
    "name": "炭黑",
    "level": "3",
    "code": "220603"
  },
  {
    "id": 274,
    "type": "1",
    "name": "硅料硅片",
    "level": "3",
    "code": "630501"
  },
  {
    "id": 275,
    "type": "1",
    "name": "食品及饲料添加剂",
    "level": "3",
    "code": "220315"
  },
  {
    "id": 276,
    "type": "1",
    "name": "人力资源服务",
    "level": "3",
    "code": "460801"
  },
  {
    "id": 277,
    "type": "1",
    "name": "磷肥及磷化工",
    "level": "3",
    "code": "220802"
  },
  {
    "id": 278,
    "type": "1",
    "name": "国有大型银行",
    "level": "3",
    "code": "480201"
  },
  {
    "id": 279,
    "type": "1",
    "name": "管材",
    "level": "3",
    "code": "610302"
  },
  {
    "id": 280,
    "type": "1",
    "name": "焦炭",
    "level": "3",
    "code": "740201"
  },
  {
    "id": 281,
    "type": "1",
    "name": "肉鸡养殖",
    "level": "3",
    "code": "110703"
  },
  {
    "id": 282,
    "type": "1",
    "name": "铅锌",
    "level": "3",
    "code": "240303"
  },
  {
    "id": 283,
    "type": "1",
    "name": "其他电子",
    "level": "3",
    "code": "270401"
  },
  {
    "id": 284,
    "type": "1",
    "name": "涂料油墨",
    "level": "3",
    "code": "220305"
  },
  {
    "id": 285,
    "type": "1",
    "name": "钾肥",
    "level": "3",
    "code": "220804"
  },
  {
    "id": 286,
    "type": "1",
    "name": "家纺",
    "level": "3",
    "code": "350206"
  },
  {
    "id": 287,
    "type": "1",
    "name": "教育运营及其他",
    "level": "3",
    "code": "461103"
  },
  {
    "id": 288,
    "type": "1",
    "name": "黄金",
    "level": "3",
    "code": "240401"
  },
  {
    "id": 289,
    "type": "1",
    "name": "镍",
    "level": "3",
    "code": "240602"
  },
  {
    "id": 290,
    "type": "1",
    "name": "橡胶助剂",
    "level": "3",
    "code": "220604"
  },
  {
    "id": 291,
    "type": "1",
    "name": "电池化学品",
    "level": "3",
    "code": "630702"
  },
  {
    "id": 292,
    "type": "1",
    "name": "多业态零售",
    "level": "3",
    "code": "450303"
  },
  {
    "id": 293,
    "type": "1",
    "name": "厨房小家电",
    "level": "3",
    "code": "330301"
  },
  {
    "id": 294,
    "type": "1",
    "name": "生猪养殖",
    "level": "3",
    "code": "110702"
  },
  {
    "id": 295,
    "type": "1",
    "name": "资产管理",
    "level": "3",
    "code": "490307"
  },
  {
    "id": 296,
    "type": "1",
    "name": "百货",
    "level": "3",
    "code": "450301"
  },
  {
    "id": 297,
    "type": "1",
    "name": "焦煤",
    "level": "3",
    "code": "740102"
  },
  {
    "id": 298,
    "type": "1",
    "name": "啤酒",
    "level": "3",
    "code": "340601"
  },
  {
    "id": 299,
    "type": "1",
    "name": "水务及水治理",
    "level": "3",
    "code": "760102"
  },
  {
    "id": 300,
    "type": "1",
    "name": "光伏发电",
    "level": "3",
    "code": "410106"
  },
  {
    "id": 301,
    "type": "1",
    "name": "公路货运",
    "level": "3",
    "code": "420807"
  },
  {
    "id": 302,
    "type": "1",
    "name": "玻璃制造",
    "level": "3",
    "code": "610201"
  },
  {
    "id": 303,
    "type": "1",
    "name": "其他数字媒体",
    "level": "3",
    "code": "720706"
  },
  {
    "id": 304,
    "type": "1",
    "name": "培训教育",
    "level": "3",
    "code": "461102"
  },
  {
    "id": 305,
    "type": "1",
    "name": "氟化工",
    "level": "3",
    "code": "220311"
  },
  {
    "id": 306,
    "type": "1",
    "name": "餐饮",
    "level": "3",
    "code": "460902"
  },
  {
    "id": 307,
    "type": "1",
    "name": "光伏辅材",
    "level": "3",
    "code": "630504"
  },
  {
    "id": 308,
    "type": "1",
    "name": "铁矿石",
    "level": "3",
    "code": "230301"
  },
  {
    "id": 309,
    "type": "1",
    "name": "体外诊断",
    "level": "3",
    "code": "370504"
  },
  {
    "id": 310,
    "type": "1",
    "name": "激光设备",
    "level": "3",
    "code": "640703"
  },
  {
    "id": 311,
    "type": "1",
    "name": "综合电商",
    "level": "3",
    "code": "450601"
  },
  {
    "id": 312,
    "type": "1",
    "name": "通信终端及配件",
    "level": "3",
    "code": "730206"
  },
  {
    "id": 313,
    "type": "1",
    "name": "长材",
    "level": "3",
    "code": "230401"
  },
  {
    "id": 314,
    "type": "1",
    "name": "医美耗材",
    "level": "3",
    "code": "770301"
  },
  {
    "id": 315,
    "type": "1",
    "name": "航运",
    "level": "3",
    "code": "421101"
  },
  {
    "id": 316,
    "type": "1",
    "name": "钛白粉",
    "level": "3",
    "code": "220206"
  },
  {
    "id": 317,
    "type": "1",
    "name": "畜禽饲料",
    "level": "3",
    "code": "110402"
  },
  {
    "id": 318,
    "type": "1",
    "name": "纸包装",
    "level": "3",
    "code": "360205"
  },
  {
    "id": 319,
    "type": "1",
    "name": "期货",
    "level": "3",
    "code": "490303"
  },
  {
    "id": 320,
    "type": "1",
    "name": "光伏电池组件",
    "level": "3",
    "code": "630502"
  },
  {
    "id": 321,
    "type": "1",
    "name": "风力发电",
    "level": "3",
    "code": "410107"
  },
  {
    "id": 322,
    "type": "1",
    "name": "板材",
    "level": "3",
    "code": "230402"
  },
  {
    "id": 323,
    "type": "1",
    "name": "其他酒类",
    "level": "3",
    "code": "340602"
  },
  {
    "id": 324,
    "type": "1",
    "name": "其他石化",
    "level": "3",
    "code": "750303"
  },
  {
    "id": 325,
    "type": "1",
    "name": "有机硅",
    "level": "3",
    "code": "220316"
  },
  {
    "id": 326,
    "type": "1",
    "name": "卫浴电器",
    "level": "3",
    "code": "330402"
  },
  {
    "id": 327,
    "type": "1",
    "name": "钢结构",
    "level": "3",
    "code": "620401"
  },
  {
    "id": 328,
    "type": "1",
    "name": "会展服务",
    "level": "3",
    "code": "460803"
  },
  {
    "id": 329,
    "type": "1",
    "name": "纺织服装设备",
    "level": "3",
    "code": "640206"
  },
  {
    "id": 330,
    "type": "1",
    "name": "食用菌",
    "level": "3",
    "code": "110104"
  },
  {
    "id": 331,
    "type": "1",
    "name": "旅游综合",
    "level": "3",
    "code": "461004"
  },
  {
    "id": 332,
    "type": "1",
    "name": "水泥制造",
    "level": "3",
    "code": "610101"
  },
  {
    "id": 333,
    "type": "1",
    "name": "通信应用增值服务",
    "level": "3",
    "code": "730104"
  },
  {
    "id": 334,
    "type": "1",
    "name": "综合环境治理",
    "level": "3",
    "code": "760104"
  },
  {
    "id": 335,
    "type": "1",
    "name": "冰洗",
    "level": "3",
    "code": "330106"
  },
  {
    "id": 336,
    "type": "1",
    "name": "锂",
    "level": "3",
    "code": "240603"
  },
  {
    "id": 337,
    "type": "1",
    "name": "白酒",
    "level": "3",
    "code": "340501"
  },
  {
    "id": 338,
    "type": "1",
    "name": "其他种植业",
    "level": "3",
    "code": "110103"
  },
  {
    "id": 339,
    "type": "1",
    "name": "鞋帽及其他",
    "level": "3",
    "code": "350205"
  },
  {
    "id": 340,
    "type": "1",
    "name": "电工仪器仪表",
    "level": "3",
    "code": "630804"
  },
  {
    "id": 341,
    "type": "1",
    "name": "铝",
    "level": "3",
    "code": "240301"
  },
  {
    "id": 342,
    "type": "1",
    "name": "粮油加工",
    "level": "3",
    "code": "110502"
  },
  {
    "id": 343,
    "type": "1",
    "name": "物业管理",
    "level": "3",
    "code": "430301"
  },
  {
    "id": 344,
    "type": "1",
    "name": "肉制品",
    "level": "3",
    "code": "340401"
  },
  {
    "id": 345,
    "type": "1",
    "name": "其他通用设备",
    "level": "3",
    "code": "640106"
  },
  {
    "id": 346,
    "type": "1",
    "name": "其他塑料制品",
    "level": "3",
    "code": "220501"
  },
  {
    "id": 347,
    "type": "1",
    "name": "其他专业工程",
    "level": "3",
    "code": "620404"
  },
  {
    "id": 348,
    "type": "1",
    "name": "烘焙食品",
    "level": "3",
    "code": "340802"
  },
  {
    "id": 349,
    "type": "1",
    "name": "纺织鞋类制造",
    "level": "3",
    "code": "350107"
  },
  {
    "id": 350,
    "type": "1",
    "name": "其他化学纤维",
    "level": "3",
    "code": "220404"
  },
  {
    "id": 351,
    "type": "1",
    "name": "文化用品",
    "level": "3",
    "code": "360501"
  },
  {
    "id": 352,
    "type": "1",
    "name": "油品石化贸易",
    "level": "3",
    "code": "750302"
  },
  {
    "id": 353,
    "type": "1",
    "name": "门户网站",
    "level": "3",
    "code": "720704"
  },
  {
    "id": 354,
    "type": "1",
    "name": "港口",
    "level": "3",
    "code": "421102"
  },
  {
    "id": 355,
    "type": "1",
    "name": "酒店",
    "level": "3",
    "code": "460901"
  },
  {
    "id": 356,
    "type": "1",
    "name": "图片媒体",
    "level": "3",
    "code": "720703"
  },
  {
    "id": 357,
    "type": "1",
    "name": "清洁小家电",
    "level": "3",
    "code": "330302"
  },
  {
    "id": 358,
    "type": "1",
    "name": "车身附件及饰件",
    "level": "3",
    "code": "280202"
  },
  {
    "id": 359,
    "type": "1",
    "name": "商用载客车",
    "level": "3",
    "code": "280602"
  },
  {
    "id": 360,
    "type": "1",
    "name": "能源及重型设备",
    "level": "3",
    "code": "640203"
  },
  {
    "id": 361,
    "type": "1",
    "name": "水泥制品",
    "level": "3",
    "code": "610102"
  },
  {
    "id": 362,
    "type": "1",
    "name": "风电整机",
    "level": "3",
    "code": "630601"
  },
  {
    "id": 363,
    "type": "1",
    "name": "熟食",
    "level": "3",
    "code": "340803"
  },
  {
    "id": 364,
    "type": "1",
    "name": "大众出版",
    "level": "3",
    "code": "720902"
  },
  {
    "id": 365,
    "type": "1",
    "name": "水产饲料",
    "level": "3",
    "code": "110403"
  },
  {
    "id": 366,
    "type": "1",
    "name": "房屋建设",
    "level": "3",
    "code": "620101"
  },
  {
    "id": 367,
    "type": "1",
    "name": "诊断服务",
    "level": "3",
    "code": "370602"
  },
  {
    "id": 368,
    "type": "1",
    "name": "洗护用品",
    "level": "3",
    "code": "770102"
  },
  {
    "id": 369,
    "type": "1",
    "name": "营销代理",
    "level": "3",
    "code": "720501"
  },
  {
    "id": 370,
    "type": "1",
    "name": "聚氨酯",
    "level": "3",
    "code": "220313"
  },
  {
    "id": 371,
    "type": "1",
    "name": "磁性材料",
    "level": "3",
    "code": "240202"
  },
  {
    "id": 372,
    "type": "1",
    "name": "预加工食品",
    "level": "3",
    "code": "340406"
  },
  {
    "id": 373,
    "type": "1",
    "name": "彩电",
    "level": "3",
    "code": "330201"
  },
  {
    "id": 374,
    "type": "1",
    "name": "产业地产",
    "level": "3",
    "code": "430103"
  },
  {
    "id": 375,
    "type": "1",
    "name": "金属制品",
    "level": "3",
    "code": "640108"
  },
  {
    "id": 376,
    "type": "1",
    "name": "水力发电",
    "level": "3",
    "code": "410102"
  },
  {
    "id": 377,
    "type": "1",
    "name": "院线",
    "level": "3",
    "code": "720602"
  },
  {
    "id": 378,
    "type": "1",
    "name": "底盘与发动机系统",
    "level": "3",
    "code": "280203"
  },
  {
    "id": 379,
    "type": "1",
    "name": "其他能源发电",
    "level": "3",
    "code": "410109"
  },
  {
    "id": 380,
    "type": "1",
    "name": "住宅开发",
    "level": "3",
    "code": "430101"
  },
  {
    "id": 381,
    "type": "1",
    "name": "空调",
    "level": "3",
    "code": "330102"
  },
  {
    "id": 382,
    "type": "1",
    "name": "大气治理",
    "level": "3",
    "code": "760101"
  },
  {
    "id": 383,
    "type": "1",
    "name": "摩托车",
    "level": "3",
    "code": "280402"
  },
  {
    "id": 384,
    "type": "1",
    "name": "成品家居",
    "level": "3",
    "code": "360307"
  },
  {
    "id": 385,
    "type": "1",
    "name": "煤化工",
    "level": "3",
    "code": "220205"
  },
  {
    "id": 386,
    "type": "1",
    "name": "锂电专用设备",
    "level": "3",
    "code": "630703"
  },
  {
    "id": 387,
    "type": "1",
    "name": "影视动漫制作",
    "level": "3",
    "code": "720601"
  },
  {
    "id": 388,
    "type": "1",
    "name": "其他家居用品",
    "level": "3",
    "code": "360311"
  },
  {
    "id": 389,
    "type": "1",
    "name": "农业综合",
    "level": "3",
    "code": "110901"
  },
  {
    "id": 390,
    "type": "1",
    "name": "钟表珠宝",
    "level": "3",
    "code": "350301"
  },
  {
    "id": 391,
    "type": "1",
    "name": "其他计算机设备",
    "level": "3",
    "code": "710103"
  },
  {
    "id": 392,
    "type": "1",
    "name": "机场",
    "level": "3",
    "code": "421002"
  },
  {
    "id": 393,
    "type": "1",
    "name": "粮食种植",
    "level": "3",
    "code": "110102"
  },
  {
    "id": 394,
    "type": "1",
    "name": "膜材料",
    "level": "3",
    "code": "220505"
  },
  {
    "id": 395,
    "type": "1",
    "name": "商用载货车",
    "level": "3",
    "code": "280601"
  },
  {
    "id": 396,
    "type": "1",
    "name": "综合乘用车",
    "level": "3",
    "code": "280502"
  },
  {
    "id": 397,
    "type": "1",
    "name": "逆变器",
    "level": "3",
    "code": "630503"
  },
  {
    "id": 398,
    "type": "1",
    "name": "医美服务",
    "level": "3",
    "code": "770302"
  },
  {
    "id": 399,
    "type": "1",
    "name": "园林工程",
    "level": "3",
    "code": "620307"
  },
  {
    "id": 400,
    "type": "1",
    "name": "IT服务",
    "level": "3",
    "code": "710301"
  },
  {
    "id": 401,
    "type": "1",
    "name": "乳品",
    "level": "3",
    "code": "340702"
  },
  {
    "id": 402,
    "type": "1",
    "name": "民爆制品",
    "level": "3",
    "code": "220307"
  },
  {
    "id": 403,
    "type": "1",
    "name": "光学元件",
    "level": "3",
    "code": "270303"
  },
  {
    "id": 404,
    "type": "1",
    "name": "其他黑色家电",
    "level": "3",
    "code": "330202"
  },
  {
    "id": 405,
    "type": "1",
    "name": "电机",
    "level": "3",
    "code": "630101"
  },
  {
    "id": 406,
    "type": "1",
    "name": "热力服务",
    "level": "3",
    "code": "410104"
  },
  {
    "id": 407,
    "type": "1",
    "name": "快递",
    "level": "3",
    "code": "420804"
  },
  {
    "id": 408,
    "type": "1",
    "name": "家电零部件",
    "level": "3",
    "code": "330601"
  },
  {
    "id": 409,
    "type": "1",
    "name": "大宗用纸",
    "level": "3",
    "code": "360102"
  },
  {
    "id": 410,
    "type": "1",
    "name": "跨境电商",
    "level": "3",
    "code": "450602"
  },
  {
    "id": 411,
    "type": "1",
    "name": "其他电源设备",
    "level": "3",
    "code": "630306"
  },
  {
    "id": 412,
    "type": "1",
    "name": "海洋捕捞",
    "level": "3",
    "code": "110201"
  },
  {
    "id": 413,
    "type": "1",
    "name": "贸易",
    "level": "3",
    "code": "450201"
  },
  {
    "id": 414,
    "type": "1",
    "name": "卫浴制品",
    "level": "3",
    "code": "360309"
  },
  {
    "id": 415,
    "type": "1",
    "name": "房产租赁经纪",
    "level": "3",
    "code": "430302"
  },
  {
    "id": 416,
    "type": "1",
    "name": "地面兵装",
    "level": "3",
    "code": "650301"
  },
  {
    "id": 417,
    "type": "1",
    "name": "被动元件",
    "level": "3",
    "code": "270203"
  },
  {
    "id": 418,
    "type": "1",
    "name": "铁路运输",
    "level": "3",
    "code": "420903"
  },
  {
    "id": 419,
    "type": "1",
    "name": "证券",
    "level": "3",
    "code": "490101"
  },
  {
    "id": 420,
    "type": "1",
    "name": "半导体材料",
    "level": "3",
    "code": "270103"
  },
  {
    "id": 421,
    "type": "1",
    "name": "特种纸",
    "level": "3",
    "code": "360103"
  },
  {
    "id": 422,
    "type": "1",
    "name": "广告媒体",
    "level": "3",
    "code": "720502"
  },
  {
    "id": 423,
    "type": "1",
    "name": "胶黏剂及胶带",
    "level": "3",
    "code": "220317"
  },
  {
    "id": 424,
    "type": "1",
    "name": "白银",
    "level": "3",
    "code": "240402"
  },
  {
    "id": 425,
    "type": "1",
    "name": "核力发电",
    "level": "3",
    "code": "410108"
  },
  {
    "id": 426,
    "type": "1",
    "name": "租赁",
    "level": "3",
    "code": "490305"
  },
  {
    "id": 427,
    "type": "1",
    "name": "金属包装",
    "level": "3",
    "code": "360203"
  },
  {
    "id": 428,
    "type": "1",
    "name": "检测服务",
    "level": "3",
    "code": "460802"
  },
  {
    "id": 429,
    "type": "1",
    "name": "保险",
    "level": "3",
    "code": "490201"
  },
  {
    "id": 430,
    "type": "1",
    "name": "动物保健",
    "level": "3",
    "code": "110801"
  },
  {
    "id": 431,
    "type": "1",
    "name": "合成树脂",
    "level": "3",
    "code": "220504"
  },
  {
    "id": 432,
    "type": "1",
    "name": "燃料电池",
    "level": "3",
    "code": "630704"
  },
  {
    "id": 433,
    "type": "1",
    "name": "股份制银行",
    "level": "3",
    "code": "480301"
  },
  {
    "id": 434,
    "type": "1",
    "name": "横向通用软件",
    "level": "3",
    "code": "710402"
  },
  {
    "id": 435,
    "type": "1",
    "name": "个护小家电",
    "level": "3",
    "code": "330303"
  },
  {
    "id": 436,
    "type": "1",
    "name": "机器人",
    "level": "3",
    "code": "640701"
  },
  {
    "id": 437,
    "type": "1",
    "name": "油田服务",
    "level": "3",
    "code": "750201"
  },
  {
    "id": 438,
    "type": "1",
    "name": "自然景区",
    "level": "3",
    "code": "461003"
  },
  {
    "id": 439,
    "type": "1",
    "name": "楼宇设备",
    "level": "3",
    "code": "640204"
  },
  {
    "id": 440,
    "type": "1",
    "name": "化妆品制造及其他",
    "level": "3",
    "code": "770201"
  },
  {
    "id": 441,
    "type": "1",
    "name": "教育出版",
    "level": "3",
    "code": "720901"
  },
  {
    "id": 442,
    "type": "1",
    "name": "电动乘用车",
    "level": "3",
    "code": "280501"
  },
  {
    "id": 443,
    "type": "1",
    "name": "涤纶",
    "level": "3",
    "code": "220401"
  },
  {
    "id": 444,
    "type": "1",
    "name": "改性塑料",
    "level": "3",
    "code": "220503"
  },
  {
    "id": 445,
    "type": "1",
    "name": "跨境物流",
    "level": "3",
    "code": "420805"
  },
  {
    "id": 446,
    "type": "1",
    "name": "生活用纸",
    "level": "3",
    "code": "770101"
  },
  {
    "id": 447,
    "type": "1",
    "name": "其他纺织",
    "level": "3",
    "code": "350106"
  },
  {
    "id": 448,
    "type": "1",
    "name": "防水材料",
    "level": "3",
    "code": "610304"
  },
  {
    "id": 449,
    "type": "1",
    "name": "汽车经销商",
    "level": "3",
    "code": "280302"
  },
  {
    "id": 450,
    "type": "1",
    "name": "粘胶",
    "level": "3",
    "code": "220403"
  },
  {
    "id": 451,
    "type": "1",
    "name": "医疗研发外包",
    "level": "3",
    "code": "370603"
  },
  {
    "id": 452,
    "type": "1",
    "name": "工程机械器件",
    "level": "3",
    "code": "640602"
  },
  {
    "id": 453,
    "type": "1",
    "name": "公交",
    "level": "3",
    "code": "420902"
  },
  {
    "id": 454,
    "type": "1",
    "name": "果蔬加工",
    "level": "3",
    "code": "110501"
  },
  {
    "id": 455,
    "type": "1",
    "name": "钢铁管材",
    "level": "3",
    "code": "230403"
  },
  {
    "id": 456,
    "type": "1",
    "name": "医药流通",
    "level": "3",
    "code": "370402"
  },
  {
    "id": 457,
    "type": "1",
    "name": "高速公路",
    "level": "3",
    "code": "420901"
  },
  {
    "id": 458,
    "type": "1",
    "name": "综合包装",
    "level": "3",
    "code": "360206"
  },
  {
    "id": 459,
    "type": "1",
    "name": "涂料",
    "level": "3",
    "code": "610305"
  },
  {
    "id": 460,
    "type": "1",
    "name": "集成电路制造",
    "level": "3",
    "code": "270106"
  },
  {
    "id": 461,
    "type": "1",
    "name": "金融信息服务",
    "level": "3",
    "code": "490306"
  },
  {
    "id": 462,
    "type": "1",
    "name": "人工景区",
    "level": "3",
    "code": "461002"
  },
  {
    "id": 463,
    "type": "1",
    "name": "印刷",
    "level": "3",
    "code": "360202"
  },
  {
    "id": 464,
    "type": "1",
    "name": "输变电设备",
    "level": "3",
    "code": "630801"
  },
  {
    "id": 465,
    "type": "1",
    "name": "火电设备",
    "level": "3",
    "code": "630304"
  },
  {
    "id": 466,
    "type": "1",
    "name": "印染",
    "level": "3",
    "code": "350104"
  },
  {
    "id": 467,
    "type": "1",
    "name": "装修装饰",
    "level": "3",
    "code": "620201"
  },
  {
    "id": 468,
    "type": "1",
    "name": "化学制剂",
    "level": "3",
    "code": "370102"
  },
  {
    "id": 469,
    "type": "1",
    "name": "疫苗",
    "level": "3",
    "code": "370303"
  },
  {
    "id": 470,
    "type": "1",
    "name": "医疗设备",
    "level": "3",
    "code": "370502"
  },
  {
    "id": 471,
    "type": "1",
    "name": "火力发电",
    "level": "3",
    "code": "410101"
  },
  {
    "id": 472,
    "type": "1",
    "name": "非金属材料",
    "level": "3",
    "code": "220901"
  },
  {
    "id": 473,
    "type": "1",
    "name": "国际工程",
    "level": "3",
    "code": "620403"
  },
  {
    "id": 474,
    "type": "1",
    "name": "照明设备",
    "level": "3",
    "code": "330501"
  },
  {
    "id": 475,
    "type": "1",
    "name": "航天装备",
    "level": "3",
    "code": "650101"
  },
  {
    "id": 476,
    "type": "1",
    "name": "磨具磨料",
    "level": "3",
    "code": "640103"
  },
  {
    "id": 477,
    "type": "1",
    "name": "动力煤",
    "level": "3",
    "code": "740101"
  },
  {
    "id": 478,
    "type": "1",
    "name": "品牌化妆品",
    "level": "3",
    "code": "770202"
  },
  {
    "id": 479,
    "type": "1",
    "name": "其他医疗服务",
    "level": "3",
    "code": "370605"
  },
  {
    "id": 480,
    "type": "1",
    "name": "航海装备",
    "level": "3",
    "code": "650401"
  },
  {
    "id": 481,
    "type": "1",
    "name": "环保设备",
    "level": "3",
    "code": "760201"
  },
  {
    "id": 482,
    "type": "1",
    "name": "其他金属新材料",
    "level": "3",
    "code": "240201"
  },
  {
    "id": 483,
    "type": "1",
    "name": "中药",
    "level": "3",
    "code": "370201"
  },
  {
    "id": 484,
    "type": "1",
    "name": "钼",
    "level": "3",
    "code": "240505"
  },
  {
    "id": 485,
    "type": "1",
    "name": "钴",
    "level": "3",
    "code": "240601"
  },
  {
    "id": 486,
    "type": "1",
    "name": "轮胎轮毂",
    "level": "3",
    "code": "280204"
  },
  {
    "id": 487,
    "type": "1",
    "name": "农药",
    "level": "3",
    "code": "220803"
  },
  {
    "id": 488,
    "type": "1",
    "name": "软饮料",
    "level": "3",
    "code": "340701"
  },
  {
    "id": 489,
    "type": "1",
    "name": "其他小金属",
    "level": "3",
    "code": "240504"
  },
  {
    "id": 490,
    "type": "1",
    "name": "学历教育",
    "level": "3",
    "code": "461101"
  },
  {
    "id": 491,
    "type": "1",
    "name": "综合",
    "level": "3",
    "code": "510101"
  },
  {
    "id": 492,
    "type": "1",
    "name": "瓷砖地板",
    "level": "3",
    "code": "360306"
  },
  {
    "id": 493,
    "type": "1",
    "name": "电能综合服务",
    "level": "3",
    "code": "410110"
  },
  {
    "id": 494,
    "type": "1",
    "name": "耐火材料",
    "level": "3",
    "code": "610301"
  },
  {
    "id": 495,
    "type": "1",
    "name": "光伏加工设备",
    "level": "3",
    "code": "630505"
  },
  {
    "id": 496,
    "type": "1",
    "name": "视频媒体",
    "level": "3",
    "code": "720701"
  },
  {
    "id": 497,
    "type": "1",
    "name": "炼油化工",
    "level": "3",
    "code": "750301"
  },
  {
    "id": 498,
    "type": "1",
    "name": "LED",
    "level": "3",
    "code": "270302"
  },
  {
    "id": 499,
    "type": "1",
    "name": "厨房电器",
    "level": "3",
    "code": "330401"
  },
  {
    "id": 503,
    "type": "2",
    "name": "汽车拆解",
    "level": "",
    "code": null
  },
  {
    "id": 505,
    "type": "2",
    "name": "净水概念",
    "level": "",
    "code": null
  },
  {
    "id": 506,
    "type": "2",
    "name": "世界杯",
    "level": "",
    "code": null
  },
  {
    "id": 507,
    "type": "2",
    "name": "空气能热泵",
    "level": "",
    "code": null
  },
  {
    "id": 508,
    "type": "2",
    "name": "商汤概念",
    "level": "",
    "code": null
  },
  {
    "id": 509,
    "type": "2",
    "name": "电子纸概念",
    "level": "",
    "code": null
  },
  {
    "id": 510,
    "type": "2",
    "name": "发电机概念",
    "level": "",
    "code": null
  },
  {
    "id": 511,
    "type": "2",
    "name": "动力电池回收",
    "level": "",
    "code": null
  },
  {
    "id": 512,
    "type": "2",
    "name": "麒麟电池",
    "level": "",
    "code": null
  },
  {
    "id": 513,
    "type": "2",
    "name": "时空大数据",
    "level": "",
    "code": null
  },
  {
    "id": 514,
    "type": "2",
    "name": "汽车一体化压铸",
    "level": "",
    "code": null
  },
  {
    "id": 515,
    "type": "2",
    "name": "碳基材料",
    "level": "",
    "code": null
  },
  {
    "id": 516,
    "type": "2",
    "name": "地热能",
    "level": "",
    "code": null
  },
  {
    "id": 517,
    "type": "2",
    "name": "氦气概念",
    "level": "",
    "code": null
  },
  {
    "id": 518,
    "type": "2",
    "name": "快手概念",
    "level": "",
    "code": null
  },
  {
    "id": 519,
    "type": "2",
    "name": "沪企改革",
    "level": "",
    "code": null
  },
  {
    "id": 520,
    "type": "2",
    "name": "垃圾分类",
    "level": "",
    "code": null
  },
  {
    "id": 521,
    "type": "2",
    "name": "万达概念",
    "level": "",
    "code": null
  },
  {
    "id": 522,
    "type": "2",
    "name": "海工装备",
    "level": "",
    "code": null
  },
  {
    "id": 523,
    "type": "2",
    "name": "医废处理",
    "level": "",
    "code": null
  },
  {
    "id": 524,
    "type": "2",
    "name": "供销社概念",
    "level": "",
    "code": null
  },
  {
    "id": 525,
    "type": "2",
    "name": "进口博览",
    "level": "",
    "code": null
  },
  {
    "id": 526,
    "type": "2",
    "name": "北斗导航",
    "level": "",
    "code": null
  },
  {
    "id": 527,
    "type": "2",
    "name": "共享经济",
    "level": "",
    "code": null
  },
  {
    "id": 528,
    "type": "2",
    "name": "BC电池",
    "level": "",
    "code": null
  },
  {
    "id": 529,
    "type": "2",
    "name": "磁悬浮概念",
    "level": "",
    "code": null
  },
  {
    "id": 530,
    "type": "2",
    "name": "食品安全",
    "level": "",
    "code": null
  },
  {
    "id": 531,
    "type": "2",
    "name": "工业4.0",
    "level": "",
    "code": null
  },
  {
    "id": 532,
    "type": "2",
    "name": "滨海新区",
    "level": "",
    "code": null
  },
  {
    "id": 533,
    "type": "2",
    "name": "EDA概念",
    "level": "",
    "code": null
  },
  {
    "id": 534,
    "type": "2",
    "name": "智能家居",
    "level": "",
    "code": null
  },
  {
    "id": 535,
    "type": "2",
    "name": "京津冀",
    "level": "",
    "code": null
  },
  {
    "id": 536,
    "type": "2",
    "name": "上海自贸",
    "level": "",
    "code": null
  },
  {
    "id": 537,
    "type": "2",
    "name": "东数西算",
    "level": "",
    "code": null
  },
  {
    "id": 538,
    "type": "2",
    "name": "虚拟电厂",
    "level": "",
    "code": null
  },
  {
    "id": 539,
    "type": "2",
    "name": "无人驾驶",
    "level": "",
    "code": null
  },
  {
    "id": 540,
    "type": "2",
    "name": "PEEK材料概念",
    "level": "",
    "code": null
  },
  {
    "id": 541,
    "type": "2",
    "name": "EDR概念",
    "level": "",
    "code": null
  },
  {
    "id": 542,
    "type": "2",
    "name": "量子通信",
    "level": "",
    "code": null
  },
  {
    "id": 543,
    "type": "2",
    "name": "AI制药",
    "level": "",
    "code": null
  },
  {
    "id": 544,
    "type": "2",
    "name": "国家安防",
    "level": "",
    "code": null
  },
  {
    "id": 545,
    "type": "2",
    "name": "碳化硅",
    "level": "",
    "code": null
  },
  {
    "id": 546,
    "type": "2",
    "name": "稀土永磁",
    "level": "",
    "code": null
  },
  {
    "id": 547,
    "type": "2",
    "name": "有机硅",
    "level": "",
    "code": null
  },
  {
    "id": 548,
    "type": "2",
    "name": "疫苗冷链",
    "level": "",
    "code": null
  },
  {
    "id": 549,
    "type": "2",
    "name": "机器视觉",
    "level": "",
    "code": null
  },
  {
    "id": 550,
    "type": "2",
    "name": "地摊经济",
    "level": "",
    "code": null
  },
  {
    "id": 551,
    "type": "2",
    "name": "中俄贸易概念",
    "level": "",
    "code": null
  },
  {
    "id": 552,
    "type": "2",
    "name": "MLCC",
    "level": "",
    "code": null
  },
  {
    "id": 553,
    "type": "2",
    "name": "远程办公",
    "level": "",
    "code": null
  },
  {
    "id": 554,
    "type": "2",
    "name": "肝素概念",
    "level": "",
    "code": null
  },
  {
    "id": 555,
    "type": "2",
    "name": "水产养殖",
    "level": "",
    "code": null
  },
  {
    "id": 556,
    "type": "2",
    "name": "尾气治理",
    "level": "",
    "code": null
  },
  {
    "id": 557,
    "type": "2",
    "name": "通用航空",
    "level": "",
    "code": null
  },
  {
    "id": 558,
    "type": "2",
    "name": "AB股",
    "level": "",
    "code": null
  },
  {
    "id": 559,
    "type": "2",
    "name": "气溶胶检测",
    "level": "",
    "code": null
  },
  {
    "id": 560,
    "type": "2",
    "name": "北京冬奥",
    "level": "",
    "code": null
  },
  {
    "id": 561,
    "type": "2",
    "name": "飞行汽车(eVTOL)",
    "level": "",
    "code": null
  },
  {
    "id": 562,
    "type": "2",
    "name": "氢能源",
    "level": "",
    "code": null
  },
  {
    "id": 563,
    "type": "2",
    "name": "天基互联",
    "level": "",
    "code": null
  },
  {
    "id": 564,
    "type": "2",
    "name": "水利建设",
    "level": "",
    "code": null
  },
  {
    "id": 565,
    "type": "2",
    "name": "口罩",
    "level": "",
    "code": null
  },
  {
    "id": 566,
    "type": "2",
    "name": "低碳冶金",
    "level": "",
    "code": null
  },
  {
    "id": 567,
    "type": "2",
    "name": "超级品牌",
    "level": "",
    "code": null
  },
  {
    "id": 568,
    "type": "2",
    "name": "债转股",
    "level": "",
    "code": null
  },
  {
    "id": 569,
    "type": "2",
    "name": "冷链物流",
    "level": "",
    "code": null
  },
  {
    "id": 570,
    "type": "2",
    "name": "工业母机",
    "level": "",
    "code": null
  },
  {
    "id": 571,
    "type": "2",
    "name": "消毒剂",
    "level": "",
    "code": null
  },
  {
    "id": 572,
    "type": "2",
    "name": "高带宽内存",
    "level": "",
    "code": null
  },
  {
    "id": 573,
    "type": "2",
    "name": "机器人概念",
    "level": "",
    "code": null
  },
  {
    "id": 574,
    "type": "2",
    "name": "IPO受益",
    "level": "",
    "code": null
  },
  {
    "id": 575,
    "type": "2",
    "name": "胎压监测",
    "level": "",
    "code": null
  },
  {
    "id": 576,
    "type": "2",
    "name": "退税商店",
    "level": "",
    "code": null
  },
  {
    "id": 577,
    "type": "2",
    "name": "注册制次新股",
    "level": "",
    "code": null
  },
  {
    "id": 578,
    "type": "2",
    "name": "无人机",
    "level": "",
    "code": null
  },
  {
    "id": 579,
    "type": "2",
    "name": "长江三角",
    "level": "",
    "code": null
  },
  {
    "id": 580,
    "type": "2",
    "name": "新能源车",
    "level": "",
    "code": null
  },
  {
    "id": 581,
    "type": "2",
    "name": "PLC概念",
    "level": "",
    "code": null
  },
  {
    "id": 582,
    "type": "2",
    "name": "蒙脱石散",
    "level": "",
    "code": null
  },
  {
    "id": 583,
    "type": "2",
    "name": "纳米银",
    "level": "",
    "code": null
  },
  {
    "id": 584,
    "type": "2",
    "name": "工业气体",
    "level": "",
    "code": null
  },
  {
    "id": 585,
    "type": "2",
    "name": "3D打印",
    "level": "",
    "code": null
  },
  {
    "id": 586,
    "type": "2",
    "name": "次新股",
    "level": "",
    "code": null
  },
  {
    "id": 587,
    "type": "2",
    "name": "轮毂电机",
    "level": "",
    "code": null
  },
  {
    "id": 588,
    "type": "2",
    "name": "超导概念",
    "level": "",
    "code": null
  },
  {
    "id": 589,
    "type": "2",
    "name": "券商概念",
    "level": "",
    "code": null
  },
  {
    "id": 590,
    "type": "2",
    "name": "智能电视",
    "level": "",
    "code": null
  },
  {
    "id": 591,
    "type": "2",
    "name": "数据要素",
    "level": "",
    "code": null
  },
  {
    "id": 592,
    "type": "2",
    "name": "华为昇腾",
    "level": "",
    "code": null
  },
  {
    "id": 593,
    "type": "2",
    "name": "英伟达概念",
    "level": "",
    "code": null
  },
  {
    "id": 594,
    "type": "2",
    "name": "ETC",
    "level": "",
    "code": null
  },
  {
    "id": 595,
    "type": "2",
    "name": "AH股",
    "level": "",
    "code": null
  },
  {
    "id": 596,
    "type": "2",
    "name": "节能环保",
    "level": "",
    "code": null
  },
  {
    "id": 597,
    "type": "2",
    "name": "WiFi",
    "level": "",
    "code": null
  },
  {
    "id": 598,
    "type": "2",
    "name": "长寿药",
    "level": "",
    "code": null
  },
  {
    "id": 599,
    "type": "2",
    "name": "养老金",
    "level": "",
    "code": null
  },
  {
    "id": 600,
    "type": "2",
    "name": "多模态AI",
    "level": "",
    "code": null
  },
  {
    "id": 601,
    "type": "2",
    "name": "GDR",
    "level": "",
    "code": null
  },
  {
    "id": 602,
    "type": "2",
    "name": "核能核电",
    "level": "",
    "code": null
  },
  {
    "id": 603,
    "type": "2",
    "name": "数字经济",
    "level": "",
    "code": null
  },
  {
    "id": 604,
    "type": "2",
    "name": "B股",
    "level": "",
    "code": null
  },
  {
    "id": 605,
    "type": "2",
    "name": "茅指数",
    "level": "",
    "code": null
  },
  {
    "id": 606,
    "type": "2",
    "name": "燃料电池",
    "level": "",
    "code": null
  },
  {
    "id": 607,
    "type": "2",
    "name": "贬值受益",
    "level": "",
    "code": null
  },
  {
    "id": 608,
    "type": "2",
    "name": "深证100R",
    "level": "",
    "code": null
  },
  {
    "id": 609,
    "type": "2",
    "name": "机器人执行器",
    "level": "",
    "code": null
  },
  {
    "id": 610,
    "type": "2",
    "name": "智能机器",
    "level": "",
    "code": null
  },
  {
    "id": 611,
    "type": "2",
    "name": "汽车热管理",
    "level": "",
    "code": null
  },
  {
    "id": 612,
    "type": "2",
    "name": "百度概念",
    "level": "",
    "code": null
  },
  {
    "id": 613,
    "type": "2",
    "name": "青蒿素",
    "level": "",
    "code": null
  },
  {
    "id": 614,
    "type": "2",
    "name": "航母概念",
    "level": "",
    "code": null
  },
  {
    "id": 615,
    "type": "2",
    "name": "东北振兴",
    "level": "",
    "code": null
  },
  {
    "id": 616,
    "type": "2",
    "name": "中字头",
    "level": "",
    "code": null
  },
  {
    "id": 617,
    "type": "2",
    "name": "体育产业",
    "level": "",
    "code": null
  },
  {
    "id": 618,
    "type": "2",
    "name": "转债标的",
    "level": "",
    "code": null
  },
  {
    "id": 619,
    "type": "2",
    "name": "混合现实",
    "level": "",
    "code": null
  },
  {
    "id": 620,
    "type": "2",
    "name": "壳资源",
    "level": "",
    "code": null
  },
  {
    "id": 621,
    "type": "2",
    "name": "AI芯片",
    "level": "",
    "code": null
  },
  {
    "id": 622,
    "type": "2",
    "name": "特高压",
    "level": "",
    "code": null
  },
  {
    "id": 623,
    "type": "2",
    "name": "千金藤素",
    "level": "",
    "code": null
  },
  {
    "id": 624,
    "type": "2",
    "name": "军工",
    "level": "",
    "code": null
  },
  {
    "id": 625,
    "type": "2",
    "name": "军民融合",
    "level": "",
    "code": null
  },
  {
    "id": 626,
    "type": "2",
    "name": "独角兽",
    "level": "",
    "code": null
  },
  {
    "id": 627,
    "type": "2",
    "name": "钠离子电池",
    "level": "",
    "code": null
  },
  {
    "id": 628,
    "type": "2",
    "name": "电子竞技",
    "level": "",
    "code": null
  },
  {
    "id": 629,
    "type": "2",
    "name": "新材料",
    "level": "",
    "code": null
  },
  {
    "id": 630,
    "type": "2",
    "name": "熔盐储能",
    "level": "",
    "code": null
  },
  {
    "id": 631,
    "type": "2",
    "name": "抗菌面料",
    "level": "",
    "code": null
  },
  {
    "id": 632,
    "type": "2",
    "name": "参股银行",
    "level": "",
    "code": null
  },
  {
    "id": 633,
    "type": "2",
    "name": "智慧城市",
    "level": "",
    "code": null
  },
  {
    "id": 634,
    "type": "2",
    "name": "参股保险",
    "level": "",
    "code": null
  },
  {
    "id": 635,
    "type": "2",
    "name": "股权激励",
    "level": "",
    "code": null
  },
  {
    "id": 636,
    "type": "2",
    "name": "粤港自贸",
    "level": "",
    "code": null
  },
  {
    "id": 637,
    "type": "2",
    "name": "基因测序",
    "level": "",
    "code": null
  },
  {
    "id": 638,
    "type": "2",
    "name": "PCB",
    "level": "",
    "code": null
  },
  {
    "id": 639,
    "type": "2",
    "name": "生物质能发电",
    "level": "",
    "code": null
  },
  {
    "id": 640,
    "type": "2",
    "name": "元宇宙概念",
    "level": "",
    "code": null
  },
  {
    "id": 641,
    "type": "2",
    "name": "电商概念",
    "level": "",
    "code": null
  },
  {
    "id": 642,
    "type": "2",
    "name": "RCEP概念",
    "level": "",
    "code": null
  },
  {
    "id": 643,
    "type": "2",
    "name": "超清视频",
    "level": "",
    "code": null
  },
  {
    "id": 644,
    "type": "2",
    "name": "医疗器械概念",
    "level": "",
    "code": null
  },
  {
    "id": 645,
    "type": "2",
    "name": "锂电池",
    "level": "",
    "code": null
  },
  {
    "id": 646,
    "type": "2",
    "name": "血氧仪",
    "level": "",
    "code": null
  },
  {
    "id": 647,
    "type": "2",
    "name": "固态电池",
    "level": "",
    "code": null
  },
  {
    "id": 648,
    "type": "2",
    "name": "东盟自贸区概念",
    "level": "",
    "code": null
  },
  {
    "id": 649,
    "type": "2",
    "name": "参股期货",
    "level": "",
    "code": null
  },
  {
    "id": 650,
    "type": "2",
    "name": "光刻机(胶)",
    "level": "",
    "code": null
  },
  {
    "id": 651,
    "type": "2",
    "name": "创投",
    "level": "",
    "code": null
  },
  {
    "id": 652,
    "type": "2",
    "name": "可控核聚变",
    "level": "",
    "code": null
  },
  {
    "id": 653,
    "type": "2",
    "name": "IPv6",
    "level": "",
    "code": null
  },
  {
    "id": 654,
    "type": "2",
    "name": "数据中心",
    "level": "",
    "code": null
  },
  {
    "id": 655,
    "type": "2",
    "name": "风能",
    "level": "",
    "code": null
  },
  {
    "id": 656,
    "type": "2",
    "name": "小米汽车",
    "level": "",
    "code": null
  },
  {
    "id": 657,
    "type": "2",
    "name": "国企改革",
    "level": "",
    "code": null
  },
  {
    "id": 658,
    "type": "2",
    "name": "刀片电池",
    "level": "",
    "code": null
  },
  {
    "id": 659,
    "type": "2",
    "name": "工业互联",
    "level": "",
    "code": null
  },
  {
    "id": 660,
    "type": "2",
    "name": "熊去氧胆酸",
    "level": "",
    "code": null
  },
  {
    "id": 661,
    "type": "2",
    "name": "手游概念",
    "level": "",
    "code": null
  },
  {
    "id": 662,
    "type": "2",
    "name": "央企改革",
    "level": "",
    "code": null
  },
  {
    "id": 663,
    "type": "2",
    "name": "边缘计算",
    "level": "",
    "code": null
  },
  {
    "id": 664,
    "type": "2",
    "name": "证金持股",
    "level": "",
    "code": null
  },
  {
    "id": 665,
    "type": "2",
    "name": "参股券商",
    "level": "",
    "code": null
  },
  {
    "id": 666,
    "type": "2",
    "name": "赛马概念",
    "level": "",
    "code": null
  },
  {
    "id": 667,
    "type": "2",
    "name": "宠物经济",
    "level": "",
    "code": null
  },
  {
    "id": 668,
    "type": "2",
    "name": "HS300_",
    "level": "",
    "code": null
  },
  {
    "id": 669,
    "type": "2",
    "name": "深股通",
    "level": "",
    "code": null
  },
  {
    "id": 670,
    "type": "2",
    "name": "分拆预期",
    "level": "",
    "code": null
  },
  {
    "id": 671,
    "type": "2",
    "name": "传感器",
    "level": "",
    "code": null
  },
  {
    "id": 672,
    "type": "2",
    "name": "车联网(车路云)",
    "level": "",
    "code": null
  },
  {
    "id": 673,
    "type": "2",
    "name": "低空经济",
    "level": "",
    "code": null
  },
  {
    "id": 674,
    "type": "2",
    "name": "储能",
    "level": "",
    "code": null
  },
  {
    "id": 675,
    "type": "2",
    "name": "培育钻石",
    "level": "",
    "code": null
  },
  {
    "id": 676,
    "type": "2",
    "name": "抽水蓄能",
    "level": "",
    "code": null
  },
  {
    "id": 677,
    "type": "2",
    "name": "啤酒概念",
    "level": "",
    "code": null
  },
  {
    "id": 678,
    "type": "2",
    "name": "专精特新",
    "level": "",
    "code": null
  },
  {
    "id": 679,
    "type": "2",
    "name": "第三代半导体",
    "level": "",
    "code": null
  },
  {
    "id": 680,
    "type": "2",
    "name": "海洋经济",
    "level": "",
    "code": null
  },
  {
    "id": 681,
    "type": "2",
    "name": "宁组合",
    "level": "",
    "code": null
  },
  {
    "id": 682,
    "type": "2",
    "name": "成渝特区",
    "level": "",
    "code": null
  },
  {
    "id": 683,
    "type": "2",
    "name": "充电桩",
    "level": "",
    "code": null
  },
  {
    "id": 684,
    "type": "2",
    "name": "云游戏",
    "level": "",
    "code": null
  },
  {
    "id": 685,
    "type": "2",
    "name": "标准普尔",
    "level": "",
    "code": null
  },
  {
    "id": 686,
    "type": "2",
    "name": "央视50_",
    "level": "",
    "code": null
  },
  {
    "id": 687,
    "type": "2",
    "name": "降解塑料",
    "level": "",
    "code": null
  },
  {
    "id": 688,
    "type": "2",
    "name": "土壤修复",
    "level": "",
    "code": null
  },
  {
    "id": 689,
    "type": "2",
    "name": "核污染防治",
    "level": "",
    "code": null
  },
  {
    "id": 690,
    "type": "2",
    "name": "MSCI中国",
    "level": "",
    "code": null
  },
  {
    "id": 691,
    "type": "2",
    "name": "数字孪生",
    "level": "",
    "code": null
  },
  {
    "id": 692,
    "type": "2",
    "name": "破净股",
    "level": "",
    "code": null
  },
  {
    "id": 693,
    "type": "2",
    "name": "草甘膦",
    "level": "",
    "code": null
  },
  {
    "id": 694,
    "type": "2",
    "name": "绿色电力",
    "level": "",
    "code": null
  },
  {
    "id": 695,
    "type": "2",
    "name": "网红直播",
    "level": "",
    "code": null
  },
  {
    "id": 696,
    "type": "2",
    "name": "一带一路",
    "level": "",
    "code": null
  },
  {
    "id": 697,
    "type": "2",
    "name": "北交所概念",
    "level": "",
    "code": null
  },
  {
    "id": 698,
    "type": "2",
    "name": "免疫治疗",
    "level": "",
    "code": null
  },
  {
    "id": 699,
    "type": "2",
    "name": "太阳能",
    "level": "",
    "code": null
  },
  {
    "id": 700,
    "type": "2",
    "name": "小米概念",
    "level": "",
    "code": null
  },
  {
    "id": 701,
    "type": "2",
    "name": "超超临界发电",
    "level": "",
    "code": null
  },
  {
    "id": 702,
    "type": "2",
    "name": "深成500",
    "level": "",
    "code": null
  },
  {
    "id": 703,
    "type": "2",
    "name": "智能电网",
    "level": "",
    "code": null
  },
  {
    "id": 704,
    "type": "2",
    "name": "上证50_",
    "level": "",
    "code": null
  },
  {
    "id": 705,
    "type": "2",
    "name": "融资融券",
    "level": "",
    "code": null
  },
  {
    "id": 706,
    "type": "2",
    "name": "2025规划",
    "level": "",
    "code": null
  },
  {
    "id": 707,
    "type": "2",
    "name": "杭州亚运会",
    "level": "",
    "code": null
  },
  {
    "id": 708,
    "type": "2",
    "name": "电子烟",
    "level": "",
    "code": null
  },
  {
    "id": 709,
    "type": "2",
    "name": "乡村振兴",
    "level": "",
    "code": null
  },
  {
    "id": 710,
    "type": "2",
    "name": "预亏预减",
    "level": "",
    "code": null
  },
  {
    "id": 711,
    "type": "2",
    "name": "转基因",
    "level": "",
    "code": null
  },
  {
    "id": 712,
    "type": "2",
    "name": "碳纤维",
    "level": "",
    "code": null
  },
  {
    "id": 713,
    "type": "2",
    "name": "NFT概念",
    "level": "",
    "code": null
  },
  {
    "id": 714,
    "type": "2",
    "name": "维生素",
    "level": "",
    "code": null
  },
  {
    "id": 715,
    "type": "2",
    "name": "体外诊断",
    "level": "",
    "code": null
  },
  {
    "id": 716,
    "type": "2",
    "name": "QFII重仓",
    "level": "",
    "code": null
  },
  {
    "id": 717,
    "type": "2",
    "name": "短剧互动游戏",
    "level": "",
    "code": null
  },
  {
    "id": 718,
    "type": "2",
    "name": "AI语料",
    "level": "",
    "code": null
  },
  {
    "id": 719,
    "type": "2",
    "name": "预盈预增",
    "level": "",
    "code": null
  },
  {
    "id": 720,
    "type": "2",
    "name": "物联网",
    "level": "",
    "code": null
  },
  {
    "id": 721,
    "type": "2",
    "name": "湖北自贸",
    "level": "",
    "code": null
  },
  {
    "id": 722,
    "type": "2",
    "name": "富时罗素",
    "level": "",
    "code": null
  },
  {
    "id": 723,
    "type": "2",
    "name": "农业种植",
    "level": "",
    "code": null
  },
  {
    "id": 724,
    "type": "2",
    "name": "上证180_",
    "level": "",
    "code": null
  },
  {
    "id": 725,
    "type": "2",
    "name": "虚拟数字人",
    "level": "",
    "code": null
  },
  {
    "id": 726,
    "type": "2",
    "name": "植物照明",
    "level": "",
    "code": null
  },
  {
    "id": 727,
    "type": "2",
    "name": "PPP模式",
    "level": "",
    "code": null
  },
  {
    "id": 728,
    "type": "2",
    "name": "Web3.0",
    "level": "",
    "code": null
  },
  {
    "id": 729,
    "type": "2",
    "name": "ChatGPT概念",
    "level": "",
    "code": null
  },
  {
    "id": 730,
    "type": "2",
    "name": "创业成份",
    "level": "",
    "code": null
  },
  {
    "id": 731,
    "type": "2",
    "name": "ERP概念",
    "level": "",
    "code": null
  },
  {
    "id": 732,
    "type": "2",
    "name": "钒电池",
    "level": "",
    "code": null
  },
  {
    "id": 733,
    "type": "2",
    "name": "机构重仓",
    "level": "",
    "code": null
  },
  {
    "id": 734,
    "type": "2",
    "name": "娃哈哈概念",
    "level": "",
    "code": null
  },
  {
    "id": 735,
    "type": "2",
    "name": "举牌",
    "level": "",
    "code": null
  },
  {
    "id": 736,
    "type": "2",
    "name": "大数据",
    "level": "",
    "code": null
  },
  {
    "id": 737,
    "type": "2",
    "name": "独家药品",
    "level": "",
    "code": null
  },
  {
    "id": 738,
    "type": "2",
    "name": "拼多多概念",
    "level": "",
    "code": null
  },
  {
    "id": 739,
    "type": "2",
    "name": "大飞机",
    "level": "",
    "code": null
  },
  {
    "id": 740,
    "type": "2",
    "name": "数字货币",
    "level": "",
    "code": null
  },
  {
    "id": 741,
    "type": "2",
    "name": "华为汽车",
    "level": "",
    "code": null
  },
  {
    "id": 742,
    "type": "2",
    "name": "钛白粉",
    "level": "",
    "code": null
  },
  {
    "id": 743,
    "type": "2",
    "name": "MLOps概念",
    "level": "",
    "code": null
  },
  {
    "id": 744,
    "type": "2",
    "name": "字节概念",
    "level": "",
    "code": null
  },
  {
    "id": 745,
    "type": "2",
    "name": "激光雷达",
    "level": "",
    "code": null
  },
  {
    "id": 746,
    "type": "2",
    "name": "工业大麻",
    "level": "",
    "code": null
  },
  {
    "id": 747,
    "type": "2",
    "name": "国产芯片",
    "level": "",
    "code": null
  },
  {
    "id": 748,
    "type": "2",
    "name": "空间计算",
    "level": "",
    "code": null
  },
  {
    "id": 749,
    "type": "2",
    "name": "华为概念",
    "level": "",
    "code": null
  },
  {
    "id": 750,
    "type": "2",
    "name": "国资云概念",
    "level": "",
    "code": null
  },
  {
    "id": 751,
    "type": "2",
    "name": "智慧政务",
    "level": "",
    "code": null
  },
  {
    "id": 752,
    "type": "2",
    "name": "蝗虫防治",
    "level": "",
    "code": null
  },
  {
    "id": 753,
    "type": "2",
    "name": "特斯拉",
    "level": "",
    "code": null
  },
  {
    "id": 754,
    "type": "2",
    "name": "人脑工程",
    "level": "",
    "code": null
  },
  {
    "id": 755,
    "type": "2",
    "name": "跨境电商",
    "level": "",
    "code": null
  },
  {
    "id": 756,
    "type": "2",
    "name": "粮食概念",
    "level": "",
    "code": null
  },
  {
    "id": 757,
    "type": "2",
    "name": "汽车芯片",
    "level": "",
    "code": null
  },
  {
    "id": 758,
    "type": "2",
    "name": "CAR-T细胞疗法",
    "level": "",
    "code": null
  },
  {
    "id": 759,
    "type": "2",
    "name": "Kimi概念",
    "level": "",
    "code": null
  },
  {
    "id": 760,
    "type": "2",
    "name": "半导体概念",
    "level": "",
    "code": null
  },
  {
    "id": 761,
    "type": "2",
    "name": "盲盒经济",
    "level": "",
    "code": null
  },
  {
    "id": 762,
    "type": "2",
    "name": "5G概念",
    "level": "",
    "code": null
  },
  {
    "id": 763,
    "type": "2",
    "name": "沪股通",
    "level": "",
    "code": null
  },
  {
    "id": 764,
    "type": "2",
    "name": "科创板做市商",
    "level": "",
    "code": null
  },
  {
    "id": 765,
    "type": "2",
    "name": "新能源",
    "level": "",
    "code": null
  },
  {
    "id": 766,
    "type": "2",
    "name": "生态农业",
    "level": "",
    "code": null
  },
  {
    "id": 767,
    "type": "2",
    "name": "Chiplet概念",
    "level": "",
    "code": null
  },
  {
    "id": 768,
    "type": "2",
    "name": "海绵城市",
    "level": "",
    "code": null
  },
  {
    "id": 769,
    "type": "2",
    "name": "纾困概念",
    "level": "",
    "code": null
  },
  {
    "id": 770,
    "type": "2",
    "name": "白酒",
    "level": "",
    "code": null
  },
  {
    "id": 771,
    "type": "2",
    "name": "户外露营",
    "level": "",
    "code": null
  },
  {
    "id": 772,
    "type": "2",
    "name": "存储芯片",
    "level": "",
    "code": null
  },
  {
    "id": 773,
    "type": "2",
    "name": "人工智能",
    "level": "",
    "code": null
  },
  {
    "id": 774,
    "type": "2",
    "name": "中特估",
    "level": "",
    "code": null
  },
  {
    "id": 775,
    "type": "2",
    "name": "婴童概念",
    "level": "",
    "code": null
  },
  {
    "id": 776,
    "type": "2",
    "name": "医疗美容",
    "level": "",
    "code": null
  },
  {
    "id": 777,
    "type": "2",
    "name": "区块链",
    "level": "",
    "code": null
  },
  {
    "id": 778,
    "type": "2",
    "name": "互联金融",
    "level": "",
    "code": null
  },
  {
    "id": 779,
    "type": "2",
    "name": "病毒防治",
    "level": "",
    "code": null
  },
  {
    "id": 780,
    "type": "2",
    "name": "上证380",
    "level": "",
    "code": null
  },
  {
    "id": 781,
    "type": "2",
    "name": "财税数字化",
    "level": "",
    "code": null
  },
  {
    "id": 782,
    "type": "2",
    "name": "中药概念",
    "level": "",
    "code": null
  },
  {
    "id": 783,
    "type": "2",
    "name": "创业板综",
    "level": "",
    "code": null
  },
  {
    "id": 784,
    "type": "2",
    "name": "碳交易",
    "level": "",
    "code": null
  },
  {
    "id": 785,
    "type": "2",
    "name": "减速器",
    "level": "",
    "code": null
  },
  {
    "id": 786,
    "type": "2",
    "name": "氮化镓",
    "level": "",
    "code": null
  },
  {
    "id": 787,
    "type": "2",
    "name": "重组蛋白",
    "level": "",
    "code": null
  },
  {
    "id": 788,
    "type": "2",
    "name": "雄安新区",
    "level": "",
    "code": null
  },
  {
    "id": 789,
    "type": "2",
    "name": "云计算",
    "level": "",
    "code": null
  },
  {
    "id": 790,
    "type": "2",
    "name": "地塞米松",
    "level": "",
    "code": null
  },
  {
    "id": 791,
    "type": "2",
    "name": "氟化工",
    "level": "",
    "code": null
  },
  {
    "id": 792,
    "type": "2",
    "name": "鸿蒙概念",
    "level": "",
    "code": null
  },
  {
    "id": 793,
    "type": "2",
    "name": "虚拟现实",
    "level": "",
    "code": null
  },
  {
    "id": 794,
    "type": "2",
    "name": "小金属概念",
    "level": "",
    "code": null
  },
  {
    "id": 795,
    "type": "2",
    "name": "锂矿概念",
    "level": "",
    "code": null
  },
  {
    "id": 796,
    "type": "2",
    "name": "AIGC概念",
    "level": "",
    "code": null
  },
  {
    "id": 797,
    "type": "2",
    "name": "铁路基建",
    "level": "",
    "code": null
  },
  {
    "id": 798,
    "type": "2",
    "name": "商业航天",
    "level": "",
    "code": null
  },
  {
    "id": 799,
    "type": "2",
    "name": "内贸流通",
    "level": "",
    "code": null
  },
  {
    "id": 800,
    "type": "2",
    "name": "中证500",
    "level": "",
    "code": null
  },
  {
    "id": 801,
    "type": "2",
    "name": "被动元件",
    "level": "",
    "code": null
  },
  {
    "id": 802,
    "type": "2",
    "name": "LED",
    "level": "",
    "code": null
  },
  {
    "id": 803,
    "type": "2",
    "name": "幽门螺杆菌概念",
    "level": "",
    "code": null
  },
  {
    "id": 804,
    "type": "2",
    "name": "网络游戏",
    "level": "",
    "code": null
  },
  {
    "id": 805,
    "type": "2",
    "name": "新冠检测",
    "level": "",
    "code": null
  },
  {
    "id": 806,
    "type": "2",
    "name": "低价股",
    "level": "",
    "code": null
  },
  {
    "id": 807,
    "type": "2",
    "name": "裸眼3D",
    "level": "",
    "code": null
  },
  {
    "id": 808,
    "type": "2",
    "name": "CPO概念",
    "level": "",
    "code": null
  },
  {
    "id": 809,
    "type": "2",
    "name": "创新药",
    "level": "",
    "code": null
  },
  {
    "id": 810,
    "type": "2",
    "name": "毫米波概念",
    "level": "",
    "code": null
  },
  {
    "id": 811,
    "type": "2",
    "name": "新冠药物",
    "level": "",
    "code": null
  },
  {
    "id": 812,
    "type": "2",
    "name": "快递概念",
    "level": "",
    "code": null
  },
  {
    "id": 813,
    "type": "2",
    "name": "精准医疗",
    "level": "",
    "code": null
  },
  {
    "id": 814,
    "type": "2",
    "name": "同步磁阻电机",
    "level": "",
    "code": null
  },
  {
    "id": 815,
    "type": "2",
    "name": "CRO",
    "level": "",
    "code": null
  },
  {
    "id": 816,
    "type": "2",
    "name": "参股新三板",
    "level": "",
    "code": null
  },
  {
    "id": 817,
    "type": "2",
    "name": "调味品概念",
    "level": "",
    "code": null
  },
  {
    "id": 818,
    "type": "2",
    "name": "新型工业化",
    "level": "",
    "code": null
  },
  {
    "id": 819,
    "type": "2",
    "name": "土地流转",
    "level": "",
    "code": null
  },
  {
    "id": 820,
    "type": "2",
    "name": "生物医药",
    "level": "",
    "code": null
  },
  {
    "id": 821,
    "type": "2",
    "name": "石墨烯",
    "level": "",
    "code": null
  },
  {
    "id": 822,
    "type": "2",
    "name": "单抗概念",
    "level": "",
    "code": null
  },
  {
    "id": 823,
    "type": "2",
    "name": "移动支付",
    "level": "",
    "code": null
  },
  {
    "id": 824,
    "type": "2",
    "name": "MicroLED",
    "level": "",
    "code": null
  },
  {
    "id": 825,
    "type": "2",
    "name": "智能穿戴",
    "level": "",
    "code": null
  },
  {
    "id": 826,
    "type": "2",
    "name": "电子后视镜",
    "level": "",
    "code": null
  },
  {
    "id": 827,
    "type": "2",
    "name": "算力概念",
    "level": "",
    "code": null
  },
  {
    "id": 828,
    "type": "2",
    "name": "煤化工",
    "level": "",
    "code": null
  },
  {
    "id": 829,
    "type": "2",
    "name": "AI手机",
    "level": "",
    "code": null
  },
  {
    "id": 830,
    "type": "2",
    "name": "预制菜概念",
    "level": "",
    "code": null
  },
  {
    "id": 831,
    "type": "2",
    "name": "环氧丙烷",
    "level": "",
    "code": null
  },
  {
    "id": 832,
    "type": "2",
    "name": "基本金属",
    "level": "",
    "code": null
  },
  {
    "id": 833,
    "type": "2",
    "name": "中芯概念",
    "level": "",
    "code": null
  },
  {
    "id": 834,
    "type": "2",
    "name": "天然气",
    "level": "",
    "code": null
  },
  {
    "id": 835,
    "type": "2",
    "name": "智慧灯杆",
    "level": "",
    "code": null
  },
  {
    "id": 836,
    "type": "2",
    "name": "噪声防治",
    "level": "",
    "code": null
  },
  {
    "id": 837,
    "type": "2",
    "name": "在线旅游",
    "level": "",
    "code": null
  },
  {
    "id": 838,
    "type": "2",
    "name": "京东金融",
    "level": "",
    "code": null
  },
  {
    "id": 839,
    "type": "2",
    "name": "抗原检测",
    "level": "",
    "code": null
  },
  {
    "id": 840,
    "type": "2",
    "name": "毛发医疗",
    "level": "",
    "code": null
  },
  {
    "id": 841,
    "type": "2",
    "name": "富士康",
    "level": "",
    "code": null
  },
  {
    "id": 842,
    "type": "2",
    "name": "肝炎概念",
    "level": "",
    "code": null
  },
  {
    "id": 843,
    "type": "2",
    "name": "超级真菌",
    "level": "",
    "code": null
  },
  {
    "id": 844,
    "type": "2",
    "name": "华为欧拉",
    "level": "",
    "code": null
  },
  {
    "id": 845,
    "type": "2",
    "name": "职业教育",
    "level": "",
    "code": null
  },
  {
    "id": 846,
    "type": "2",
    "name": "辅助生殖",
    "level": "",
    "code": null
  },
  {
    "id": 847,
    "type": "2",
    "name": "无线充电",
    "level": "",
    "code": null
  },
  {
    "id": 848,
    "type": "2",
    "name": "地下管网",
    "level": "",
    "code": null
  },
  {
    "id": 849,
    "type": "2",
    "name": "微盘股",
    "level": "",
    "code": null
  },
  {
    "id": 850,
    "type": "2",
    "name": "在线教育",
    "level": "",
    "code": null
  },
  {
    "id": 851,
    "type": "2",
    "name": "网络安全",
    "level": "",
    "code": null
  },
  {
    "id": 852,
    "type": "2",
    "name": "苹果概念",
    "level": "",
    "code": null
  },
  {
    "id": 853,
    "type": "2",
    "name": "生物疫苗",
    "level": "",
    "code": null
  },
  {
    "id": 854,
    "type": "2",
    "name": "深圳特区",
    "level": "",
    "code": null
  },
  {
    "id": 855,
    "type": "2",
    "name": "人造太阳",
    "level": "",
    "code": null
  },
  {
    "id": 856,
    "type": "2",
    "name": "REITs概念",
    "level": "",
    "code": null
  },
  {
    "id": 857,
    "type": "2",
    "name": "流感",
    "level": "",
    "code": null
  },
  {
    "id": 858,
    "type": "2",
    "name": "互联医疗",
    "level": "",
    "code": null
  },
  {
    "id": 859,
    "type": "2",
    "name": "玻璃基板",
    "level": "",
    "code": null
  },
  {
    "id": 860,
    "type": "2",
    "name": "高压快充",
    "level": "",
    "code": null
  },
  {
    "id": 861,
    "type": "2",
    "name": "科创板做市股",
    "level": "",
    "code": null
  },
  {
    "id": 862,
    "type": "2",
    "name": "复合集流体",
    "level": "",
    "code": null
  },
  {
    "id": 863,
    "type": "2",
    "name": "合成生物",
    "level": "",
    "code": null
  },
  {
    "id": 864,
    "type": "2",
    "name": "国产软件",
    "level": "",
    "code": null
  },
  {
    "id": 865,
    "type": "2",
    "name": "健康中国",
    "level": "",
    "code": null
  },
  {
    "id": 866,
    "type": "2",
    "name": "无线耳机",
    "level": "",
    "code": null
  },
  {
    "id": 867,
    "type": "2",
    "name": "知识产权",
    "level": "",
    "code": null
  },
  {
    "id": 868,
    "type": "2",
    "name": "eSIM",
    "level": "",
    "code": null
  },
  {
    "id": 869,
    "type": "2",
    "name": "养老概念",
    "level": "",
    "code": null
  },
  {
    "id": 870,
    "type": "2",
    "name": "基金重仓",
    "level": "",
    "code": null
  },
  {
    "id": 871,
    "type": "2",
    "name": "阿里概念",
    "level": "",
    "code": null
  },
  {
    "id": 872,
    "type": "2",
    "name": "磷化工",
    "level": "",
    "code": null
  },
  {
    "id": 873,
    "type": "2",
    "name": "痘病毒防治",
    "level": "",
    "code": null
  },
  {
    "id": 874,
    "type": "2",
    "name": "社保重仓",
    "level": "",
    "code": null
  },
  {
    "id": 875,
    "type": "2",
    "name": "稀缺资源",
    "level": "",
    "code": null
  },
  {
    "id": 876,
    "type": "2",
    "name": "IGBT概念",
    "level": "",
    "code": null
  },
  {
    "id": 877,
    "type": "2",
    "name": "百元股",
    "level": "",
    "code": null
  },
  {
    "id": 878,
    "type": "2",
    "name": "屏下摄像",
    "level": "",
    "code": null
  },
  {
    "id": 879,
    "type": "2",
    "name": "新零售",
    "level": "",
    "code": null
  },
  {
    "id": 880,
    "type": "2",
    "name": "C2M概念",
    "level": "",
    "code": null
  },
  {
    "id": 881,
    "type": "2",
    "name": "统一大市场",
    "level": "",
    "code": null
  },
  {
    "id": 882,
    "type": "2",
    "name": "装配建筑",
    "level": "",
    "code": null
  },
  {
    "id": 883,
    "type": "2",
    "name": "蚂蚁概念",
    "level": "",
    "code": null
  },
  {
    "id": 884,
    "type": "2",
    "name": "ST股",
    "level": "",
    "code": null
  },
  {
    "id": 885,
    "type": "2",
    "name": "柔性屏(折叠屏)",
    "level": "",
    "code": null
  },
  {
    "id": 886,
    "type": "2",
    "name": "数据确权",
    "level": "",
    "code": null
  },
  {
    "id": 887,
    "type": "2",
    "name": "数字哨兵",
    "level": "",
    "code": null
  },
  {
    "id": 888,
    "type": "2",
    "name": "跨境支付",
    "level": "",
    "code": null
  },
  {
    "id": 889,
    "type": "2",
    "name": "UWB概念",
    "level": "",
    "code": null
  },
  {
    "id": 890,
    "type": "2",
    "name": "Sora概念",
    "level": "",
    "code": null
  },
  {
    "id": 891,
    "type": "2",
    "name": "生物识别",
    "level": "",
    "code": null
  },
  {
    "id": 892,
    "type": "2",
    "name": "液冷概念",
    "level": "",
    "code": null
  },
  {
    "id": 893,
    "type": "2",
    "name": "数字阅读",
    "level": "",
    "code": null
  },
  {
    "id": 894,
    "type": "2",
    "name": "化工原料",
    "level": "",
    "code": null
  },
  {
    "id": 895,
    "type": "2",
    "name": "彩票概念",
    "level": "",
    "code": null
  },
  {
    "id": 896,
    "type": "2",
    "name": "RCS概念",
    "level": "",
    "code": null
  },
  {
    "id": 897,
    "type": "2",
    "name": "可燃冰",
    "level": "",
    "code": null
  },
  {
    "id": 898,
    "type": "2",
    "name": "空间站概念",
    "level": "",
    "code": null
  },
  {
    "id": 899,
    "type": "2",
    "name": "星闪概念",
    "level": "",
    "code": null
  },
  {
    "id": 900,
    "type": "2",
    "name": "油气设服",
    "level": "",
    "code": null
  },
  {
    "id": 901,
    "type": "2",
    "name": "新型城镇化",
    "level": "",
    "code": null
  },
  {
    "id": 902,
    "type": "2",
    "name": "增强现实",
    "level": "",
    "code": null
  },
  {
    "id": 903,
    "type": "2",
    "name": "6G概念",
    "level": "",
    "code": null
  },
  {
    "id": 904,
    "type": "2",
    "name": "PVDF概念",
    "level": "",
    "code": null
  },
  {
    "id": 905,
    "type": "2",
    "name": "信创",
    "level": "",
    "code": null
  },
  {
    "id": 906,
    "type": "2",
    "name": "化妆品概念",
    "level": "",
    "code": null
  },
  {
    "id": 907,
    "type": "2",
    "name": "影视概念",
    "level": "",
    "code": null
  },
  {
    "id": 908,
    "type": "2",
    "name": "OLED",
    "level": "",
    "code": null
  },
  {
    "id": 909,
    "type": "2",
    "name": "抖音小店",
    "level": "",
    "code": null
  },
  {
    "id": 910,
    "type": "2",
    "name": "蓝宝石",
    "level": "",
    "code": null
  },
  {
    "id": 911,
    "type": "2",
    "name": "猪肉概念",
    "level": "",
    "code": null
  },
  {
    "id": 912,
    "type": "2",
    "name": "免税概念",
    "level": "",
    "code": null
  },
  {
    "id": 913,
    "type": "2",
    "name": "建筑节能",
    "level": "",
    "code": null
  },
  {
    "id": 914,
    "type": "2",
    "name": "光伏建筑一体化",
    "level": "",
    "code": null
  },
  {
    "id": 915,
    "type": "2",
    "name": "高送转",
    "level": "",
    "code": null
  },
  {
    "id": 916,
    "type": "2",
    "name": "鸡肉概念",
    "level": "",
    "code": null
  },
  {
    "id": 917,
    "type": "2",
    "name": "数据安全",
    "level": "",
    "code": null
  },
  {
    "id": 918,
    "type": "2",
    "name": "VPN",
    "level": "",
    "code": null
  },
  {
    "id": 919,
    "type": "2",
    "name": "AIPC",
    "level": "",
    "code": null
  },
  {
    "id": 920,
    "type": "2",
    "name": "HIT电池",
    "level": "",
    "code": null
  },
  {
    "id": 921,
    "type": "2",
    "name": "广电",
    "level": "",
    "code": null
  },
  {
    "id": 922,
    "type": "2",
    "name": "乳业",
    "level": "",
    "code": null
  },
  {
    "id": 923,
    "type": "2",
    "name": "光伏高速公路",
    "level": "",
    "code": null
  },
  {
    "id": 924,
    "type": "2",
    "name": "阿兹海默",
    "level": "",
    "code": null
  },
  {
    "id": 925,
    "type": "2",
    "name": "TOPCon电池",
    "level": "",
    "code": null
  },
  {
    "id": 926,
    "type": "2",
    "name": "代糖概念",
    "level": "",
    "code": null
  },
  {
    "id": 927,
    "type": "2",
    "name": "民爆概念",
    "level": "",
    "code": null
  },
  {
    "id": 928,
    "type": "2",
    "name": "人造肉",
    "level": "",
    "code": null
  },
  {
    "id": 929,
    "type": "2",
    "name": "页岩气",
    "level": "",
    "code": null
  },
  {
    "id": 930,
    "type": "2",
    "name": "工程机械概念",
    "level": "",
    "code": null
  },
  {
    "id": 931,
    "type": "2",
    "name": "社区团购",
    "level": "",
    "code": null
  },
  {
    "id": 932,
    "type": "2",
    "name": "注射器概念",
    "level": "",
    "code": null
  },
  {
    "id": 933,
    "type": "2",
    "name": "全息技术",
    "level": "",
    "code": null
  },
  {
    "id": 934,
    "type": "2",
    "name": "第四代半导体",
    "level": "",
    "code": null
  },
  {
    "id": 935,
    "type": "2",
    "name": "3D摄像头",
    "level": "",
    "code": null
  },
  {
    "id": 936,
    "type": "2",
    "name": "换电概念",
    "level": "",
    "code": null
  },
  {
    "id": 937,
    "type": "2",
    "name": "电子车牌",
    "level": "",
    "code": null
  },
  {
    "id": 938,
    "type": "2",
    "name": "中超概念",
    "level": "",
    "code": null
  },
  {
    "id": 939,
    "type": "2",
    "name": "光通信模块",
    "level": "",
    "code": null
  },
  {
    "id": 940,
    "type": "2",
    "name": "铜缆高速连接",
    "level": "",
    "code": null
  },
  {
    "id": 941,
    "type": "2",
    "name": "油价相关",
    "level": "",
    "code": null
  },
  {
    "id": 942,
    "type": "2",
    "name": "股权转让",
    "level": "",
    "code": null
  },
  {
    "id": 943,
    "type": "2",
    "name": "超级电容",
    "level": "",
    "code": null
  },
  {
    "id": 944,
    "type": "2",
    "name": "F5G概念",
    "level": "",
    "code": null
  },
  {
    "id": 945,
    "type": "2",
    "name": "租售同权",
    "level": "",
    "code": null
  },
  {
    "id": 946,
    "type": "2",
    "name": "数字水印",
    "level": "",
    "code": null
  },
  {
    "id": 947,
    "type": "2",
    "name": "减肥药",
    "level": "",
    "code": null
  },
  {
    "id": 948,
    "type": "2",
    "name": "黄金概念",
    "level": "",
    "code": null
  },
  {
    "id": 949,
    "type": "2",
    "name": "SPD概念",
    "level": "",
    "code": null
  },
  {
    "id": 950,
    "type": "2",
    "name": "钙钛矿电池",
    "level": "",
    "code": null
  },
  {
    "id": 951,
    "type": "2",
    "name": "MiniLED",
    "level": "",
    "code": null
  },
  {
    "id": 952,
    "type": "2",
    "name": "电子身份证",
    "level": "",
    "code": null
  },
  {
    "id": 953,
    "type": "2",
    "name": "托育服务",
    "level": "",
    "code": null
  },
  {
    "id": 954,
    "type": "2",
    "name": "3D玻璃",
    "level": "",
    "code": null
  },
  {
    "id": 956,
    "type": "2",
    "name": "DRG/DIP",
    "level": "",
    "code": null
  }
]
module.exports = {data}