import Vue from 'vue'
import Router from 'vue-router'

import tabBar from '@/components/tabBar'

import reportHome from '@/components/reportHome'
import induCompare from '@/components/induCompare'
import myUser from '@/components/myUser'
import notLogged from '@/components/notLogged'

import login from '@/components/login'
import subscriptionPage from '@/components/subscriptionPage'
import reportBuy from '@/components/reportBuy'
import induCompareBuy from '@/components/induCompareBuy'

import reportView from '@/components/reportView'
import buyView from '@/components/buyView'


Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'tabBar',
    component: tabBar,
    children: [
      {
        path: '/reportHome',
        name: 'reportHome',
        component: reportHome
      }, {
        path: '/induCompare',
        name: 'induCompare',
        component: induCompare
      }, {
        path: '/myUser',
        name: 'myUser',
        component: myUser
      }, {
        path: '/notLogged',
        name: 'notLogged',
        component: notLogged
      }
    ]
  }, {
    path: '/login',
    name: 'login',
    component: login
  }, {
    path: '/subscriptionPage',
    name: 'subscriptionPage',
    component: subscriptionPage
  }, {
    path: '/reportBuy',
    name: 'reportBuy',
    component: reportBuy
  }, {
    path: '/induCompareBuy',
    name: 'induCompareBuy',
    component: induCompareBuy
  }, {
    path: '/reportView',
    name: 'reportView',
    component: reportView
  }, {
    path: '/buyView',
    name: 'buyView',
    component: buyView
  }
]


const router = new Router({
  mode: 'hash',
  routes
})


export default router
