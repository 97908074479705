import axios from 'axios';

export function linkPath(that, url, data) {
  if (that.$route.path != url) {
    that.$router.push({
      path: url,
      query: data
    });
  }
}
export function linkName(that, url, data) {
  if (that.$route.name != url) {
    that.$router.push({
      name: url,
      query: data
    });
  }
}
/**
 *
 * @param url 不带ip
 * @param type 请求类型，get/post
 * @param data 数据，json
 * @param headers 请求头，json
 * @param suc 成功回调方法，function
 * @param err 失败回调方法，function
 */
export function sendRequestCompany(url, type, data, headers, suc, err) {
  const form = new FormData();
  form.append("url", url);
  form.append("type", type);
  form.append("data", JSON.stringify(data));
  form.append("headers", JSON.stringify(headers));

  const options = {
    method: 'POST',
    url: '/beawanHome/registerController/requestTransferCompany',
    headers: { 'content-type': 'multipart/form-data' },
    data: form
  };


  axios.request(options).then((response) => {
    suc(response.data);
  }).catch((error) => {
    err(error.data);
  });

}

export function sendRequestCompany2(url, type, data, suc, err) {
  var token = localStorage.getItem('satoken');

  const form = new FormData();
  form.append("url", url);
  form.append("type", type);
  form.append("data", JSON.stringify(data));
  form.append("headers", JSON.stringify({ 'satoken': token }));

  const options = {
    method: 'POST',
    url: '/beawanHome/registerController/requestTransferCompany',
    headers: { 'content-type': 'multipart/form-data' },
    data: form
  };


  axios.request(options).then((response) => {
    suc(response);
  }).catch((error) => {
    err(error);
  });

}

/**
 *
 * @param url 不带ip
 * @param type 请求类型，get/post
 * @param data 数据，json
 * @param suc 成功回调方法，function
 * @param err 失败回调方法，function
 */
export function sendRequestIndustry(url, type, data, suc, err) {
  var token = localStorage.getItem('satoken');

  const form = new FormData();
  form.append("url", url);
  form.append("type", type);
  form.append("data", JSON.stringify(data));
  form.append("headers", JSON.stringify({ 'satoken': token }));

  const options = {
    method: 'POST',
    url: '/beawanHome/registerController/requestTransferIndustry',
    headers: { 'content-type': 'multipart/form-data' },
    data: form
  };


  axios.request(options).then((response) => {
    suc(response);
  }).catch((error) => {
    err(error);
  });

}

/**
 *
 * @param url 不带ip
 * @param type 请求类型，get/post
 * @param data 数据，json
 * @param suc 成功回调方法，function
 * @param err 失败回调方法，function
 */
export function sendRequestIndustry2(url, type, data, header, suc, err) {
  var token = localStorage.getItem('satoken');

  const form = new FormData();
  form.append("url", url);
  form.append("type", type);
  form.append("data", JSON.stringify(data));
  header.satoken = token;
  // form.append("headers", JSON.stringify({ 'satoken': token }));
  form.append("headers", JSON.stringify(header));

  const options = {
    method: 'POST',
    url: '/beawanHome/registerController/requestTransferIndustry',
    headers: { 'content-type': 'multipart/form-data' },
    data: form
  };


  axios.request(options).then((response) => {
    suc(response);
  }).catch((error) => {
    err(error);
  });

}

/**
 *
 * @param url 不带ip
 * @param type 请求类型，get/post
 * @param data 数据，json
 * @param suc 成功回调方法，function
 * @param err 失败回调方法，function
 */
export function sendRequestCompanyLocation(url, type, data, suc, err) {
  var token = localStorage.getItem('satoken');

  const form = new FormData();
  form.append("url", url);
  form.append("type", type);
  form.append("data", JSON.stringify(data));
  form.append("headers", JSON.stringify({ 'satoken': token }));

  const options = {
    method: 'POST',
    url: '/beawanHome/registerController/requestTransferCompanyLocation',
    headers: { 'content-type': 'multipart/form-data' },
    data: form
  };


  axios.request(options).then((response) => {
    suc(response);
  }).catch((error) => {
    err(error);
  });

}

/**
 * 微信H5支付-文章url
 * @param url 
 * @param type 
 * @param title 
 * @param money 
 */
export function wxpayH5(url, type, title, money) {
  var token = localStorage.getItem('satoken');
  var newUrl = 'https://www.beawan.com/beawanHome/registerController/requestTransferIndustryWxpayH5'+
    '?url=' + url +
    '&type=' + type +
    '&title=' + title +
    '&money=' + money +
    '&satoken=' + token;
  return newUrl;
}

/**
 * 微信H5支付-会员
 * @param skuId 
 * @param type 
 */
export function wxpayH5Member(skuId, type) {
  var token = localStorage.getItem('satoken');
  var newUrl = 'https://www.beawan.com/beawanHome/registerController/requestTransferCompanyWxpayH5'+
    '?skuId=' + skuId +
    '&type=' + type +
    '&satoken=' + token;
  return newUrl;
}