<template>
  <div class="main">
    <div class="top">
      <img src="../../static/img/beawan_title.svg" class="title">
      <img src="../../static/img/back_img.png" class="backImg">
      <!-- <t-search v-model="inputText" placeholder="请输入公司名称查询" class="searchBox" @change="search" @clear="searchClear"/> -->
      <t-input v-model="inputText" placeholder="请输入公司名称、代码查询" class="searchBox" clearable size="large" @change="search" @clear="searchClear">
        <search-icon slot="prefix-icon"/>
      </t-input>

      <div class="searchResult">
        <div class="searchResultBox">
          <div :key="index" v-for="(item, index) in searchResultList" @click="toReport(item)" class="searchResultItem">
            {{item.comName}}({{item.comCode}})
          </div>
        </div>
      </div>

    </div>

    <div class="content">

      <div class="hotBox" style="margin-top: 0;" v-if="hotList.length>0">
        <div class="hotTitle">
          <img src="../../static/img/hot.svg" class="hotIcon">
          <span class="hotText">热门</span>
        </div>
        <div class="hotList">
          <div :key="index" v-for="(item, index) in hotList" @click="toReport(item)" class="hotItem">
            <span style="color: #595959;margin-right: 6px;">{{item.comName}}</span>
            <span style="color: #999999;font-size: 14px;">{{item.comCode}}</span>
          </div>
        </div>
      </div>

      <div class="historyBox">
        <div class="historyTitle">
          <img src="../../static/img/history.svg" class="historyIcon">
          <span class="historyText">历史搜索</span>
          <img src="../../static/img/clear.svg" class="clearIcon" @click="historyClear">
        </div>
        <div class="historyList">
          <div :key="index" v-for="(item, index) in searchHistory" @click="toReport(item)" class="historyItem">
            <span style="color: #595959;">{{item.comName}}</span>
            <span style="color: #999999;font-size: 14px;">{{item.comCode}}</span>
          </div>
        </div>
      </div>

      <div style="height: 90px;"></div>

    </div>

    <div class="footerText">
      <div>
        杭州碧湾信息技术有限公司
      </div>
      <div>
        联系电话：0571-86685367
      </div>
    </div>
  </div>
</template>

<script>
import { SearchIcon } from 'tdesign-icons-vue';
import { sendRequestCompany2, sendRequestIndustry } from '../../static/js/util';

export default {
  components: { SearchIcon },
  data() {
    return {
      inputText: '',
      searchResultShow: false,
      searchDataList: [],
      searchResultList: [],
      searchHistory: [],
      hotList: []
    };
  },
  mounted() {
    var history = localStorage.getItem('searchHistory');
    if(history!=null) {
      this.searchHistory = JSON.parse(history);
    }
    this.getHotList();
    this.getSearchData();
  },
  methods: {
    //取热门股
    getHotList() {
      let url = 'company/tongHotCom'
      sendRequestIndustry(url, 'get', null, (res) => {
        // console.log(res)
        if(res.data.code==0) {
          this.hotList = res.data.data;
          if(this.hotList.length>12) {
            this.hotList = this.hotList.splice(0, 12);
          }
        }
      }, (err) => {
        console.log(err)
      });
    },
    /**
     * 读取缓存的公司列表，没有再请求
     * searchDataRead读取次数达到100次就重新获取
     */
    getSearchData() {
      var readNum = localStorage.getItem('searchDataRead');
      if(readNum==null) {
        readNum = -1;
      }else {
        readNum = parseInt(readNum);
      }

      if(readNum>0 && readNum<100) {
        var list = localStorage.getItem('searchDataList');
        this.searchDataList = JSON.parse(list);
        localStorage.setItem('searchDataRead', readNum+1);
      }else {
        let url = '/company/comList'
        sendRequestCompany2(url, 'get', null, (res) => {
          this.searchDataList = res.data;
          localStorage.setItem('searchDataList', JSON.stringify(res.data));
          localStorage.setItem('searchDataRead', 1);
        }, (err) => {
          console.log(err)
        });
      }
    },
    /**
     * 搜索
     */
    search() {
      var text = this.inputText;
      if (text !== '' && text.length > 0) {
        let isNumber = !isNaN(parseFloat(text)) && isFinite(text);
        let ret = isNumber == true ? this.QueryCode(this.searchDataList, text) : this.QueryName(this.searchDataList, text);
        if (ret != null) {
          this.searchResultList = ret;
        }
      } else {
        this.searchResultList = [];
      }
    },
    QueryName(list, keyWord) {
      var reg = new RegExp(keyWord);
      var arr = [];
      if (list == null) {
        return arr;
      }
      for (var i = 0; i < list.length; i++) {
        if (reg.test(list[i].comName)) {
          arr.push(list[i]);
          if (arr.length > 10) {
            break;
          }
        }
      }
      return arr;
    },
    QueryCode(list, keyWord) {
      var reg = new RegExp(keyWord);
      var arr = [];
      if (list == null) {
        return arr;
      }
      for (var i = 0; i < list.length; i++) {
        if (reg.test(list[i].comCode)) {
          arr.push(list[i]);
          if (arr.length > 10) {
            break;
          }
        }
      }
      return arr;
    },
    /**
     * 搜索框清除
     */
    searchClear() {
      this.searchResultList = [];
    },
    /**
     * 历史记录清除
     */
    historyClear() {
      var state = confirm('删除历史记录？');
      if(state) {
        this.searchHistory = [];
        localStorage.setItem('searchHistory', '[]');
      }
      
    },
    /**
     * 点击搜索结果/历史记录
     */
    toReport(item) {
      // console.log(item)
      //存搜索历史
      var temp = this.searchHistory;

      for(var i=0; i<temp.length; i++) {
        if(temp[i].comCode == item.comCode) {
          temp.splice(i, 1);
          break;
        }
      }

      if(temp.length == 8) {
        temp.pop()
      }
      temp.unshift(item)

      this.searchHistory = temp;
      localStorage.setItem('searchHistory', JSON.stringify(temp));
      
      //跳转
      // wx.navigateTo({
      //   url: '/pages/report/report?comCode='+item.comCode+'&comName='+item.comName
      // })
      this.$router.push({
        name: 'reportBuy',
        query: {
          comCode: item.comCode,
          comName: item.comName
        }
      });
    }
  }
}
</script>

<style>
body {
  --td-brand-color: #D84A36;
  --td-brand-color-focus: #F4CAC6;
}
</style>
<style scoped>

.main {
  /* display: flex; */
  /* flex-direction: column; */
  height: 100%;
  background-color: #F2F2F2;
}

.top {
  height: 230px;
  background-color: #F4CAC6;
}
.title {
  width: calc(55% - 20px);
  height: 150px;
  margin-left: 20px;
  padding-bottom: 30px;
  position: relative;
  z-index: 2;
}
.backImg {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: 13px;
  width: 60%;
}
.searchBox {
  width: 86%;
  margin: -8px 7% 0 7%;
  position: relative;
  z-index: 3;
}
.t-icon-search {
  color: #D84A36;
}

.content {
  flex: 1;
  margin-bottom: 10px;
}
.searchResult {
  position: absolute;
  width: calc(100% - 80px);
  padding: 3px 40px 0 40px;
  z-index: 1000;
}
.searchResultBox {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  background-color: #FFFFFF;
  /* padding: 6px 0; */
  overflow-y: auto;
}
.searchResultItem {
  height: 36px;
  line-height: 36px;
  /* padding-left: 20px; */
  padding: 3px 0 3px 20px;
  font-size: 17px;
}
.historyBox, .hotBox {
  margin-top: 15px;
  padding: 5px 10px 15px 10px;
  background-color: #FFFFFF;
  position: relative;
  z-index: 10;
}
.historyTitle, .hotTitle {
  display: flex;
  align-items: center;
  padding: 8px 0;
  margin: 0 14px;
  margin-bottom: 5px;
  border-bottom: 1px solid #EBEBEB;
}
.historyText, .hotText {
  color: #595959;
  font-size: 19px;
  font-weight: 600;
  flex: 1;
}
.hotIcon {
  width: 28px;
  height: 28px;
  margin-right: 6px;
}
.historyIcon {
  width: 23px;
  height: 23px;
  margin-right: 11px;
}
.clearIcon {
  width: 24px;
  height: 24px;
}
.historyList, .hotList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
/* .historyItem, .hotItem {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 5px 0 5px 14px;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  padding: 5px 10px;
  width: calc(33% - 40px);
} */
.historyItem, .hotItem {
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: 16px;
  margin-left: 10px;
  padding: 5px 0px;
  width: calc(25% - 10px);
}

.footerText {
  background-color: #F2F2F2;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 13px;
  color: #AAAAAA;
  padding-bottom: 10px;
  padding-bottom: 10px;
  position: fixed;
  bottom: 60px;
  width: 100%;
  z-index: 20;
}

</style>
