<template>
  <div class="main">

    <div class="top">
      <div class="back" @click="back"><ChevronLeftIcon />返回</div>
      <div class="topTitle">{{ comName }}</div>
    </div>

    <div class="content">
      <img mode="aspectFill" src="../../static/img/report_image.png" class="reportimg">
    </div>
    <div class="buyBox" v-if="showBuy">
      <!-- <text class="text1">成为VIP或单次购买即可查看文档</text>
      <text class="text2">最新上市公司报告分析尽在碧湾App</text>
      <text class="text3">新注册用户可查看10篇文章</text> -->
      <div class="text">您的查看次数已用完，成为VIP或单次购买报告即可继续查看</div>
      <img src="../../static/img/kthy_icon.png" class="img">
      <div>
        <button class="btn" @click="toSubscription">开通会员</button>
      </div>
    </div>
    <div class="footer">
      <div class="footerMenu1">
        <!-- <picker bindchange="changeReportIndex" value="{{reportTimeIndex}}" range="{{reportTimeList}}" range-key="name">
          <div class="picker">
            <span>{{reportTimeList[reportTimeIndex].name}}</span>
            <img src="../../static/img/more.png" class="moreIcon">
          </div>
        </picker> -->
        <t-select v-model="reportTimeIndex" :onChange="changeReportIndex">
          <t-option v-for="(item, index) in reportTimeList" :key="item.id" :label="item.name" :value="index" />
        </t-select>
      </div>
      <div class="footerMenu2" @click="buyButton">
        <span>{{buyButtonText}}</span>
      </div>
    </div>

    <!-- <div style="width: 0;height: 0;">
      <iframe id="myIframe" style="width: 0;height: 0;border: 0;" src=""></iframe>
    </div> -->
  </div>
</template>

<script>
import { ChevronLeftIcon } from 'tdesign-icons-vue';
import { sendRequestIndustry, wxpayH5 } from '../../static/js/util';

export default {
  components: {
    ChevronLeftIcon
  },
  data() {
    return {
      comName: '',
      comCode: '',
      year: '2023',
      type: '三季报',
      shareUrl: null,
      thisUrl: null,
      rankMoney: 2500,
      buyButtonText: '购买报告',
      showBuy: true,

      userInfo: null,
      userCode: null,

      reportTimeList: [
        {id: 65051, year: "2023", stage: "年报", name: "2023年年报"},
        {id: 64988, year: "2023", stage: "三季报", name: "2023年三季报"},
        {id: 58892, year: "2023", stage: "中报", name: "2023年中报"}
      ],
      reportTimeIndex: 0,
      mydialog: null
    };
  },
  mounted() {
    this.comCode = this.$route.query.comCode;
    this.comName = this.$route.query.comName;

    this.getReportDate();
  },
  methods: {
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   name: 'reportHome'
      // });
    },
    /**
     * 改报告时间
     */
    changeReportIndex(index) {
      this.reportTimeIndex = index;
      this.year = this.reportTimeList[index].year;
      this.type = this.reportTimeList[index].stage;

      this.getReport();

    },

    /**
     * 获取已出报告
     */
    getReportDate() {
      let url = '/company/selfOneComNew?code=' + this.comCode;
      sendRequestIndustry(url, 'get', null, (response)=>{
        // console.log(response)
        if (response.data.code === 0) {
          var tempList = response.data.data.comInfoDTO.comReports;
          var timeIndex = 0;
          for(var i=0; i<tempList.length; i++){
            tempList[i].name = tempList[i].year+'年'+tempList[i].stage;
            if(tempList[i].year==response.data.data.year && tempList[i].stage==response.data.data.stage){
              timeIndex = i;
            }
          }

          this.reportTimeList = response.data.data.comInfoDTO.comReports;
          this.reportTimeIndex = timeIndex;
          this.year = response.data.data.year;
          this.type = response.data.data.stage;

          this.getReport();
          
        } else if (response.data.code === 1) {
          this.$message.error(response.data.msg);

        } else {
          this.$message.error("分析报告未出");
        }
      }, (error)=>{
        console.log(error);
      })
    },

    /**
     * 获取报告url / 获取报告价格
     */
    getReport(gotoView) {
      const instance = this.$loading({
        fullscreen: true,
        attach: 'body',
        preventScrollThrough: false,
      });
      let url = 'company/hasFile/' + this.comCode + '/' + this.year + '/' + this.type;
      sendRequestIndustry(url, 'get', null, (response)=>{
        // console.log(response)
        instance.hide();
        if (response.data.code == 0) {
          if (response.data.data != null) {
            
            this.shareUrl = response.data.data;
            this.buyButtonText = '查看报告';
            this.showBuy = false;

            if(gotoView){
              this.buyButton()
            }

          } else {
            
            this.shareUrl = null;
            this.buyButtonText = '暂无报告';
            this.showBuy = false;

            this.$message.warning("暂无报告，请查询其它公司");
          }
        } else if (response.data.code == '5002') {
          
          this.shareUrl = null;
          this.thisUrl = response.data.data.url;
          this.rankMoney = response.data.data.rankMoney;
          this.buyButtonText = '购买报告（'+response.data.data.rankMoney/100+'元）';
          this.showBuy = true;
        } else {
          
          this.shareUrl = null;
          this.buyButtonText = '暂无报告';
          this.showBuy = false;
          
          this.$message.warning("暂无报告，请查询其它公司");
          
        }
      })
    },

    /**
     * 微信支付
     */
    wxpay() {
      // var newUrl = wxpayH5(this.thisUrl, 'web', '财务分析报告', 1);
      var newUrl = wxpayH5(this.thisUrl, 'web', '财务分析报告', this.rankMoney);
      // console.log(newUrl);
      // document.getElementById('myIframe').src = newUrl;

      // var buyUrl = 'https://www.beawan.com/#/buyView?url=' + encodeURIComponent(newUrl);
      var buyUrl = '/#/buyView?url=' + encodeURIComponent(encodeURIComponent(newUrl));
      window.open(buyUrl);

      setTimeout(()=>{
        this.mydialog = this.$dialog.alert({
          header: '购买完成后请刷新',
          confirmBtn: '刷新',
          closeBtn: false,
          width: '70%',
          onConfirm: ()=>{
            // this.$router.go(0);
            location.reload();
          }
        });
      }, 1000)
    },


    /**
     * 购买按钮
     */
    buyButton() {
      if(this.shareUrl==null && this.showBuy==false){
        this.$message.warning("暂无报告，请查询其它公司");
        return;
      }
      if(this.shareUrl!=null && this.shareUrl.length>1){
        // window.open(this.shareUrl)
        this.$router.push({
          name: 'reportView',
          params: {
            shareUrl: encodeURIComponent(this.shareUrl)
          }
        });
      }else{
        this.wxpay();
      }
    },

    //开通会员
    toSubscription() {
      this.$router.push({
        name: 'subscriptionPage'
      });
    }
  }
}
</script>

<style>
.t-select-option span {
  font-size: 16px;
}
</style>
<style scoped>
.main {
  overflow: hidden;
  height: 100%;
}

.top {
  height: 40px;
  background-color: #F4CAC6;
}
.back {
  display: inline-block;
  width: 50px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.topTitle {
  display: inline-block;
  margin-right: 50px;
  height: 40px;
  line-height: 40px;
  width: calc(100% - 100px);
  text-align: center;
}

.content {
  height: calc(100% - 40px);
}
.reportImage {
  width: 100%;
  height: 800px;
}

.buyBox {
  /* height: 350px;
  width: 100%;
  position: fixed;
  bottom: 70px;
  text-align: center;
  display: flex;
  flex-direction: column; */
  /* background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1), rgba(255,255,255,1)); */
  width: calc(100% - 80px);
  text-align: center;
  padding: 0 40px;
  position: absolute;
  top: calc(50% - 200px);
}
.text1 {
  font-size: 25px;
  margin-top: 150px;
  margin-left: 45px;
  margin-right: 45px;
  font-weight: 600;
}
.text2 {
  color: #999999;
  font-size: 16px;
  margin-top: 10px;
}
.text3 {
  color: #999999;
  font-size: 17px;
  margin-top: 15px;
}
.buyBox .text {
  font-size: 20px;
  color: #CE9151;
}
.buyBox .img {
  width: 60%;
}
.buyBox .btn {
  background-color: #F3BB6D;
  padding: 12px 24px;
  border-radius: 24px;
  color: #704913;
  font-size: 17px;
  width: 170px;
  border: 0;
}

.footer {
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;
  height: 70px;
  width: 100%;
  border-top: 1px solid #CCCCCC;
}

.footerMenu1 {
  width: 50%;
  line-height: 70px;
  text-align: center;
  color: #D84B4E;
  background-color: #FFFFFF;
}
.footerMenu2 {
  width: 50%;
  line-height: 70px;
  text-align: center;
  color: #FFFFFF;
  background-color: #D84B4E;
}
.picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.moreIcon {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.t-select__wrap,
.main /deep/ .t-input,
.main /deep/ .t-input__inner {
  height: 69px;
  font-size: 16px;
}
.main /deep/ .t-select-input {
  margin-top: -3px;
}
</style>
