<template>
  <div class="main">
    <div class="backColor"></div>

    <div class="content">
      <div class="userInfo">
        <img class="userIcon" src="../../static/img/user.png" v-if="userInfo.avatar==null">
        <img class="userIcon" :src="userInfo.avatar" v-else>
        <span class="userName">{{userInfo.userNickName}}</span>
        <div>
          <div v-if="userInfo.level==null" class="normalUser">
            <span>普通用户</span>
          </div>
          <div v-else class="vipUser">
            <span>VIP用户</span>
          </div>
        </div>
      </div>
      <div class="vipCardBox">
        <div class="vipBuy" v-if="userInfo.level==null">
          <div><span>碧湾VIP</span></div>
          <div><span>升级订阅会员尊享不限次查看报告权益</span></div>
          <div><span>VIP</span></div>
          <div><button @click="toSubscription">立即购买</button></div>
        </div>
        <div class="vipCard" v-else>
          <!-- 左上角内凹效果 -->
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>

          <div>
            <div>
              <div>
                <img src="../../static/img/vip_center.svg" class="vipCenterIcon">
              </div>
              <span>会员中心</span>
            </div>
            <div>
              <div>
                <span v-if="userInfo.level==1">碧湾VIP月卡</span>
                <span v-if="userInfo.level==2">碧湾VIP季卡</span>
                <span v-if="userInfo.level==3">碧湾VIP年卡</span>
              </div>
              <div><span>已尊享各平台不限次查看报告权益</span></div>
              <div><img src="../../static/img/vip_card.svg" class="vipCardIcon"></div>
              <div>会员有效期：{{memberStopTime}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="note">
        注：使用碧湾APP时请使用微信登录
      </div> -->

      <div class="featureBox" style="display: none;">
        <div class="featureItem">查看历史</div>
        <div class="featureItem">购买记录</div>
        <div class="featureItem">我的收藏</div>
      </div>
      <div class="history">
        <span style="color: #595959;">我的购买</span>
        <span style="color: #595959;float: right;position: relative;z-index: 100;" @click="refreshList">刷新</span>
        <div class="historyBox" v-if="listData.length > 0">
          <div :key="index" v-for="(item, index) in listData" @click="toReport(item)" class="historyItem">
            <img src="../../static/img/report_unselect.png" style="width: 30px;height: 30px;">
            <span v-if="!item.interfaceUrl.startsWith('http')" style="color: #726D6E;font-size: 16px;margin-left: 4px;">{{item.comName}}({{item.comCode}}){{item.year}}年{{item.stage}}解读</span>
            <span v-else style="color: #726D6E;font-size: 16px;margin-left: 4px;">{{item.title}}</span>
          </div>
        </div>
        <div class="noHistoryBox" v-else>
          <img src="../../static/img/no_file.svg" style="width: 40px;height: 40px;margin-right: 5px;">
          <span style="color: #726D6E;font-size: 17px;">暂无购买文档</span>
        </div>
      </div>
    </div>

    <div class="drawerMenu" @click="showDrawer">
      <EllipsisIcon @click="showDrawer"/>
    </div>
    <t-drawer :visible="visible" placement="right" size="140px" preventScrollThrough :header="false" :footer="false">
      <div class="menuItem" @click="logout">退出登录</div>
    </t-drawer>

    <div class="footerText">
      <div>
        杭州碧湾信息技术有限公司
      </div>
      <div>
        联系电话：0571-86685367
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { EllipsisIcon } from 'tdesign-icons-vue';
import { sendRequestCompany2, sendRequestIndustry } from '../../static/js/util';

export default {
  components: {
    EllipsisIcon
  },
  data() {
    return {
      userInfo: {},
      memberStopTime: '--',
      listData: [],
      visible: false,
      mydialog: null
    };
  },
  mounted() {
    this.getUserInfo();
    this.getList();
  },
  methods: {
    //获取用户信息
    getUserInfo(){
      let url = 'user/userInfo2';
      sendRequestCompany2(url, 'get', {'device': 'web-mobile'}, (response) => {
        // console.log(response)
        this.userInfo = response.data.data;

        var stopTime = this.userInfo.memberStopTime;
        if(stopTime!=null && stopTime.length>0) {
          stopTime = stopTime.substr(0, 10);
        }else {
          stopTime = '--';
        }
        this.memberStopTime = stopTime;
      }, (err) => {
        console.log(err)
      });
    },

    showDrawer() {
      this.visible = true;
      $('.t-drawer__mask').click(()=>{
        this.visible = false;
      });
      // $('.t-drawer__body').click(()=>{
      //   this.visible = false;
      // });
    },

    /**
     * 刷新
     */
    refreshList(){
      const instance = this.$loading({
        fullscreen: true,
        attach: '.main'
      });
      this.getList();
      const timer = setTimeout(() => {
        instance.hide();
        clearTimeout(timer);
      }, 800);
    },

    /**
     * 订单记录
     */
    getList(){
      let url = 'orderPayment/listOrderPayment/500/1';
      sendRequestIndustry(url, 'get', null, (response)=>{
        // console.log(response)
        if(response.data.code == 0){
          var tempList = response.data.data.records;
          if(tempList!=null && tempList.length>0){
            for(var i=0; i<tempList.length; i++){
              if(tempList[i].isPay!='0'
                // || tempList[i].orderType!=3
              ){
                tempList.splice(i, 1);
                i--;
                continue;
              }
              
              //app，小程序购买
              if(tempList[i].stage==0){
                tempList[i].stage = '年报';
              }else if(tempList[i].stage==1){
                tempList[i].stage = '一季报';
              }else if(tempList[i].stage==2){
                tempList[i].stage = '中报';
              }else if(tempList[i].stage==3){
                tempList[i].stage = '三季报';
              }

              //移动端购买
              if(tempList[i].comCode==null && !tempList[i].interfaceUrl.startsWith('http')) {
                let interfaceUrl = tempList[i].interfaceUrl;
                let interfaceUrlData = interfaceUrl.split('/');
                tempList[i].comCode = interfaceUrlData[3];
                tempList[i].year = interfaceUrlData[4];
                tempList[i].stage = interfaceUrlData[5];
              }

              //行业对比
              // if(tempList[i].interfaceUrl.startsWith('http')) {
                
              // }

            }
            this.listData = tempList;
          }else{
            this.listData = [];
          }
        }else{
          this.listData = [];
        }
      }, (error)=>{
        console.log(error);
      })
    },

    /**
     * 跳转
     */
    toReport(item) {
      

      if(item.interfaceUrl.startsWith('http')) {
        //行业对比
        var urlNew = 'https://www.beawan.com/beawanHome/'+item.interfaceUrl.substr(35);
        this.$router.push({
          name: 'reportView',
          params: {
            shareUrl: encodeURIComponent(urlNew)
          }
        });
      }else {
        //报告
        this.$loading(true);
        var url = item.interfaceUrl.substr(9);

        sendRequestIndustry(url, 'get', null, (response)=>{
          // console.log(response)
          this.$loading(false);
          if (response.data.code == 0) {
            if (response.data.data != null) {
              //跳转
              this.$router.push({
                name: 'reportView',
                params: {
                  shareUrl: encodeURIComponent(response.data.data)
                }
              });
            } else {
              this.$message.error("打开异常！")
            }
          } else {
            this.$message.error("打开异常！")
          }
        })
      }
      
    },

    /**
     * 订阅会员
     */
    toSubscription() {
      // wx.navigateTo({
      //   url: '/pages/subscription/subscription'
      // })
      this.$router.push({
        name: 'subscriptionPage'
      });
    },

    //退出登录
    logout() {
      this.mydialog = this.$dialog.alert({
        header: '是否退出登录',
        confirmBtn: '是',
        closeBtn: false,
        width: '70%',
        onConfirm: ()=>{
          var url = 'user/logout';
          sendRequestCompany2(url, 'get', null, () => {
            localStorage.removeItem('satoken');
            localStorage.removeItem('satokenDate');
            localStorage.removeItem('searchHistory');
            this.$router.push({
              name: 'notLogged'
            });
            this.mydialog.hide();
          }, (err) => {
            console.log(err)
          });
        }
      });
    }
  }
}
</script>

<style scoped>
.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #F2F2F2;
}

.backColor {
  height: 250px;
  width: 100%;
  background: linear-gradient(#F4CAC6, #F2F2F2);
  position: absolute;
  top: 0;
}

.content {
  height: 100%;
  z-index: 10;
}
.userInfo {
  padding-left: 30px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.userIcon {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-right: 10px;
  background-color: #F2F2F2;
}
.userName {
  color: #333333;
  font-size: 20px;
}
.normalUser {
  padding: 2px 6px;
  background-color: #A7A7A7;
  color: #fff;
  font-size: 13px;
  border-radius: 3px;
  margin-left: 7px;
  height: 19px;
  line-height: 19px;
}

.drawerMenu {
  position: absolute;
  top: 10px;
  right: 16px;
  width: 34px;
  height: 34px;
  z-index: 100;
}
.drawerMenu svg {
  width: 100%;
  height: 100%;
}
.menuItem {
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  margin: 0 10px;
  border-bottom: 1px solid #dddddd;
}

.vipUser {
  padding: 2px 6px;
  background-color: #FFA724;
  color: #fff;
  font-size: 13px;
  border-radius: 3px;
  margin-left: 7px;
  height: 19px;
  line-height: 19px;
}

.vipCardBox {
  width: 85%;
  margin: 25px auto 10px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.vipBuy {
  width: 100%;
  height: 130px;
  padding: 10px;
  border-radius: 10px;
  background: linear-gradient(137.37deg, rgb(254, 237, 221) 0%,rgb(254, 215, 172) 105.021%);
  position: relative;
}
.vipBuy > div:nth-child(1), .vipBuy > div:nth-child(2) {
  position: relative;
  z-index: 10;
  margin-top: 5px;
}
.vipBuy > div:nth-child(2) {
  background: linear-gradient(266deg, rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.00), rgba(255, 167, 36, 0.40));
}
.vipBuy > div:nth-child(1) > span {
  font-size: 20px;
  font-weight: bold;
  background-image: linear-gradient(265deg, #FFA724, #EF533B);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.vipBuy > div:nth-child(2) > span {
  color: #FB9226;
}
.vipBuy > div:nth-child(3) {
  position: absolute;
  top: -67px;
  right: 0px;
  z-index: 1;
}
.vipBuy > div:nth-child(3) > span {
  width: 312px;
  height: 213px;
  font-size: 180px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.10) 66.36%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.vipBuy > div:nth-child(4) {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 10;
}
.vipBuy > div:nth-child(4) > button {
  width: 130px;
  height: 40px;
  border-radius: 7px;
  background: linear-gradient(90deg, #FFDB66 0%, #FFB871 100%);
  box-shadow: 2px 2px 4.5px 1px rgba(222, 22, 22, 0.25);
  font-size: 19px;
  color: #A43129;
  padding: 6px 12px;
  border-width: 0;
}
.vipCard {
  width: 100%;
  height: 170px;
  border-radius: 10px;
  background: #FFA724;
  position: relative;
}
.vipCard > div:nth-child(1) {
  width: 30px;
  height: 30px;
  background: #FFA724;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 57%;
}
.vipCard > div:nth-child(2) {
  width: 30px;
  height: 30px;
  background: #ff574c;
  position: absolute;
  top: 0;
  left: 30px;
  border-top-left-radius: 57%;
  z-index: 20;
  box-shadow: -3px -1px 6px 0px rgba(243, 10, 10, 0.15);
}
.vipCard > div:nth-child(3) {
  width: 30px;
  height: 30px;
  background: #FFA724;
  position: absolute;
  top: 0;
  left: 30px;
  z-index: 10;
}
.vipCard > div:nth-child(4) {
  width: 30px;
  height: 30px;
  background: #ff574c;
  position: absolute;
  top: 30px;
  left: 0;
  border-top-left-radius: 57%;
  z-index: 20;
  box-shadow: -3px -1px 6px 0px rgba(243, 10, 10, 0.15);
}
.vipCard > div:nth-child(5) {
  width: 30px;
  height: 30px;
  background: #FFA724;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 10;
}
.vipCard > div:nth-child(6) {
  width: 100%;
  height: 170px;
  box-shadow: -3px -1px 6px 0px rgba(243, 10, 10, 0.25);
  background: #ff574c;
  text-align: right;
  border-radius: 10px;
  border-top-left-radius: 60px;
}
.vipCard > div:nth-child(6) > div:nth-child(1) {
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.vipCard > div:nth-child(6) > div:nth-child(1) > span {
  color: #FFF;
  font-size: 15px;
  line-height: 40px;
  margin-right: 25px;
}
.vipCard > div:nth-child(6) > div:nth-child(2) {
  height: 130px;
  background: linear-gradient(99deg, #ff574c 42.79%, #FFA724 98.72%);
  box-shadow: 0px -0.5px 0px 0px #FFB676;
  border-radius: 10px;
  position: relative;
  z-index: 100;
  text-align: left;
}
.vipCard > div:nth-child(6) > div:nth-child(2) > div:nth-child(1) {
  color: #FFB144;
  font-size: 17px;
  padding: 7px;
}
.vipCard > div:nth-child(6) > div:nth-child(2) > div:nth-child(2) {
  color: #FFEE96;
  font-size: 14px;
  padding-left: 7px;
}
.vipCard > div:nth-child(6) > div:nth-child(2) > div:nth-child(4) {
  color: #FFF;
  font-size: 13px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.vipCenterIcon {
  width: 23px;
  height: 23px;
  margin-right: 5px;
  margin-top: 2px;
}
.vipCardIcon {
  width: 60px;
  height: 60px;
  float: left;
  margin-left: 10px;
  margin-top: 10px;
}
.note {
  width: 85%;
  margin: -10px auto 20px auto;
  color: #666;
  font-size: 16px;
}

.featureBox {
  width: 85%;
  height: 55px;
  margin: 20px auto 25px auto;
  background-color: #FFFFFF;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.featureItem {
  color: #595959;
  font-size: 14px;
}

.footerText {
  background-color: #F2F2F2;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 13px;
  color: #AAAAAA;
  padding: 10px 0;
  padding-top: 26px;
  position: fixed;
  bottom: 60px;
  width: 100%;
}

.history {
  width: 85%;
  height: calc(100% - 110px);
  margin: 20px auto;
}
.historyBox {
  height: 70%;
  margin-top: 5px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 7px;
  overflow-y: auto;
}
.noHistoryBox {
  height: 70%;
  margin-top: 5px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 7px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.historyItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;
  align-items: center;
}

.main /deep/ .t-loading__gradient-conic {
  transform: scale(1) !important;
}
.main /deep/ .t-drawer__body {
  padding: 10px 0;
}
</style>
<style>
.t-loading__gradient-conic {
  transform: scale(1) !important;
}
</style>