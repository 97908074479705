<template>
  <div class="main">

    <div class="top">
      <div class="back" @click="back"><ChevronLeftIcon />返回</div>
    </div>

    <div class="loading" v-if="pageState==0">
      <t-loading size="50px" class="large" />
    </div>

    <div class="buyBox" v-if="pageState==1">
      <img src="../../static/img/indu_report_image.png" class="reportImg"/>
      <div class="actionBox">
        <div class="text">您的查看次数已用完，成为VIP或单次购买报告即可继续查看</div>
        <img src="../../static/img/kthy_icon.png" class="img">
        <div class="buyBtns">
          <button class="btn1" @click="toSubscription">开通会员</button>
          <button class="btn2" @click="wxpay">单次购买({{rankMoney/100}}元)</button>
        </div>
      </div>
    </div>

    <div class="buyBox" v-if="pageState==2">
      <img src="../../static/img/indu_report_image.png" class="reportImg"/>
      <div class="actionBox">
        <div class="text">您已购买此报告，可直接查看</div>
        <img src="../../static/img/kthy_icon.png" class="img">
        <div class="buyBtns">
          <button class="btn2" @click="viewReport">查看报告</button>
        </div>
      </div>
    </div>

    <!-- <div style="width: 0;height: 0;">
      <iframe id="myIframe" style="width: 0;height: 0;border: 0;" src=""></iframe>
    </div> -->

  </div>
</template>

<script>
import { ChevronLeftIcon } from 'tdesign-icons-vue';
import { sendRequestIndustry2, wxpayH5 } from '../../static/js/util';

export default {
  components: {
    ChevronLeftIcon
  },
  data() {
    return {
      fundList: [],
      typeList: [],
      interfaceUrl: null,
      pageState: 1,

      userInfo: null,
      userCode: null,

      shareUrl: null,
      thisTitle: '',
      rankMoney: 2000,
      mydialog: null
    };
  },
  mounted() {
    this.fundList = this.$route.query.fundList;
    if(typeof this.fundList == 'string') {
      this.fundList = [this.fundList]
    }
    this.typeList = this.$route.query.typeList;
    if(typeof this.typeList == 'string') {
      this.typeList = [this.typeList]
    }

    this.getData();
  },
  methods: {
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   name: 'induCompare'
      // });
    },
    //获取文章url
    getData() {
      let url = 'company/induLinkUrl';
      let data = {
        stkCdList: this.fundList,
        typeList: this.typeList
      }
      let header = {
        "satoken": null,
        "content-type": "application/json;"
      };
      sendRequestIndustry2(url, 'post', data, header, (response)=>{
        // console.log(response)
        if (response.data.code == 0) {
          if (response.data.data != null) {

            var urlOld = response.data.data.url;
            var urlNew = 'https://www.beawan.com/beawanHome/'+urlOld.substr(35);

            this.shareUrl = urlNew;
            this.thisTitle = response.data.data.title;

            if(this.shareUrl.length > 0) {
              //关闭此页，跳转报告
              // wx.redirectTo({
              //   url: '/pages/reportView/reportView?url='+encodeURIComponent(this.shareUrl)
              //     +'&title='+this.thisTitle
              // })
              // window.open(this.shareUrl)

              // this.$router.push({
              //   name: 'reportView',
              //   params: {
              //     shareUrl: encodeURIComponent(this.shareUrl)
              //   }
              // });

              this.pageState = 2;

            }else {
              this.mydialog = this.$dialog.alert({
                header: '暂无报告数据',
                confirmBtn: '确定',
                closeBtn: false,
                width: '70%',
                onConfirm: ()=>{
                  this.mydialog.hide();
                  this.back();
                }
              });
            }
          
          }
        }else if (response.data.code == '5002') {
          //需要购买
          this.pageState = 1;
          this.shareUrl = response.data.data.url;
          this.thisTitle = response.data.data.title;
          this.rankMoney = response.data.data.rankMoney;
          // this.rankMoney = 1;
          
        }else {
          this.$message.error(response.data.msg);
        }
      }, error => {
        console.log(error);
      })
    },
    wxpay() {
      // var newUrl = wxpayH5(this.shareUrl, 'web', '行业对比', 1);
      var newUrl = wxpayH5(this.shareUrl, 'web', this.thisTitle, this.rankMoney);
      // console.log(newUrl);
      // document.getElementById('myIframe').src = newUrl;

      // var buyUrl = '/#/buyView?url=' + encodeURIComponent(newUrl);
      var buyUrl = '/#/buyView?url=' + encodeURIComponent(encodeURIComponent(newUrl));
      window.open(buyUrl);

      setTimeout(()=>{
        this.mydialog = this.$dialog.alert({
          header: '购买完成后请刷新',
          confirmBtn: '刷新',
          closeBtn: false,
          width: '70%',
          onConfirm: ()=>{
            // this.$router.go(0);
            location.reload();
          }
        });
      }, 1000)
    },
    //开通会员
    toSubscription() {
      this.$router.push({
        name: 'subscriptionPage'
      });
    },
    //查看报告
    viewReport() {
      this.$router.push({
        name: 'reportView',
        params: {
          shareUrl: encodeURIComponent(this.shareUrl)
        }
      });
    }
  }
}
</script>

<style scoped>
.main {
  width: 100%;
  height: 100%;
  background-color: #F2F2F2;
  --td-loading-color: #ed492e;
}

.top {
  height: 40px;
  background-color: #F4CAC6;
}
.back {
  display: inline-block;
  width: 50px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buyBox {
  height: calc(100% - 40px);
  overflow: hidden;
}
.reportImg {
  width: 100%;
  height: 100%;
}
.actionBox {
  width: calc(100% - 80px);
  text-align: center;
  padding: 0 40px;
  position: absolute;
  top: calc(50% - 200px);
}
.actionBox .text {
  font-size: 20px;
  color: #CE9151;
}
.actionBox .img {
  width: 60%;
}
.buyBtns {
  display: flex;
  justify-content: space-around;
}
.buyBtns .btn1 {
  width: 115px;
  border-radius: 24px;
  line-height: 30px;
  padding: 6px 10px;
  font-size: 17px;
  border: 0;
}
.buyBtns .btn2 {
  width: 160px;
  line-height: 30px;
  background-color: #F3BB6D;
  padding: 6px 10px;
  border-radius: 24px;
  color: #704913;
  font-size: 17px;
  border: 0;
}

.main /deep/ .t-loading__gradient-conic {
  transform: scale(1) !important;
}
</style>
