<template>
  <div class="main">

    <div class="left">

      <div>
        <div class="title1 selected1" v-if="select1==0">
          <img src="../../static/img/tab_1.png" class="titleIcon">
          <span>选择股票</span>
        </div>
        <div class="title1" v-if="select1!=0" @click="changeSelect1(0)">
          <img src="../../static/img/tab_1.png" class="titleIcon">
          <span>选择股票</span>
        </div>
        <div class="title2 selected2" v-if="select2==0">
          <img src="../../static/img/form-select_selected.svg" class="titleIcon">
          <span>行业选股</span>
        </div>
        <div class="title2" v-if="select2!=0" @click="changeSelect2(0)">
          <img src="../../static/img/form-select.svg" class="titleIcon">
          <span>行业选股</span>
        </div>
        <div class="title2 selected2" v-if="select2==1">
          <img src="../../static/img/codesandbox_selected.svg" class="titleIcon">
          <span>概念选股</span>
        </div>
        <div class="title2" v-if="select2!=1" @click="changeSelect2(1)">
          <img src="../../static/img/codesandbox.svg" class="titleIcon">
          <span>概念选股</span>
        </div>
        <div class="title2 selected2" v-if="select2==2">
          <img src="../../static/img/search_selected.svg" class="titleIcon">
          <span>搜索股票</span>
        </div>
        <div class="title2" v-if="select2!=2"  @click="changeSelect2(2)">
          <img src="../../static/img/search.svg" class="titleIcon">
          <span>搜索股票</span>
        </div>
      </div>

      <div style="margin-top: 20px;">
        <div class="title1 selected1" v-if="select1==1">
          <img src="../../static/img/tab_2.png" class="titleIcon">
          <span>选择指标</span>
        </div>
        <div class="title1" v-if="select1!=1" @click="changeSelect1(1)">
          <img src="../../static/img/tab_2.png" class="titleIcon">
          <span>选择指标</span>
        </div>
      </div>

      <div style="margin-top: 20px;">
        <div class="title1 tab3" @click="analysis">
          <img src="../../static/img/tab_3.png" class="titleIcon">
          <span>查看结果</span>
        </div>
      </div>

      <div style="margin-top: 50px;margin-left: 5px;color: #777;">
        <div :key="index" v-for="(item, index) in showSF">{{item}}</div>
        <!-- <span>计费标准：\n</span> -->
        <!-- <span>可选2~6家股票\n</span> -->
        <!-- <span>每个股票收费10元</span> -->
      </div>

    </div>

    <div class="right">

      <div class="box1" v-if="select1==0&&select2==0">
        <!-- <t-tabs value="tabSelect1" bind:click="onTabsClick1">
          <t-tab-panel label="热门行业" value="0" />
          <t-tab-panel label="申万行业" value="1" />
        </t-tabs> -->
        <div class="box2">

          <div class="industrySearchBox" v-if="boxType==0">
            <!-- <t-search placeholder="请输入行业名称" bind:change="queryIndystry" bind:clear="clearQueryIndystry" bind:blur="clearQueryIndystry"/> -->
            <t-input v-model="inputText1" placeholder="请输入行业名称" clearable size="large" @change="queryIndystry(1)" @clear="clearQueryIndystry(1)" @blur="clearQueryIndystry(1)">
              <search-icon slot="prefix-icon"/>
            </t-input>
            <div class="hotResultBox" v-if="resultBoxType==0">
              <div class="hotResultItem" :key="index" v-for="(item, index) in hotIndustryList" @click="getFundSonList(item)">
                <div class="seq">{{index+1}}</div>
                <div class="text">{{item.name}}</div>
                <img src="../../static/img/chevron-right.svg" class="goto">
              </div>
            </div>
            <div class="industrySearchResultBox" v-if="resultBoxType==1">
              <div class="hotResultItem" :key="index" v-for="(item, index) in searchFundList" @click="getFundSonList(item)">
                <div class="text" v-if="item.type=='1'">{{item.name}}<span class="smallText">（申万{{item.level}}级）</span></div>
                <div class="text" v-if="item.type=='2'">{{item.name}}<span class="smallText">（概念）</span></div>
                <img src="../../static/img/chevron-right.svg" class="goto">
              </div>
            </div>
            <div class="industrySelectResultBox" v-if="resultBoxType==2">
              <div class="backHeight">
                <div class="goback">
                  <img src="../../static/img/chevron-left.svg" class="goto" @click="goback">
                  <span @click="goback">返回</span>
                  <div style="flex: 1;"></div>
                  <div style="color: #4a86e8;" @click="clearOption">清空已选</div>
                </div>
              </div>
              <div class="industryResultItem" :key="index" v-for="(item, index) in fundSonStockList" @click="addOption(item)">
                <div class="text">{{item.comName}}</div>
                <div class="text">{{item.comCode}}</div>
                <t-checkbox color="#ed492e" v-model="item.check" @change="addOption(item)"/>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="box1" v-if="select1==0&&select2==1">
        <!-- <t-tabs value="tabSelect1" bind:click="onTabsClick1">
          <t-tab-panel label="热门概念" value="0" />
          <t-tab-panel label="全部概念" value="1" />
        </t-tabs> -->
        <div class="box2">

          <div class="conceptSearchBox" v-if="boxType==0">
            <!-- <t-search placeholder="请输入概念名称" bind:change="queryIndystry" bind:clear="clearQueryIndystry" bind:blur="clearQueryIndystry"/> -->
            <t-input v-model="inputText2" placeholder="请输入概念名称" clearable size="large" @change="queryIndystry(2)" @clear="clearQueryIndystry(2)" @blur="clearQueryIndystry(2)">
              <search-icon slot="prefix-icon"/>
            </t-input>
            <div class="hotResultBox" v-if="resultBoxType==0">
              <div class="hotResultItem" :key="index" v-for="(item, index) in hotConceptList" @click="getFundSonList(item)">
                <div class="seq">{{index+1}}</div>
                <div class="text">{{item.name}}</div>
                <img src="../../static/img/chevron-right.svg" class="goto">
              </div>
            </div>
            <div class="conceptSearchResultBox" v-if="resultBoxType==1">
              <div class="hotResultItem" :key="index" v-for="(item, index) in searchFundList" @click="getFundSonList(item)">
                <div class="text" v-if="item.type=='1'">{{item.name}}<span class="smallText">（申万{{item.level}}级）</span></div>
                <div class="text" v-if="item.type=='2'">{{item.name}}<span class="smallText">（概念）</span></div>
                <img src="../../static/img/chevron-right.svg" class="goto">
              </div>
            </div>
            <div class="conceptSelectResultBox" v-if="resultBoxType==2">
              <div class="backHeight">
                <div class="goback">
                  <img src="../../static/img/chevron-left.svg" class="goto" @click="goback">
                  <span @click="goback">返回</span>
                  <div style="flex: 1;"></div>
                  <div style="color: #4a86e8;" @click="clearOption">清空已选</div>
                </div>
              </div>
              <div class="conceptResultItem" :key="index" v-for="(item, index) in fundSonStockList" @click="addOption(item)">
                <div class="text">{{item.comName}}</div>
                <div class="text">{{item.comCode}}</div>
                <t-checkbox color="#ed492e" v-model="item.check" @change="addOption(item)"/>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="box1" v-if="select1==0&&select2==2">
        <div class="box2">
          <div class="stockSearchBox">
            <!-- <t-search model:value="inputText" placeholder="输入公司名称" bind:change="search" bind:clear="searchClear"/> -->
            <t-input v-model="inputText3" placeholder="请输入股票名称、代码查询" class="searchBox" clearable size="large" @change="search" @clear="searchClear">
              <search-icon slot="prefix-icon"/>
            </t-input>
            <div class="stockSelectResultBox">
              <div class="stockResultItem" :key="index" v-for="(item, index) in fundSonStockList" @click="addOption(item)">
                <div class="text">{{item.comName}}</div>
                <div class="text">{{item.comCode}}</div>
                <t-checkbox color="#ed492e" v-model="item.check" @change="addOption(item)"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="indicatorBox" v-if="select1==1">
        <div class="indicatorItem" :key="index" v-for="(item, index) in analysisIndicatorsList" @click="selectIndicator(index)">
          <div class="text" v-if="analysisIndex==index" style="color: #ed492e;">{{item.modelName}}</div>
          <div class="text" v-if="analysisIndex!=index">{{item.modelName}}</div>
          <t-checkbox color="#ed492e" v-model="item.check" @change="selectIndicator(index)"/>
        </div>
      </div>

    </div>

    <div class="bottom">
      <div class="selectedStock" @click="showOptionPopup">
        <img src="../../static/img/chevron-up-circle-outline.svg" class="bottomIcon">
        <div>已 选 <span>{{optionlistLength}}</span> 项</div>
      </div>
      <div class="history" @click="showHistoryPopup">
        <img src="../../static/img/clock-time-nine-outline.svg" class="bottomIcon">
        <div>历 史 记 录</div>
      </div>

      <t-drawer :visible="optionVisible" placement="bottom" :header="false" :footer="false">
        <div class="bottomPopup9">
          <div class="bottomPopupTop">
            <div style="font-size: 23px;">已选股票</div>
            <div>（最多可选6家股票）</div>
            <div style="flex: 1;text-align: right;color: #4a86e8;" @click="clearOption">全部清空</div>
          </div>
          <div class="bottomPopupStock">
            <div style="font-size: 17px;height: 80px;line-height: 80px;" v-if="optionlistLength==0">无自选股票</div>
            <div class="bottomPopupStockItem" v-for="(item, index) in optionList" :key="index">
              <div>{{item.comName}}</div>
              <div>{{item.comCode}}</div>
              <img src="../../static/img/close-circle.svg" class="bottomIcon" @click="addOption(item)">
            </div>
          </div>
          <div class="bottomPopupClose" @click="closeOptionPopup">
            <img src="../../static/img/chevron-down-circle-outline.svg" class="bottomIcon">
            <div>已 选 <span>{{optionlistLength}}</span> 项</div>
          </div>
        </div>
      </t-drawer>

      <t-drawer :visible="historyVisible" placement="bottom" :header="false" :footer="false">
        <div class="bottomPopup9">
          <div class="bottomPopupTop">
            <div style="font-size: 23px;">历史记录</div>
          </div>
          <div class="bottomPopupHistory">
            <div style="font-size: 17px;height: 80px;line-height: 80px;text-align: center;" v-if="recordList.length==0">暂无数据</div>
            <template v-if="recordList.length>0">
              <div class="bottomPopupHistoryItem" v-for="(item, index) in recordList" :key="index">
                <div class="title">{{item.param3}}</div>
                <img src="../../static/img/report.svg" class="bottomIcon" @click="viewHistory(item)">
              </div>
            </template>
          </div>
        </div>
      </t-drawer>
    </div>

  </div>
</template>

<script>
import $ from 'jquery';
import { SearchIcon } from 'tdesign-icons-vue';
import industryData from '../../static/js/industryData';
import { sendRequestCompany2, sendRequestIndustry } from '../../static/js/util';

export default {
  components: { SearchIcon },
  data() {
    return {

      // satoken: null,
        
      select1: 0,
      select2: 0,

      //右侧框的类型
      boxType: 0,
      tabSelect1: 0,
      tabSelect2: 0,
      //右侧列表框
      resultBoxType: 0,

      //申万行业、概念列表
      allIndustryData: [],
      //股票列表
      searchDataList: [],
      //搜索选股
      inputText1: '',
      inputText2: '',
      inputText3: '',
      //热门行业
      hotIndustryList: [],
      //热门概念
      hotConceptList: [],
      //申万行业结果
      shenwanIndustryList: [],
      //行业，概念搜索结果
      searchFundList: [],
      //行业，概念下股票列表
      fundSonStockList: [],

      //申万行业
      areaVisible: false,
      areaText: '选择行业',
      firstIndustry: [],
      firstIndex: 0,
      secondIndustry: [],
      secondIndex: 0,
      thirdIndustry: [],
      thirdIndex: 0,

      //指标列表
      analysisIndicatorsList: [],
      analysisIndex: -1,
      //计费标准
      showSF: [],

      //已选股票
      optionList: [],
      //已选股票数量
      optionlistLength: 0,
      optionVisible: false,

      //历史记录
      historyVisible: false,
      recordList: []

    };
  },
  mounted() {
    // this.satoken = localStorage.getItem('satoken');
    //全部行业
    this.allIndustryData = industryData.data;
    //热门行业
    this.getHotIndustryList();
    //热门概念
    this.getHotConceptList();
    //指标列表
    this.getAnalysisIndicatorsList();
    //加载股票列表
    this.getSearchData();
  },
  methods: {
    //热门行业
    getHotIndustryList() {
      let url = 'company/hotInduList?topSize=10'
      sendRequestIndustry(url, 'get', null, (response)=>{
        // console.log(response)
        this.hotIndustryList = response.data.data;
      }, error => {
        console.log(error);
      })
    },
    //获取热门概念列表
    getHotConceptList() {
      let url = 'company/hotGnList?topSize=10'
      sendRequestIndustry(url, 'get', null, (response)=>{
        // console.log(response)
        this.hotConceptList = response.data.data;
      }, error => {
        console.log(error);
      })
    },
    //读取缓存的公司列表，没有再请求
    getSearchData() {
      var readNum = localStorage.getItem('searchDataRead');
      if(readNum==null) {
        readNum = -1;
      }else {
        readNum = parseInt(readNum);
      }

      if(readNum>0 && readNum<100) {
        var list = localStorage.getItem('searchDataList');
        this.searchDataList = JSON.parse(list);
        localStorage.setItem('searchDataRead', readNum+1);
      }else {
        let url = '/company/comList'
        sendRequestCompany2(url, 'get', null, (res) => {
          this.searchDataList = res.data;
          localStorage.setItem('searchDataList', JSON.stringify(res.data));
          localStorage.setItem('searchDataRead', 1);
        }, (err) => {
          console.log(err)
        });
      }
    },
    //指标列表
    getAnalysisIndicatorsList() {
      let url = 'company/induInterfaceNames'
      sendRequestIndustry(url, 'get', null, (response)=>{
        // console.log(response)
        this.analysisIndicatorsList = response.data.data.data;
        this.showSF = response.data.data.text;
      }, error => {
        console.log(error);
      })
    },

    //切换左侧大标题
    changeSelect1(index) {
      this.select1 = index;
      if(index==0) {
        this.select2 = 0;
      }else {
        this.select2 = -1;
      }
      this.resultBoxType = 0;
      this.fundSonStockList = [];
      // this.onTabsClick1(0)
    },
    //切换左侧小标题
    changeSelect2(index) {
      this.select1 = 0;
      this.select2 = index;
      this.resultBoxType = 0;
      this.fundSonStockList = [];
      // this.onTabsClick1(0)
    },
    //切换tab
    // onTabsClick1(index) {
    //   this.tabSelect1 = index;
    //   this.resultBoxType = 0;
    //   this.fundSonStockList = [];
    //   if(index==0) {
    //     this.boxType = 0;
    //   }else if(index==1) {
    //     this.boxType = 1;
    //   }
    // },

    //搜索行业
    queryIndystry(type) {
      var keyWord;
      if(type==1){
        keyWord = this.inputText1;
      }else if(type==2) {
        keyWord = this.inputText2;
      }
      var reg = new RegExp(keyWord);
      var arr = [];
      if (this.allIndustryData == null) {
        this.searchFundList = arr;
        this.resultBoxType = 1;
      }
      for (var i = 0; i < this.allIndustryData.length; i++) {
        if (reg.test(this.allIndustryData[i].name)) {
          arr.push(this.allIndustryData[i]);
          if (arr.length > 10) {
            break;
          }
        }
      }
      this.searchFundList = arr;
      this.resultBoxType = 1;
    },
    //清除行业搜索
    clearQueryIndystry(type) {
      var keyWord;
      if(type==1){
        keyWord = this.inputText1;
      }else if(type==2) {
        keyWord = this.inputText2;
      }
      if(keyWord=='') {
        this.searchFundList = [];
        this.fundSonStockList = [];
        this.resultBoxType = 0;
      }
    },

    //获取股票列表
    getFundSonList(item) {
      if(item.type=='1') {//行业
        var industryCode = item.code;
        var type = item.level;
        let url = 'company/induComList/' + industryCode + '/' + type;
        // let url = 'company/induComMiniList/' + industryCode + '/' + type;
        sendRequestIndustry(url, 'get', null, (response)=>{
          var temp = response.data.data;
          temp.forEach((val)=>{
            this.optionList.forEach((val2)=>{
              if(val.comCode==val2.comCode) {
                val.check = true;
              }
            })
          })
          // console.log(response)
          this.fundSonStockList = temp;
          this.resultBoxType = 2;
        }, error => {
          console.log(error);
        })
      }else if(item.type=='2') {//概念
        var conceptName = item.name;
        let url = 'company/gnComList/' + conceptName;
        // let url = 'company/gnComMiniList/' + conceptName;
        sendRequestIndustry(url, 'get', null, (response)=>{
          var temp = response.data.data;
          temp.forEach((val)=>{
            this.optionList.forEach((val2)=>{
              if(val.comCode==val2.comCode) {
                val.check = true;
              }
            })
          })
          // console.log(response)
          this.fundSonStockList = temp;
          this.resultBoxType = 2;
        }, error => {
          console.log(error);
        })
      }
    },
    
    //股票列表返回
    goback() {
      if(this.searchFundList.length>0) {
        this.resultBoxType = 1;
        this.fundSonStockList = [];
      }else {
        this.resultBoxType = 0;
        this.fundSonStockList = [];
      }
    },

    //添加股票，刷新勾选页
    addOption(item) {
      var temp = this.optionList;
      var num = this.optionlistLength;
      var sonList = this.fundSonStockList;
      
      //增加还是删除
      var isAdd= true;
      this.optionList.forEach((val)=>{
        if(val.comCode==item.comCode) {
          isAdd = false;
        }
      })

      //多选
      if(isAdd) {
        //最多6个
        if(num==6) {
          this.$message.warning({
            content: '最多选择6家股票',
            duration: 1000,
            zIndex: 1001
          });
          //修改股票列表的勾选
          sonList.forEach((val)=>{
            val.check = false;
            temp.forEach((val2)=>{
              if(val.comCode===val2.comCode) {
                val.check = true;
              }
            })
          })
          this.fundSonStockList = sonList;
          return;
        }

        // item.comCode = '';
        temp.push(item);
        num += 1;
        this.optionList = temp;
        this.optionlistLength = num;
      }else {
        var index = temp.findIndex((stock)=>{
          return stock.comCode === item.comCode
        })
        temp.splice(index, 1);
        num -= 1;
      }

      //修改股票列表的勾选
      sonList.forEach((val)=>{
        val.check = false;
        temp.forEach((val2)=>{
          if(val.comCode===val2.comCode) {
            val.check = true;
          }
        })
      })

      this.optionList = temp;
      this.optionlistLength = num;
      this.fundSonStockList = sonList;
    },
    //清空选择的股票
    clearOption() {
      var sonList = this.fundSonStockList;

      //修改股票列表的勾选
      sonList.forEach((val)=>{
        val.check = false;
      })

      this.optionList = [];
      this.optionlistLength = 0;
      this.fundSonStockList = sonList;
    },

    //展示已选股票
    showOptionPopup() {
      this.optionVisible = true;
      $('.t-drawer__mask').click(()=>{
        this.optionVisible = false;
      });
    },
    //关闭已选股票
    closeOptionPopup() {
      this.optionVisible = false;
    },

    //打开历史记录
    showHistoryPopup() {
      this.getRecordList();
      this.historyVisible = true;
      $('.t-drawer__mask').click(()=>{
        this.historyVisible = false;
      });
      $('.t-drawer__body').click(()=>{
        this.historyVisible = false;
      });
    },
    //关闭历史记录
    closeHistoryPopup() {
      this.historyVisible = false;
    },

    //搜索选股
    search() {
      var text = this.inputText3;
      // console.log(text)
      if (text !== '' && text.length > 0) {
        let isNumber = !isNaN(parseFloat(text)) && isFinite(text);
        let ret = isNumber == true ? this.QueryCode(this.searchDataList, text) : this.QueryName(this.searchDataList, text);
        if (ret != null) {
          this.fundSonStockList = ret;
        }
      } else {
        this.fundSonStockList = [];
      }
    },
    QueryName(list, keyWord) {
      var reg = new RegExp(keyWord);
      var arr = [];
      if (list == null) {
        return arr;
      }
      for (var i = 0; i < list.length; i++) {
        if (reg.test(list[i].comName)) {
          arr.push(list[i]);
          if (arr.length > 10) {
            break;
          }
        }
      }
      return arr;
    },
    QueryCode(list, keyWord) {
      var reg = new RegExp(keyWord);
      var arr = [];
      if (list == null) {
        return arr;
      }
      for (var i = 0; i < list.length; i++) {
        if (reg.test(list[i].comCode)) {
          arr.push(list[i]);
          if (arr.length > 10) {
            break;
          }
        }
      }
      return arr;
    },
    //搜索选股框清除
    searchClear() {
      this.fundSonStockList = [];
    },

    //选择指标
    selectIndicator(index) {
      this.analysisIndex = index;
      for(var i=0; i<this.analysisIndicatorsList.length; i++){
        if(i!=index) {
          this.analysisIndicatorsList[i].check = false;
        }else {
          this.analysisIndicatorsList[i].check = true;
        }
      }
      
    },

    //查看结果
    analysis() {
      if(this.optionList.length<2) {
        this.$message.warning({
          content: '请选择至少两个股票',
          duration: 1000,
          zIndex: 1001
        });
        return;
      }else if(this.analysisIndex<0) {
        this.$message.warning({
          content: '请选择一种分析指标',
          duration: 1000,
          zIndex: 1001
        });
        return;
      }else {
        var fundList = [];
        var typeList = [];
        this.optionList.forEach((val)=>{
          fundList.push(val.comCode)
        })
        typeList.push(
          this.analysisIndicatorsList[
            this.analysisIndex
          ].type
        )
        //跳转比较文章
        // wx.navigateTo({
        //   url: '/pages/induCompareReport/induCompareReport'
        //     + '?fundList=' + JSON.stringify(fundList)
        //     + '&typeList=' + JSON.stringify(typeList)
        // })
        this.$router.push({
          name: 'induCompareBuy',
          query: {
            fundList: fundList,
            typeList: typeList
          }
        });
      }
    },

    //获取历史记录
    getRecordList() {
      let url = 'company/viewInduLinkLog'

      sendRequestIndustry(url, 'get', null, (response)=>{
        // console.log(response.data);
        this.recordList = response.data.data;
      }, error => {
        console.log(error);
      })
    },
    //查看历史
    viewHistory(item) {
      // console.log(item)
      //跳转比较文章
      // wx.navigateTo({
      //   url: '/pages/induCompareReport/induCompareReport'
      //     + '?fundList=' + item.param1
      //     + '&typeList=' + item.param2
      // })
      this.$router.push({
        name: 'induCompareBuy',
        query: {
          fundList: JSON.parse(item.param1),
          typeList: JSON.parse(item.param2)
        }
      });
    }
  }
}
</script>

<style>
body {
  --td-brand-color: #D84A36;
  --td-brand-color-focus: #F4CAC6;
}
</style>
<style scoped>
.main {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  background-color: #F2F2F2;
}

.left {
  width: 35%;
}
.title1 {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 54px;
  padding-left: 5px;
  background-color: #fcfcfc;
  color: #111;
  font-size: 18px;
}
.title2 {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 54px;
  padding-left: 8px;
  color: #8a8a8a;
  font-size: 17px;
}
.titleIcon {
  width: 32px;
  height: 32px;
  /* background-color: coral; */
  margin-right: 5px;
}

.selected1, .selected2 {
  color: #ed492e;
  font-weight: 600;
}
.tab3 {
  background-color: #ec6b6d;
  color: #fff;
}

.right {
  width: 65%;
  height: 100%;
  background-color: #fff;
}
.box1 {
  height: 100%;
}
.box2 {
  /* height: calc(100% - 96rpx); */
  height: 100%;
}
.industrySearchBox, .conceptSearchBox, .stockSearchBox {
  padding: 5px;
  height: calc(100% - 10px);
}
.hotResultBox, 
.industrySearchResultBox, .industrySelectResultBox,
.conceptSearchResultBox, .conceptSelectResultBox,
.stockSelectResultBox {
  background-color: #F2F2F2;
  height: calc(100% - 40px - 50px);
  padding: 10px 7px 40px 7px;
  margin-top: 5px;
  overflow-y: auto;
}
.hotResultItem, .shenwanResultItem, 
.industryResultItem, .conceptResultItem,
.stockResultItem {
  display: flex;
  align-items: center;
  padding: 14px;
  border-radius: 6px;
  margin-bottom: 10px;
  background-color: #fff;
}
.seq {
  width: 25px;
  color: #9a9a9a;
}
.text {
  font-size: 18px;
  flex: 1;
  color: #6a6a6a;
}
.goto {
  width: 20px;
  height: 20px;
  color: #ed492e;
}
.backHeight {
  height: 26px;
}
.goback {
  width: calc(65% - 24px);
  height: 30px;
  margin-top: -11px;
  padding-top: 4px;
  color: #ed492e;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  position: absolute;
  background-color: #F2F2F2;
  z-index: 100;
}
.hotResultBox .hotResultItem:nth-child(1) .seq{
  color: #e91e63;
}
.hotResultBox .hotResultItem:nth-child(2) .seq{
  color: #ff9b05;
}
.hotResultBox .hotResultItem:nth-child(3) .seq{
  color: #ffe69f;
}

.smallText {
  font-size: 15px;
  color: #999;
}

.indicatorBox {
  height: calc(100% - 5px);
  background-color: #F2F2F2;
  margin-left: 5px;
  padding-top: 5px;
}
.indicatorItem {
  display: flex;
  align-items: center;
  padding: 14px;
  /* border-radius: 6px; */
  margin-bottom: 10px;
  background-color: #fff;
}
.indicatorItem .text {
  padding-right: 5px;
}

.bottom {
  width: 100%;
  position: absolute;
  bottom: 60px;
  display: flex;
  justify-content: space-evenly;
  background-color: #F2F2F2;
}
.selectedStock, .history {
  width: 43%;
  display: flex;
  align-items: center;
  margin-top: 3px;
  padding: 5px 0;
  font-size: 17px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  justify-content: center;
  background-color: #fff;
}
.bottomIcon {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}
.bottomPopup {
  width: calc(100% - 16px);
  padding: 8px;
  background-color: #F2F2F2;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.bottomPopupTop {
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  height: 30px;
  color: #333;
  padding: 10px 10px 5px 10px;
}
.bottomPopupStock {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bottomPopupStockItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 18px;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}
.bottomPopupClose {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.bottomPopupHistory {
  max-height: 500px;
  background-color: #fff;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
  overflow-y: auto;
}
.bottomPopupHistoryItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}
.bottomPopupHistoryItem .title {
  color: #222;
  padding-left: 10px;
  width: calc(100% - 50px);
}
.bottomPopupHistoryItem .bottomIcon {
  width: 40px;
}

.main /deep/ .t-drawer__content-wrapper {
  height: auto !important;
}
.main /deep/ .t-drawer__body {
  padding: 8px;
  background-color: #F2F2F2;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
</style>
