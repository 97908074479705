<template>
  <div class="main">

    <div class="top">
      <div class="back" @click="back"><ChevronLeftIcon />返回</div>
    </div>

    <div style="margin-top: 20px;">
      <div style="font-size: 15px;color: #595959;text-align: center;">享受所有核心权益：</div>
      <div style="font-size: 15px;color: #595959;text-align: center;">报告畅读/数据畅查/更高权限/深度数据</div>
      <div class="sumBox">

        <div>
          <div class="sumBoxSelected" v-if="memberType==1">
            <div>月费</div>
            <div>618</div>
            <div>￥</div>
            <div>超值价格!</div>
          </div>
          <div class="sumBoxUnselected" v-else @click="changeMember(1)">
            <div>月费</div>
            <div>618</div>
            <div></div>
          </div>
        </div>

        <div>
          <div class="sumBoxSelected" v-if="memberType==2">
            <div>季费</div>
            <div>1580</div>
            <div>￥</div>
          </div>
          <div class="sumBoxUnselected" v-else @click="changeMember(2)">
            <div>季费</div>
            <div>1580</div>
            <div></div>
          </div>
        </div>

        <div>
          <div class="sumBoxSelected" v-if="memberType==3">
            <div>年费</div>
            <div>5180</div>
            <div>￥</div>
          </div>
          <div class="sumBoxUnselected" v-else @click="changeMember(3)">
            <div>年费</div>
            <div>5180</div>
            <div></div>
          </div>
        </div>

      </div>
    </div>

    <div class="equityLine">
      <div></div>
      <div></div>
      <div>碧湾会员权益对比</div>
      <div></div>
      <div></div>
    </div>

    <div class="equityTable">
      <div class="equityTableHead">
        <div>
          <div><img src="../../static/img/equity.svg" class="equityIcon"></div>
          <div>权益</div>
        </div>
        <div>VIP用户</div>
        <div>普通用户</div>
      </div>
      <div class="equityTableBody">
        <div>
          <div>季报分析</div>
          <div style="background: #FCF5ED;">免费</div>
          <div>25元/篇</div>
        </div>
        <div>
          <div style="background: #F9F9F8;">中报分析</div>
          <div style="background: #FFFDF8;">免费</div>
          <div style="background: #F9F9F8;">50元/篇</div>
        </div>
        <div>
          <div>年报分析</div>
          <div style="background: #FCF5ED;">免费</div>
          <div>50元/篇</div>
        </div>
      </div>
    </div>

    <div class="noteView">
      <span>另外赠送：2024年12月31日前免费查看行业和公司比较深度数据</span>
      <span>（时限等同于VIP时间，如月卡可免费看一个月，另免费赠送的可看时间截止时间为2024年12月31日）</span>
    </div>

    <div class="footBox">
      <div class="agreement">
        <div><t-radio color="#F8BB6A" :allowUncheck="true" :checked="read" @click="changeRead"/></div>
        <div>我已经阅读</div>
        <div @click="showPopup">《<span style="border-bottom: 1px solid #BD3124;">会员服务协议</span>》</div>
      </div>
      <div class="subBtn" @click="buyElement">立即购买</div>
    </div>

    <t-drawer :visible="visible" placement="bottom" :header="false" :footer="false">
      <div class="scrollView" @click="closePopup">
        <div class="agreementBox">
          <div style="margin-top: 10px;font-weight: 600;font-size: 18px;text-align: center">
            碧湾会员服务协议
          </div>
          <div style="margin-top: 10px;font-size: 14;text-align: right">
            本版发布日期：2023年11月2日
          </div>
          <div style="margin-top: 10px;font-size: 14;text-align: right">
            生效日期：2023年11月2日
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            导言
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            本协议是杭州碧湾信息技术有限公司（下称“碧湾”）之间关于您成为碧湾会员及使用碧湾提供的会员服务（下称“会员服务”）所订立的有效契约，具有法律效力。“会员服务”是指碧湾为会员提供的会员权益； “会员”是指完成了成为碧湾会员的所有程序且在会员服务期限内遵守相关服务使用规则下享受会员服务的自然人。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            碧湾在此善意的提醒您，本协议中与您的权益（可能）存在重大关系的条款（包括相关免除碧湾责任的条款、限制您权利的条款、约定的争议解决方式及司法管辖条款等）。您应当在确认充分阅读本协议下划线条款，明确理解了条款的前提下使用碧湾会员服务；如果您认为前述条款可能会导致您的部分或全部权利或利益受损，您应当在使用碧湾会员服务之前再次对前述条款进行阅读，并确保您已同意前述条款的有效性的前提下，使用碧湾会员服务。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            本协议构成您获取和使用会员服务之必要前提条件，您应当在仔细阅读并同意本协议的全部内容后获取和使用会员服务。如您不同意本协议的任一内容，您应立即停止下一步操作。您的使用行为（包括但不限于支付行为/接受赠与、或完成了成为会员的全部程序而在此过程中未向碧湾提出关于本协议的任何异议、或使用会员服务等）将视为同意本协议的全部内容。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            如您在阅读本协议或获取/使用会员服务的过程中有任何疑惑或其他相关事宜的，碧湾为您提供了反馈渠道，详见“个人中心-关于我们-意见反馈”，碧湾会尽快为您作出解答。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            未成年人应在监护人监护、指导下阅读并决定是否同意本协议。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            1. 服务说明
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            1.1 碧湾会员服务涉及的碧湾产品/服务的所有权及相关知识产权均归碧湾所有或拥有合法授权（经合法渠道取得的实体产品所有权、账号下归属于会员的个人财产、碧湾另有说明的其他权益除外）。碧湾授予会员一项个人的、非独家的、不可转让的、非商业用途的、可撤销的、有期限的使用许可，即会员仅享有以上产品/服务的有限使用权。碧湾所提供的服务将按照其服务条款和操作规则严格执行。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            1.2 本协议为《用户服务协议》、《碧湾隐私政策》的补充协议，是其不可分割的一部分；本协议如与前述协议发生冲突的，以本协议为准。碧湾会员中心已有和未来不时发布的与会员服务相关的协议、各类公告、页面说明和规范流程、问题解答等内容均构成本协议的一部分，与本协议具有同等法律效力。碧湾与会员均都应当受本协议以及《用户服务协议》、《碧湾隐私政策》的约束。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            1.3 碧湾有权基于自身运营策略单方面决定和调整会员服务内容（如特权修改等）、服务方式。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            1.4 如在碧湾平台展示了碧湾会员服务以外的其他付费内容的，当您需要时，由对应的其他收费体系与您签署相应的服务协议、向您提供相应的服务并收取费用
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            2. 成为会员的程序
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            2.1 碧湾为您提供了多种成为会员的途径，而每种途径对应的成为碧湾会员的程序，碧湾会在相关页面上为您做出每一步的明确操作指引。如您使用第三方账号登录的，您应保证第三方账号和所含信息的稳定性、真实性、准确性、合法性和有效性，如因第三方账号原因导致您无法登录的，您应与第三方联系进行解决，与碧湾无关，碧湾不因此向您退还您已交纳的会员服务费用且不予进行任何形式的补偿/赔偿。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            如您使用个人信息注册成为碧湾用户并成为会员的，您必须保证您提供的个人信息真实、准确、合法且有效，且当您的前述个人信息发生变化时，您应及时予以更新，否则由此造成的会员权利不能全面有效行使或无法获取碧湾最新通知的损失和责任均由您自行承担。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            2.2 在您阅读和同意本协议全部内容、支付了会员费用（如收费）并完成了成为碧湾会员的其他所有程序后，您即取得碧湾会员资格，在您持续遵守碧湾相关服务使用规则下，即能够在会员服务期限内享受会员服务。如您不同意前述任一流程或内容的，您应立即停止下一步操作。一旦您完成了成为会员的所有程序，即视为您对所有流程及其内容无异议。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            2.3 您知悉并同意，会员服务系网络商品和虚拟商品，采用先收费后服务的方式，会员费用是您所购买的会员服务所对应的网络商品价格，而非预付款或者存款、定金、储蓄卡等性质，会员服务一经开通后不可转让或退款（如因会员服务存在重大瑕疵导致您完全无法使用等碧湾违约情形、本协议另有约定、法律法规要求必须退款的或经碧湾判断后认为可以退款等除外）。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            2.4 碧湾会员账号的所有权归碧湾所有，会员仅在碧湾授权范围内拥有碧湾账号的有限使用权。您应自行负责妥善且正确地保管、使用、维护您的会员账户（包括账户信息及密码），您应对您的账户信息和密码采取必要和有效的保密措施。您知悉并同意，为保护您的账号安全，防止账号被盗等情况发生，碧湾有权在您使用时采用一种或多种身份验证方式，如未成功通过验证的，碧湾有合理理由怀疑您的账号出现被盗等不安全情况，则碧湾有权视情节严重情况而决定是否中止向您继续提供会员服务并采取进一步措施，且对此，碧湾无需承担任何责任。非碧湾原因致使您账户信息被篡改、密码泄露以及其他因保管、使用、维护不当而造成的损失，您应自行承担责任，碧湾在法律允许的最大范围内无须承担与此有关的任何责任。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            3. 会员权益及服务期限
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            3.1 碧湾会员权益，即碧湾为会员提供的专门服务，包括公司数据查看、报告分析生成等，具体以碧湾平台上VIP特权页面的说明或碧湾实际提供为准，本协议项下的会员权益名称与页面的说明不一致的，不影响会员所实际享受的会员权益的内容。碧湾有权基于自身运营策略变更全部或部分会员权益、适用的用户设备终端。就前述权益调整，碧湾将在相应服务页面或以其他合理方式进行通知，您也可通过碧湾官方渠道查询最新的会员权益内容。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            3.2 会员服务的服务期限以您自行选择并支付相应会员费用对应的服务期限为准，自您成为会员之时起算。该期限不因您未使用等情况而延长。当会员服务期限到期后，碧湾将停止继续向您提供会员服务；但如您开通自动续费服务且您在服务期限到期前续费成功的，会员服务期限将在原服务期限的基础上顺延。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            4. 会员服务费用相关（含自动续费
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            4.1 碧湾提供的会员服务为收费服务，使用该服务前需要支付相应的服务费用。除此之外，您也可以通过营销活动、抵扣券等碧湾认可的途径获取该服务。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            4.2 您可通过碧湾实际接受的付费方式完成会员费用的支付，如银行卡支付、第三方支付等。您知悉并同意，会员服务的付费方式为代收费运营商托收的付款方式，您通过此种付费方式付费可能存在一定的商业风险（如不法分子利用您账户或银行卡等有价卡等进行违法活动），该等风险可能会给您造成相应的经济损失，您应自行承担向侵权方追究侵权责任和追究责任不能的后果。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            5. 双方权利及限制
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            会员和碧湾均有权享受/有义务遵守法律法规的规定的和本协议约定的权利/义务。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            5.1 在会员服务期限内，您有权选择参加由碧湾组织的活动并享有由碧湾提供的各项优惠。具体活动及优惠的内容及规则以碧湾实际提供为准。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            5.2 碧湾可能会根据实际运营情况对不同阶段已经开通且持续有效的会员给予延期、权益升级等方面的优惠，具体优惠政策以碧湾在相关服务页面通知的内容为准。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            5.3 如您触发上述规则的，碧湾有权视情节作出限制登录、限制使用、自动封禁违规账号或其他处理。若您的碧湾账号被封禁的，碧湾有权不经通知直接收回该账号使用权、取消该账号会员资格，并不予退还会员服务费且不予支付任何形式的赔偿/补偿。因此造成的损失和责任由您自行承担。
            您知悉并确认，因苹果应用商店渠道允许非注册用户通过游客方式购买会员服务，会员服务将会绑定您购买会员时所用的设备，会员服务也将仅可在绑定设备上使用，如您需在其他设备中使用，请按照页面操作指引绑定您的碧湾注册账号，账号绑定完成后将受前款约定的登录限制规则的约束。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            5.4 会员服务期限中包含碧湾解决故障、服务器维修、调整、升级等所需用的合理时间，对上述情况所需用的时间或造成的任何损失（如有），碧湾不予任何形式的赔偿/补偿，但碧湾会尽可能将影响降至最低。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            5.5 您成为会员后，可能会因您使用的软件版本、设备、操作系统等不同以及其他第三方原因导致实际可使用的具体权益或服务内容有差别，由此可能给您带来的不便，您表示理解且不予追究或豁免碧湾的相关责任。碧湾建议您可以通过升级应用程序或操作系统版本、更换使用设备等方式来尝试解决，或者直接通过本协议提供的方式与碧湾联系进行解决。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            5.6 会员账号下的所有行为视为您本人的行为；而且您应对自身在获取和使用会员服务时的一切行为（无论是否故意）负全部责任。碧湾享有对您在获取和使用会员服务及其相关活动过程中的监督、提示的权利，如您的行为违反有关法律法规或违反本协议的约定，碧湾享有要求您纠正及追究您责任等权利。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            5.7 您知悉并同意，您应遵循碧湾官方渠道公布的流程、平台规范等获取/使用会员资格和会员服务、以及参与面向会员举办的相关活动，不得自行或协助他人通过以下任一方式获取/使用会员资格和会员服务、或参与相关活动。否则，碧湾有权不经通知拒绝您的申请或撤销涉及的会员资格、拒绝或终止为您提供服务，并不退还会员服务费且不予支付任何形式的任何赔偿/补偿，同时碧湾有权追究您的相关法律责任：
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            （1） 未经碧湾明确授权，以商业性或其他非个人使用等目的为自己或他人获取、使用碧湾会员服务；（2） 未经碧湾明确授权，通过机器人软件、蜘蛛软件、爬虫软件等任何自动程序、脚本、软件等方式获取会员服务、内容、数据；（3） 未经碧湾明确授权，通过技术手段对服务内容、服务期限、消费金额、交易状态等信息进行修改；（4） 将会员服务通过非碧湾明确授权的方式在碧湾账号之间转移，或未经碧湾明确授权而赠与、借用、租用、销售、转让会员资格；（5） 对碧湾用于保护会员服务的任何安全措施技术进行破解、更改、反操作、篡改或其他破坏；未经碧湾事先书面同意而删除会员服务内容上的任何所有权或知识产权声明或标签；（6） 未经碧湾明确授权，采用收费或免费的方式，在任何公开场合全部或部分展示会员服务内容（但如您的上述行为不构成侵权的除外）；（7） 通过不正当手段或以违反诚实信用原则的方式（如利用规则漏洞、利用系统漏洞、滥用会员身份、黑色产业、投机等违背碧湾提供服务/举办活动的初衷的方式）；（8） 通过侵犯碧湾或他人合法权益的方式；（9） 其他未经碧湾明示授权许可或违反本协议、法律法规或监管政策的行为。
            碧湾在此声明：任何未经碧湾明示授权而销售、转让碧湾会员资格的行为属于非法销售、非法转让，碧湾有权追究其法律责任。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            5.8 您知悉并同意，如您存在或碧湾经独立判断后认为您存在任何违反国家法律法规或监管政策、违反本协议或有损碧湾或/及其关联公司的声誉、利益的行为的，碧湾有权独立决定采取以下一项或多项处理措施：
            （1） 如本协议对此行为有单独条款约定处理方式的，按照该条款处理；（2） 无需通知您而采取一种或多种措施制止您的行为及行为产生的后果，如删除/屏蔽相关链接或内容、限制/取消您的账号/账户使用权限等；（3） 无需通知您而中断或终止部分或全部会员服务，且您已交纳的会员服务费用将不予退还且不获得任何形式的补偿/赔偿；（4） 如您的行为使碧湾或/及其关联公司遭受任何损失的，您应当承担全部损失赔偿责任并在碧湾要求的时限内完成费用支付。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            6. 协议的变更
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            6.1 碧湾有权变更本协议内容，对此，碧湾将在相应页面或以其他合理方式进行通知。通知一经发送即视为已成功通知您。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            6.2 如协议发生变更，但您不同意变更的内容的，您有权选择停止使用会员服务。如您在变更后的协议生效后仍继续使用会员服务的，则视为您已经同意变更的全部内容。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            7. 服务的中断和终止
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            7.1 在发生如下情况时，碧湾有权中断或终止向您提供会员服务
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            （1） 您主动提出要求的；（2） 您存在或碧湾经独立判断后认为您存在任何违反国家法律法规或监管政策、违反本协议或有损碧湾或/及其关联公司的声誉、利益的行为的；（3） 碧湾根据法律法规、监管政策的规定或有权机关的要求；（4） 碧湾为维护账号与系统安全等紧急情况之需要；（5） 不可抗力（鉴于互联网之特殊性质，不可抗力亦包括黑客攻击、电信部门技术调整导致之重大影响、因政府管制而造成之暂时关闭、病毒侵袭等影响互联网正常运行之情形）；（6） 其他碧湾无法抗拒的情况。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            7.2 当发生前述终止的情况时，您与碧湾均认可如下处理方式：
            （1） 已经产生但未使用的会员权益自动清除且不折现；（2） 如您在碧湾平台内有正在进行中的交易，碧湾届时将视情况进行合理处理；（3） 除法律法规另有规定或碧湾另有说明外，碧湾已收取的会员费用不予退还；（4） 如因您违反本协议导致终止的，碧湾有权视情况要求您承担相应的违约责任；（5） 除法律法规另有规定或碧湾另有说明外，碧湾无需向您和第三人承担任何责任。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            8. 未成年人保护
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            8.1 碧湾非常注重未成年人的保护。若您为未成年人的，应在监护人监护、指导下阅读本协议，并且使用会员服务已经得到监护人的同意。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            8.2 监护人应指导子女上网应该注意的安全问题，防患于未然。若监护人同意未成年人使用会员服务，必须以监护人名义申请消费，并对未成年人使用会员服务进行正确引导、监督。未成年人使用会员服务，以及行使和履行本协议项下的权利和义务即视为已获得了监护人的认可。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            8.3 碧湾提醒未成年人在使用会员服务时，要善于网上学习，认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            9. 通知
            为便于您获知与本协议和会员服务相关的信息，您同意碧湾有权通过网页公示、页面提示、弹窗、消息通知、公众号通知、您预留的联系方式（如手机短信、电子邮件等）等一种或多种方式进行通知，该通知自碧湾发送之时视为已成功送达您。如多种通知方式并存的，则送达时间以上述方式中最早发送之时为准。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            10. 法律的适用和管辖
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            10.1 本协议的生效、履行、解释及争议的解决均适用中华人民共和国法律。如本协议因与中华人民共和国现行法律相抵触而导致部分无效的，不影响协议的其他部分的效力。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            10.2如就本协议的签订、履行等发生任何争议的，双方应尽量友好协商解决；协商不成时，任何一方均可向被告住所地享有管辖权的人民法院提起诉讼。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            11. 联系方式
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            11.1 如您对本协议或使用会员服务的过程中有任何问题（包括问题咨询、投诉等），碧湾专门为您提供了多种反馈通道，希望为您提供满意的解决方案：
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            （1） 在线客服/其他在线意见反馈通道：您可与碧湾平台上产品功能页面的在线客服联系或者在线提交意见反馈；（2） 人工客服通道：您可以拨打碧湾的任何一部客服电话与碧湾联系；（3） 其他方式：碧湾提供的其他反馈通道。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            11.2 我们会在收到您的反馈后尽快向您答复。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            12. 其他
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            12.1 本协议自页首所列更新之日起生效。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            12.2 本协议的标题仅为方便及阅读而设，并不影响正文中任何条款的含义或解释。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: justify">
            12.3 碧湾未行使或延迟行使其在本协议项下的权利并不构成对这些权利的放弃，而单一或部分行使其在本协议项下的任何权利并不排斥其任何其它权利的行使。碧湾随时有权要求您继续履行义务并承担相应的违约责任。
          </div>
          <div style="margin-top: 10px;font-size: 16;text-align: right">
            杭州碧湾信息技术有限公司
          </div>
        </div>
      </div>
    </t-drawer>

    <!-- <div style="width: 0;height: 0;">
      <iframe id="myIframe" style="width: 0;height: 0;border: 0;" src=""></iframe>
    </div> -->

  </div>
</template>

<script>
import $ from 'jquery';
import { ChevronLeftIcon } from 'tdesign-icons-vue';
import { wxpayH5Member } from '../../static/js/util';

export default {
  components: {
    ChevronLeftIcon
  },
  data() {
    return {
      memberType: 1,
      read: false,
      visible: false,

      ip: null,

      mydialog: null
    };
  },
  mounted() {

  },
  methods: {
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   name: 'myUser'
      // });
    },
    /**
     * 付费类型
     */
    changeMember(type) {
      // console.log(e)
      this.memberType = type;
    },
    /**
     * 阅读协议
     */
    changeRead() {
      if(this.read==true) {
        this.read = false;
      }else {
        this.read = true;
      }
    },
    //打开协议
    showPopup() {
      this.visible = true;
      $('.t-drawer__mask').click(()=>{
        this.visible = false;
      });
    },
    //关闭协议
    closePopup() {
      this.visible = false;
    },
    //立即购买
    buyElement() {
      if(!this.read) {
        this.$message.error("请先确认已阅读协议");
        return;
      }else {
        this.wxpay();
      }
    },
    wxpay() {
      var newUrl = wxpayH5Member(this.memberType+'', 'web');
      // console.log(newUrl);
      // document.getElementById('myIframe').src = newUrl;

      // var buyUrl = '/#/buyView?url=' + encodeURIComponent(newUrl);
      var buyUrl = '/#/buyView?url=' + encodeURIComponent(encodeURIComponent(newUrl));
      window.open(buyUrl);

      setTimeout(()=>{
        this.mydialog = this.$dialog.alert({
          header: '购买完成后请刷新',
          confirmBtn: '刷新',
          closeBtn: false,
          width: '70%',
          onConfirm: ()=>{
            // this.$router.go(0);
            // location.reload();
            this.$router.push({
              name: 'myUser'
            });
          }
        });
      }, 1000)
    }
  }
}
</script>

<style scoped>

.main {
  height: 100%;
  padding: 10px;
  position: relative;
  height: calc(100% - 20px);
  overflow-y: auto;
  font-family: '黑体';
}

.top {
  margin: -10px -10px 0 -10px;
  height: 40px;
  background-color: #F4CAC6;
}
.back {
  display: inline-block;
  width: 50px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.sumBox {
  width: 100%;
  /* margin: 0 auto; */
  margin-top: 15px;
  display: flex;
  justify-content: space-around;
}
.sumBoxSelected {
  position: relative;
  text-align: center;
  width: 100px;
  /* height: 100px; */
  border-radius: 6px;
  border: 1px solid #EDA666;
  background: #FFF8EE;
  color: #74592A;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.sumBoxUnselected {
  text-align: center;
  width: 100px;
  /* height: 100px; */
  border-radius: 6px;
  color: #595959;
  background: #F7F7F9;
  border: 1px solid #FFF;
}
.sumBoxSelected > div:nth-child(1),
.sumBoxUnselected > div:nth-child(1) {
  padding-top: 18px;
  font-size: 18px;
}
.sumBoxSelected > div:nth-child(2),
.sumBoxUnselected > div:nth-child(2) {
  font-size: 27px;
  margin-top: 5px;
}
.sumBoxSelected > div:nth-child(3),
.sumBoxUnselected > div:nth-child(3) {
  font-size: 19px;
  height: 22px;
  color: #EDA666;
  padding-bottom: 6px;
}
.sumBoxSelected > div:nth-child(4) {
  position: absolute;
  left: -1px;
  top: -1px;
  border-radius: 6px 0px;
  background: #FF4D3A;
  color: #FFF;
  padding: 1px 3px;
  font-size: 14px;
}

.equityLine {
  width: 330px;
  margin: 0 auto;
  margin-top: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.equityLine > div:nth-child(1) {
  height: 5px;
  width: 60px;
  clip-path: polygon(0 50%, 100% 0, 100% 100%);
  background: #F7BE71;
}
.equityLine > div:nth-child(2),
.equityLine > div:nth-child(4) {
  width: 8px;
  height: 8px;
  transform: rotate(46deg);
  background: #F7BE71;
}
.equityLine > div:nth-child(5) {
  height: 5px;
  width: 60px;
  clip-path: polygon(0 0, 100% 50%, 0 100%);
  background: #F7BE71;
}
.equityLine > div:nth-child(3) {
  color: #333;
  font-size: 19px;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
}

.equityTable {
  margin: 10px;
}
.equityTableHead {
  display: flex;
  width: 100%;
}
.equityTableHead > div {
  text-align: center;
  width: 100%;
  height: 42px;
  line-height: 42px;
}
.equityTableHead > div:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.equityTableHead > div:nth-child(1) > div:nth-child(1) {
  display: flex;
}
.equityTableHead > div:nth-child(2) {
  background: #38393E;
  color: #FAE2B6;
  border-left: 0.4px solid #38393E;
  border-right: 0.4px solid #38393E;
}
.equityTableHead > div:nth-child(3) {
  background: #FAE2B6;
  color: #7E5328;
}
.equityTableBody {
  border: 0.2px solid #cecece;
  border-radius: 0px 0px 8px 8px;
}
.equityTableBody > div {
  display: flex;
  text-align: center;
  width: 100%;
  height: 42px;
  line-height: 42px;
}
.equityTableBody > div > div {
  width: 33.33%;
  color: #595959;
}
.equityTableBody > div:nth-child(1) > div:nth-child(2) {
  border-top: 0.4px solid #FF4D3A;
}
.equityTableBody > div > div:nth-child(2) {
  border-left: 0.4px solid #FF4D3A;
  border-right: 0.4px solid #FF4D3A;
  color: #EDA666;
}
.equityTableBody > div:nth-child(3) > div:nth-child(2) {
  border-bottom: 0.4px solid #FF4D3A;
}
.equityIcon {
  width: 23px;
  height: 23px;
  margin-right: 3px;
}

.noteView {
  margin-top: 15px;
  padding: 0 10px;
  color: #222;
  font-size: 16px;
  line-height: 22px;
}

.agreement {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footBox {
  width: calc(100% - 20px);
  position: absolute;
  bottom: 10px;
}
.subBtn {
  width: 90%;
  height: 49px;
  border-radius: 21.5px;
  background: linear-gradient(270deg, #F8BB6A 0.09%, #F9DFBC 100.77%);
  margin: 10px auto;
  text-align: center;
  line-height: 49px;
  font-size: 22px;
  color: #774F14;
}
.scrollView {
  padding: 15px 20px;
  background-color: #FFF;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 600px;
  overflow-y: auto;
}
.agreementBox {
  min-height: 600px;
  text-align: center;
  color: #333;
}

.main /deep/ .t-drawer__content-wrapper {
  height: auto !important;
}
.main /deep/ .t-drawer__body {
  padding: 8px;
  height: 80%;
}
</style>