<template>
  <div class="main">
    <div class="top">
      <div class="back" @click="back"><ChevronLeftIcon />关闭</div>
    </div>
    <iframe id="myIframe" src=""></iframe>

    <div class="footText">
      <div>请点击跳转微信支付</div>
      <div>支付完成后关闭此页</div>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon } from 'tdesign-icons-vue';

export default {
  components: {
    ChevronLeftIcon
  },
  data() {
    return {
      wxpayUrl: '',
      mydialog: null
    };
  },
  mounted() {
    this.wxpayUrl = decodeURIComponent(decodeURIComponent(this.$route.query.url));
    this.mydialog = this.$dialog.alert({
      header: '是否进行支付',
      confirmBtn: '是',
      closeBtn: false,
      width: '70%',
      onConfirm: ()=>{
        document.getElementById('myIframe').src = this.wxpayUrl;
        this.mydialog.hide();
      }
    });
    
  },
  methods: {
    back() {
      // this.$router.go(-1);
      // this.$router.push({
      //   name: 'reportHome'
      // });
      window.close();
    },
  }
}
</script>

<style scoped>
.main {
  width: 100%;
  height: 100%;
}
.top {
  height: 40px;
  background-color: #F4CAC6;
}
.back {
  display: inline-block;
  width: 50px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
#myIframe {
  width: 100%;
  height: calc(100% - 45px);
  border: 0;
}
.footText {
  text-align: center;
  position: absolute;
  bottom: 5px;
  width: 100%;
  font-size: 17px;
}
</style>
