<template>
  <div class="main">
    <div style="height: calc(100% - 61px);">
      <router-view/>
    </div>
    <div class="tabBar">

      <div class="tabBarItem">
        <div v-if="index==0">
          <img src="../../static/img/report_selected.png" class="icon">
          <span style="color: #D84A36;">报告</span>
        </div>
        <div v-else @click="changeIndex(0)">
          <img src="../../static/img/report_unselect.png" class="icon">
          <span style="color: #999999;">报告</span>
        </div>
      </div>

      <div class="tabBarItem">
        <div v-if="index==1">
          <img src="../../static/img/compare_selected.png" class="icon">
          <span style="color: #D84A36;">行业指标</span>
        </div>
        <div v-else @click="changeIndex(1)">
          <img src="../../static/img/compare_unselect.png" class="icon">
          <span style="color: #999999;">行业指标</span>
        </div>
      </div>

      <div class="tabBarItem">
        <div v-if="index==2">
          <img src="../../static/img/my_selected.png" class="icon">
          <span style="color: #D84A36;">我的</span>
        </div>
        <div v-else @click="changeIndex(2)">
          <img src="../../static/img/my_unselect.png" class="icon">
          <span style="color: #999999;">我的</span>
        </div>
      </div>

    </div>
    <div style="height: 61px;background-color: #F2F2F2;"></div>
  </div>
</template>

<script>
import { MessagePlugin } from 'tdesign-vue';
import { sendRequestCompany2 } from '../../static/js/util';

export default {
  data() {
    return {
      index: 0,
      isLogin: false
    };
  },
  mounted() {

    var path = this.$route.name;
    if(path=='reportHome') {
      this.index = 0;
    }else if(path=='induCompare') {
      this.index = 1;
    }else if(path=='myUser') {
      this.index = 2;
    }else {
      this.$router.push({
        name: 'reportHome'
      });
    }

    // 校验token
    this.checkToken();

    // this.$router.push({
    //   name: 'reportHome'
    // });

    //下载提示
    MessagePlugin.error({
      icon: false,
      closeBtn: true,
      placement: 'bottom',
      duration: 6000,
      content: ()=>{
        return (
          <div>
            是否下载 <a href="https://www.beawan.com/downloadView">碧湾App</a>
          </div>
        )
      }
    });
  },
  methods: {
    //验证token是否有效
    checkToken(){
      var token = localStorage.getItem('satoken');
      var tokenDate = localStorage.getItem('satokenDate');
      if(token == null) {
        this.$router.push({
          name: 'notLogged'
        });
      }else if(tokenDate != new Date().toLocaleDateString()){
        
        let url = 'user/userInfo2';
        sendRequestCompany2(url, 'get', {'device': 'web-mobile'}, (response) => {
          // console.log(response)
          if (response.data.code != 0) {
            localStorage.removeItem('satoken');
            this.$router.push({
              name: 'notLogged'
            });
          }else {
            localStorage.setItem('satokenDate', new Date().toLocaleDateString());
            this.isLogin = true;
            this.$router.push({
              name: 'reportHome'
            });
          }
        }, (err) => {
          console.log(err);
        });

      }else {
        this.isLogin = true;
      }

      
    },

    toLogin() {
      this.mydialog = this.$dialog.alert({
        header: '是否先登录账号',
        confirmBtn: '是',
        closeBtn: false,
        width: '70%',
        onConfirm: ()=>{
          this.$router.push({
            name: 'login'
          });
          this.mydialog.hide();
        }
      });
    },
    
    changeIndex(num) {
      if(!this.isLogin) {
        this.toLogin();
        return;
      }

      this.index = num;
      if(num==0) {
        this.$router.push({
          name: 'reportHome'
        });
      }else if(num==1) {
        this.$router.push({
          name: 'induCompare'
        });
      }else if(num==2) {
        this.$router.push({
          name: 'myUser'
        });
      }
    }
  }
}
</script>

<style scoped>
.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.tabBar {
  height: 60px;
  width: 100%;
  border-top: 1px solid #b6b6b6;
  background-color: #fff;
  display: flex;
  position: fixed;
  bottom: 0;
  z-index: 100;
}
.tabBarItem {
  /* width: 33.3%; */
  width: 50%;
  height: 100%;
}
.tabBarItem > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.icon {
  width: 27px;
}
</style>
