import Vue from 'vue'
import App from './App.vue'
import router from './router';
import TDesign from 'tdesign-vue';
// import {Input as TInput, Checkbox as TCheckbox, Loading as TLoading, Drawer as TDrawer, Select as TSelect, Option as TOption, Radio as TRadio, Message as TMessage} from 'tdesign-vue';
import 'tdesign-vue/es/style/index.css';
import axios from 'axios';

//配置全局的超时时长
axios.defaults.timeout = 10000;
//配置全局的基本URL
//dev -- 'http://192.168.10.52:8918/'
//prod-- 'https://www.beawan.com/'
// axios.defaults.baseURL = 'http://192.168.10.52:8918/';
axios.defaults.baseURL = 'https://www.beawan.com/';


Vue.use(TDesign);
// Vue.use(TInput);
// Vue.use(TCheckbox);
// Vue.use(TLoading);
// Vue.use(TDrawer);
// Vue.use(TSelect);
// Vue.use(TOption);
// Vue.use(TRadio);


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
