import { render, staticRenderFns } from "./induCompareBuy.vue?vue&type=template&id=19e58575&scoped=true"
import script from "./induCompareBuy.vue?vue&type=script&lang=js"
export * from "./induCompareBuy.vue?vue&type=script&lang=js"
import style0 from "./induCompareBuy.vue?vue&type=style&index=0&id=19e58575&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19e58575",
  null
  
)

export default component.exports