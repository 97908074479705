<template>
  <div class="main">
    <div class="top">
      <div class="back" @click="back"><ChevronLeftIcon />返回</div>
    </div>
    <iframe id="myIframe" src=""></iframe>
  </div>
</template>

<script>
import { ChevronLeftIcon } from 'tdesign-icons-vue';

export default {
  components: {
    ChevronLeftIcon
  },
  data() {
    return {
      shareUrl: ''
    };
  },
  mounted() {
    this.shareUrl = decodeURIComponent(this.$route.params.shareUrl);
    document.getElementById('myIframe').src = this.shareUrl;
  },
  methods: {
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   name: 'reportHome'
      // });
    },
  }
}
</script>

<style scoped>
.main {
  width: 100%;
  height: 100%;
}
.top {
  height: 40px;
  background-color: #F4CAC6;
}
.back {
  display: inline-block;
  width: 50px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
#myIframe {
  width: 100%;
  height: calc(100% - 45px);
  border: 0;
}
</style>
