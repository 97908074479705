<template>
  <div class="main">
    <div class="top">
      <img src="../../static/img/beawan_title.svg" class="title">
      <img src="../../static/img/back_img.png" class="backImg">
      <t-input v-model="inputText" placeholder="请输入公司名称、代码查询" class="searchBox" clearable size="large" @change="toLogin">
        <search-icon slot="prefix-icon"/>
      </t-input>

    </div>

    <div class="content">

      <div class="hotBox" style="margin-top: 0;" v-if="hotList.length>0">
        <div class="hotTitle">
          <img src="../../static/img/hot.svg" class="hotIcon">
          <span class="hotText">热门公司</span>
        </div>
        <div class="hotList">
          
        </div>
      </div>

      <div class="historyBox">
        <div class="historyTitle">
          <img src="../../static/img/history.svg" class="historyIcon">
          <span class="historyText">历史搜索</span>
          <img src="../../static/img/clear.svg" class="clearIcon" @click="toLogin">
        </div>
        <div class="historyList">
          
        </div>
      </div>

      <div style="height: 40px;"></div>

    </div>

    <div class="footerText">
      <div>
        杭州碧湾信息技术有限公司
      </div>
      <div>
        联系电话：0571-86685367
      </div>
    </div>
  </div>
</template>

<script>
import { SearchIcon } from 'tdesign-icons-vue';

export default {
  components: { SearchIcon },
  data() {
    return {
      inputText: '',
      hotList: [],
      mydialog: null
    };
  },
  mounted() {
    
  },
  methods: {
    toLogin() {
      this.mydialog = this.$dialog.alert({
        header: '是否先登录账号',
        confirmBtn: '是',
        closeBtn: false,
        width: '70%',
        onConfirm: ()=>{
          this.$router.push({
            name: 'login'
          });
          this.mydialog.hide();
        }
      });
    }
  }
}
</script>

<style>
body {
  --td-brand-color: #D84A36;
  --td-brand-color-focus: #F4CAC6;
}
</style>
<style scoped>

.main {
  /* display: flex; */
  /* flex-direction: column; */
  height: 100%;
  background-color: #F2F2F2;
}

.top {
  height: 230px;
  background-color: #F4CAC6;
}
.title {
  width: calc(55% - 20px);
  height: 150px;
  margin-left: 20px;
  padding-bottom: 30px;
  position: relative;
  z-index: 2;
}
.backImg {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: 13px;
  width: 60%;
}
.searchBox {
  width: 86%;
  margin: -8px 7% 0 7%;
  position: relative;
  z-index: 3;
}
.t-icon-search {
  color: #D84A36;
}

.content {
  flex: 1;
  margin-bottom: 10px;
}
.searchResult {
  position: absolute;
  width: calc(100% - 80px);
  padding: 3px 40px 0 40px;
  z-index: 1000;
}
.searchResultBox {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
  background-color: #FFFFFF;
  /* padding: 6px 0; */
  overflow-y: auto;
}
.searchResultItem {
  height: 36px;
  line-height: 36px;
  /* padding-left: 20px; */
  padding: 3px 0 3px 20px;
  font-size: 17px;
}
.historyBox, .hotBox {
  margin-top: 15px;
  padding: 5px 10px 15px 10px;
  background-color: #FFFFFF;
  position: relative;
  z-index: 10;
}
.historyTitle, .hotTitle {
  display: flex;
  align-items: center;
  padding: 8px 0;
  margin: 0 14px;
  margin-bottom: 5px;
  border-bottom: 1px solid #EBEBEB;
}
.historyText, .hotText {
  color: #595959;
  font-size: 19px;
  font-weight: 600;
  flex: 1;
}
.hotIcon {
  width: 28px;
  height: 28px;
  margin-right: 6px;
}
.historyIcon {
  width: 23px;
  height: 23px;
  margin-right: 11px;
}
.clearIcon {
  width: 24px;
  height: 24px;
}
.historyList, .hotList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.historyItem, .hotItem {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 5px 0 5px 14px;
  font-size: 16px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  padding: 5px 10px;
  width: calc(33% - 40px);
}

.footerText {
  background-color: #F2F2F2;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-size: 13px;
  color: #AAAAAA;
  padding-bottom: 10px;
  padding-bottom: 10px;
  position: fixed;
  bottom: 60px;
  width: 100%;
  z-index: 20;
}

</style>
